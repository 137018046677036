import React, { useState } from "react";
import NavigationTab from "../../components/common/NavigationTab";
import ErrorMessage from "../../components/common/ErrorMessage";
import AdminComponent from "./AdminComponent";
import ActiveCriteria from "./ActiveCriteria";
import AdminReport from "./reports/AdminReportsComponent";
import AdminSettings from "./adminSettings/AdminSettingsComponent";
import useErrorMessage from "../../components/hooks/useErrorMessage";
import { Routes, Route, Link, Navigate } from "react-router-dom";

const Scorecard = (props) => {
  const { clearErrorMessage } = useErrorMessage();
  const [isActiveList, setIsActiveList] = useState({
    activeAssesment: "active",
    activeCriteria: "",
    defectByCategory: "",
    eda: "",
    issueBacklog: "",
    issueBacklogRegression: "",
    issueBacklogRegressionNewFeature: "",
    sonarQube: "",
    automationTrending: "",
    jiraCustom: "",
    performance: "",
  });

  const changeStyle = (tabId) => {
    let newStatuses = {};
    for (let key in isActiveList) {
      newStatuses[key] = key === tabId ? "active" : "";
    }
    clearErrorMessage();
    setIsActiveList(newStatuses);
  };

  return (
    <div id="scorecard_main" style={{ minHeight: "calc(100vh - 60px)" }}>
      <NavigationTab
        changeStyle={props.navTabChangeStyle}
        currentProjectId={props.currentProjectId}
      />
      <div id="scorecard_main_container">
        <div id="scorecard_main_container_1">
          <div
            id="scorecard_header_container"
            className="container-fluid pt-3 pb-3"
          >
            <h4 className="ms-4">Scorecard</h4>
            <ErrorMessage />
          </div>
          <ul className="nav nav-tabs" id="navTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${isActiveList["activeAssesment"]}`}
                aria-current="page"
              >
                <Link
                  to="activeAssesment"
                  className="text-decoration-none"
                  onClick={() => {
                    changeStyle("activeAssesment");
                  }}
                >
                  Active Assessments
                </Link>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${isActiveList["activeCriteria"]}`}
                aria-current="page"
              >
                <Link
                  to="activeCriteria"
                  className="text-decoration-none"
                  onClick={() => {
                    changeStyle("activeCriteria");
                  }}
                >
                  Active Criteria
                </Link>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${isActiveList["reports"]}`}
                aria-current="page"
              >
                <Link
                  to="reports"
                  className="text-decoration-none"
                  onClick={() => {
                    changeStyle("reports");
                  }}
                >
                  Reports
                </Link>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${isActiveList["settings"]}`}
                aria-current="page"
              >
                <Link
                  to="settings"
                  className="text-decoration-none"
                  onClick={() => {
                    changeStyle("settings");
                  }}
                >
                  Settings
                </Link>
              </button>
            </li>
          </ul>

          <Routes>
            <Route
              path="activeAssesment"
              element={
                <AdminComponent token={props.token} changeStyle={changeStyle} />
              }
            />
            <Route
              path="activeCriteria"
              element={
                <ActiveCriteria changeStyle={changeStyle} token={props.token} />
              }
            />
            <Route
              path="reports"
              element={
                <AdminReport changeStyle={changeStyle} token={props.token} />
              }
            />
            <Route
              path="settings"
              element={
                <AdminSettings changeStyle={changeStyle} token={props.token} />
              }
            />
            <Route
              path="*"
              element={<Navigate to="activeAssesment" replace />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Scorecard;
