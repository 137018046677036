import React, { useState, useEffect } from "react";
import Jira from "../../services/Jira";
import DefectsModal from "../../components/common/modals/DefectsModal";
import ErrorMessage from "../../components/common/ErrorMessage";
import OpenDefectsTrendingChart from "../../components/charts/steercoCharts/OpenDefectsTrendingChart";
import OpenDefectsByPriorityChart from "../../components/charts/steercoCharts/OpenDefectsByPriorityChart";
import OpenClosedDefectsBySeverityChart from "../../components/charts/steercoCharts/OpenClosedDefectsBySeverityChart";
import RootCauseAnalysisChart from "../../components/charts/defectsCharts/RootCauseAnalysis";
import useErrorMessage from "../../components/hooks/useErrorMessage";
import useJiraInfo from "../../components/hooks/useJiraInfo";
import useProjectDetails from "../../components/hooks/useProjectDetails";
import ADO from "../../services/ADO";
import { useAppContext } from "../../store/AppProvider";
import "../../components/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getJiraSteercoDefectsInfo(token, projectId) {
  try {
    const resp = await Jira.getJiraSteercoDefectsInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error Jira info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getADOSteercoDefectsInfo(token, projectId, body) {
  try {
    const resp = await ADO.getADOSteercoChartsInfo(token, projectId, body);
    return resp;
  } catch (err) {
    return {
      message:
        "Error ADO info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getRootCauseAnalysisInfo(token, projectId) {
  try {
    const resp = await Jira.getRootCauseAnalysisInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error Jira info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

export default function SteercoDefectsPage(props) {
  const { jiraSteercoDefectsInfo, setJiraSteercoDefectsInfo } = useJiraInfo();
  const { projectDetails } = useProjectDetails();
  const { setErrorMessage, errorMessage } = useErrorMessage("");
  const [rootCauseData, setRootCauseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSteercoDefects, setLoadingSteercoDefects] = useState(true);
  const [iterations, setIterations] = useState([]);
  const [currentIteration, setCurrentIteration] = useState("Overall");
  const [showModal, setShowModal] = useState(false);
  const { currentProjectDetails } = useAppContext();
  const [modalData, setModalData] = useState({
    title: "",
    projectId: null,
    createdDt: null,
    releaseName: null,
    status: null,
    severity: null,
  });

  const DataSource = {
    JIRA: "JIRA",
    ADO: "ADO",
    QTEST: "qTest",
  };
  const currentProjectSourceOfInformation =
    currentProjectDetails?.projectTestSource;
  /*
  TODO delete this
  // const currentProjectSourceOfInformation = JSON.parse(
  //   localStorage.getItem("currentProjectDetails")
  // )?.projectTestSource;
*/
  useEffect(() => {
    function handleScrollYChange() {
      const lastScrollY = window.scrollY;
      if (lastScrollY) {
        sessionStorage.setItem("scrollY", lastScrollY);
      }
    }

    window.addEventListener("scroll", handleScrollYChange, true);
    return () => {
      window.removeEventListener("scroll", handleScrollYChange);
    };
  }, []);

  useEffect(() => {
    const handleJiraDetails = async (e) => {
      var currentProjectId = props.currentProjectId;
      var url = window.location.href;
      let res = /project\/(\d+)/g.exec(url);

      if (!currentProjectId) {
        if (res?.length > 1) {
          currentProjectId = res[1];
          props.setCurrentProjectId(currentProjectId);
        } else {
          setErrorMessage("Project is not selected!");
          setLoadingSteercoDefects(false);
          return;
        }
      }

      if (
        (!jiraSteercoDefectsInfo ||
          jiraSteercoDefectsInfo.projectId !== currentProjectId) &&
        !errorMessage
      ) {
        setJiraSteercoDefectsInfo(null);
        const response =
          DataSource.ADO === currentProjectSourceOfInformation
            ? await getADOSteercoDefectsInfo(
                props.token,
                currentProjectId,
                projectDetails
              )
            : await getJiraSteercoDefectsInfo(props.token, currentProjectId);

        const rootCauseAnalysisResponse =
          DataSource.ADO !== currentProjectSourceOfInformation
            ? await getRootCauseAnalysisInfo(props.token, currentProjectId)
            : null;

        if (rootCauseAnalysisResponse) {
          setRootCauseData(rootCauseAnalysisResponse);
        }

        if (response?.errMsg || rootCauseAnalysisResponse?.errMsg) {
          setErrorMessage(
            "Oops!! There was an error retrieving information, please try again later."
          );
          setJiraSteercoDefectsInfo(null);
          setRootCauseData(null);
        } else {
          if (!response?.productSprintMap) {
            setErrorMessage(
              "Oops!! There was an error retrieving information, please try again later."
            );
            setJiraSteercoDefectsInfo(null);
          } else {
            setErrorMessage("");
            setJiraSteercoDefectsInfo(response);
            setIterations(response?.productSprintMap);
          }
        }
      } else {
        if (jiraSteercoDefectsInfo) {
          if (jiraSteercoDefectsInfo?.productSprintMap) {
            setIterations(jiraSteercoDefectsInfo.productSprintMap);
          } else {
            setErrorMessage(
              "Oops!! Stored Steerco defects object does not have info about iterations!"
            );
            setJiraSteercoDefectsInfo(null);
          }
        }
      }
      setLoadingSteercoDefects(false);
      sessionStorage.setItem("scrollY", "");
    };

    handleJiraDetails();
  }, [props.currentProjectId]);

  useEffect(() => {
    props.changeStyle("steercodefects");
  }, [props.currentProjectId]);

  useEffect(() => {
    setLoading(loadingSteercoDefects);
  }, [loadingSteercoDefects]);

  const handleIterationClick = (event) => {
    setCurrentIteration(event.target.id);
  };

  return (
    <div id="main_container">
      <DefectsModal
        isOpen={showModal}
        setShowModal={setShowModal}
        data={modalData}
        token={props.token}
      />
      <div
        id="main_container_1"
        className="bg-light  container-fluid pt-3 pb-3"
      >
        {loading && (
          <div className="d-flex justify-content-center bg-transparent ">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <h4 className="ms-4">Steerco Defect Metrics</h4>
        <ErrorMessage errorMessage={errorMessage} />
        <div id="sprints-container" className="container-fluid pt-3 pb-3">
          <div className="btn-group ms-4" role="group" aria-label="Iterations">
            <div key={0} className={"ms-1"}>
              <button
                type="button"
                key={0}
                id={"Overall"}
                className={
                  "btn btn-outline-primary " +
                  ("Overall" === currentIteration ? "active" : "")
                }
                onClick={handleIterationClick}
              >
                Overall
              </button>
            </div>
            {iterations && currentProjectSourceOfInformation !== DataSource.ADO
              ? Object.keys(iterations).map((iterationId, index) => {
                  return (
                    <div key={index} className={"ms-1"}>
                      <button
                        type="button"
                        key={index}
                        id={iterationId}
                        className={
                          "btn btn-outline-primary " +
                          (iterationId === currentIteration ? "active" : "")
                        }
                        onClick={handleIterationClick}
                      >
                        {iterations[iterationId]}
                      </button>
                    </div>
                  );
                })
              : iterations.map((iteration, index) => {
                  return (
                    <div key={index} className={"ms-1"}>
                      <button
                        type="button"
                        key={iteration.id}
                        id={iteration.iterationId}
                        className={
                          "btn btn-outline-primary " +
                          (iteration.iterationId === currentIteration
                            ? "active"
                            : "")
                        }
                        onClick={handleIterationClick}
                      >
                        {iteration.iterationName}
                      </button>
                    </div>
                  );
                })}
          </div>
        </div>
        <div
          className="row ms-3 row-cols-auto justify-content-around"
          style={
            loading
              ? {
                  pointerEvents: "none",
                  opacity: "0.4",
                }
              : {
                  marginTop: "50px",
                }
          }
        >
          <div id="openDefectsTrendingChart" className="col col-5 mb-4">
            <div id="open-closed-defects-root">
              <OpenDefectsTrendingChart
                jiraSteercoDefectsInfo={jiraSteercoDefectsInfo}
                currentIteration={currentIteration}
                setModalData={setModalData}
                setShowModal={setShowModal}
                currentProjectId={props.currentProjectId}
                isADO={currentProjectSourceOfInformation}
              />
            </div>
          </div>
          <div id="openDefectsTrendingChart" className="col col-5 mb-4">
            <div id="open-by-priority-root">
              <OpenDefectsByPriorityChart
                jiraSteercoDefectsInfo={jiraSteercoDefectsInfo}
                currentIteration={currentIteration}
                setModalData={setModalData}
                setShowModal={setShowModal}
                currentProjectId={props.currentProjectId}
                isADO={currentProjectSourceOfInformation}
              />
            </div>
          </div>
          <div id="openDefectsTrendingChart" className="col col-5 mb-4">
            <div id="open-closed-defects-by-severity-root">
              <OpenClosedDefectsBySeverityChart
                jiraSteercoDefectsInfo={jiraSteercoDefectsInfo}
                currentIteration={currentIteration}
                setModalData={setModalData}
                setShowModal={setShowModal}
                currentProjectId={props.currentProjectId}
                isADO={currentProjectSourceOfInformation}
              />
            </div>
          </div>
          {currentProjectSourceOfInformation !== DataSource.ADO && (
            <div id="rootCauseAnalysisChart" className="col col-5 mb-4">
              <div id="root-cause-analysis-chart-root">
                <RootCauseAnalysisChart
                  data={rootCauseData}
                  currentIteration={currentIteration}
                  setModalData={setModalData}
                  setShowModal={setShowModal}
                  currentProjectId={props.currentProjectId}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
