import FetchUtils from "./FetchUtils";

class ScoreCardService {

    static async getScorecardAndSelfAssements(token, deptName, quarter, year) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/assessments/dept/' + deptName + '/' + quarter + '/' + year, 'GET', headers);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllModules(token) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/scoring-modules', 'GET', headers);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllModulesByTemplateIdAndAssessmentId(token, templateId, assessmentId) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/all-modules/' + templateId + "/" + assessmentId, 'GET', headers);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllModulesByTemplateId(token, templateId) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/all-modules/' + templateId, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async addNewSubModule(token, name, moduleId) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_SCORECARD_SERVICE_URL + "/scoring-sub-modules/" + moduleId + "/save";
        let body = name;

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async addNewModule(token, name, templateId) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_SCORECARD_SERVICE_URL + "/scoring-modules/" + templateId + "/save";
        let body = name;

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getSubModules(token, id) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/scoring-sub-modules/' + id, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllSubModules(token, id, assessmentId) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/all-sub-modules/' + id + "/" + assessmentId, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getCriteriaListBySubModuleId(token, id) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/criteria/' + id, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllCriteriaListBySubModuleId(token, id, assessmentId) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/all-criteria/' + id + "/" + assessmentId, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async addNewCriteria(token, payload) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_SCORECARD_SERVICE_URL + '/criteria/save';
        let body = JSON.stringify(payload);

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async deleteCriteria(token, id) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_ADMIN_SERVICE_URL + '/criteria/delete/' + id, 'DELETE', headers);
            if (!data || !(data.status == 'OK' || data.status == 204)) {
                let errorMsg = 'User cannot be deleted from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error deleting user (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllCriteria(token) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/all/criteria', 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllCriteriaByTemplateId(token, templateId) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/all/criteria/' + templateId, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllCriteriaByTemplateIdAndAssessmentId(token, templateId, assessmentId) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/report/' + templateId + "/" + assessmentId, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getReortaByTemplateIdAndAssessmentId(token, templateId, assessmentId) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/report/' + templateId + "/" + assessmentId, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async addNewUser(token, payload) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_SCORECARD_SERVICE_URL + '/user/add';
        let body = JSON.stringify(payload);

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllUsers(token) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/users', 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllUsersByRole(token, role) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/users/' + role, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllBussinessUnits(token) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/bussinessunits', 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async addBussinessUnits(token, payload) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_SCORECARD_SERVICE_URL + '/bussinessunits/add';
        let body = JSON.stringify(payload);

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllProjects(token) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/products', 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async addNewProduct(token, payload) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_SCORECARD_SERVICE_URL + '/products/add';
        let body = JSON.stringify(payload);

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async addNewProgram(token, payload) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_SCORECARD_SERVICE_URL + '/programs/add';
        let body = JSON.stringify(payload);

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async addNewDept(token, payload) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_SCORECARD_SERVICE_URL + '/departments/add';
        let body = JSON.stringify(payload);

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllPrograms(token) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/programs', 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllDepartments(token) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/departments', 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllTemplates(token) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/template', 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async addNewTemplate(token, template) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_SCORECARD_SERVICE_URL + '/template/add';
        let body = template;

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async copyTemplate(token, copyTemplateId, newTemplateName) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_SCORECARD_SERVICE_URL + '/template/copy/' + copyTemplateId;
        let body = JSON.stringify(newTemplateName);

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllProjectsByProgramCode(token, programCode) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/products/' + programCode, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllProgrambyDeptCode(token, deptCode) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/programs/' + deptCode, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async addNewAssessment(token, assessment) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_SCORECARD_SERVICE_URL + '/assessment/add';
        let body = JSON.stringify(assessment);

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async updateAssessment(token, assessment) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_SCORECARD_SERVICE_URL + '/assessment/modify';
        let body = JSON.stringify(assessment);

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllAssessments(token) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/assessment/all', 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getAllAssessmentsByUser(token, email) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/assessment/all/' + email, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async updateUserAnswerResponse(token, payload) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_SCORECARD_SERVICE_URL + '/assessment/update';
        let body = JSON.stringify(payload);

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async checkCriteriaEditable(token, templateId) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/criteria/check-editable/' + templateId, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async getRoleBasedNavigation(token, currentUser) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_SCORECARD_SERVICE_URL + '/user-role/' + currentUser, 'GET', headers);
            if (!data || !(data.status === 'OK' || data.status === 200)) {
                let errorMsg = 'Assesment by deartment cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;
        } catch (err) {
            return { message: 'Error getting assesment by deartment (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    }
}

export default ScoreCardService;