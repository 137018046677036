import React, { useState } from "react";
import NavigationTab from "../components/common/NavigationTab";
import ErrorMessage from "../components/common/ErrorMessage";
import EscapedDefectAnalytics from "./crossProject/EscapedDefectAnalytics";
import DefectAnalyticsPage from "./crossProject/DefectAnalytics";
import DefectByCategoryPage from "./crossProject/DefectByCategory";
import ProjectTracker from "./crossProject/ProjectTracker";
import AutomationTrending from "./crossProject/AutomationTrending";
import JiraCustomPage from "./crossProject/JiraCustom";
import IssueBacklogRegression from "../pages/crossProject/IssueBacklogRegression";
import IssueBacklogRegressionNewFeature from "../pages/crossProject/IssueBacklogRegressionNewFeatures";
import useErrorMessage from "../components/hooks/useErrorMessage";
import { Routes, Route, Link, Navigate } from "react-router-dom";

const CrossProject = (props) => {
  const { clearErrorMessage } = useErrorMessage();
  const [isActiveList, setIsActiveList] = useState({
    projectTracker: "",
    defectAnalytics: "",
    defectByCategory: "",
    eda: "active",
    issueBacklog: "",
    issueBacklogRegression: "",
    issueBacklogRegressionNewFeature: "",
    sonarQube: "",
    automationTrending: "",
    jiraCustom: "",
    performance: "",
  });

  const changeStyle = (tabId) => {
    let newStatuses = {};
    for (let key in isActiveList) {
      if (key === tabId) {
        newStatuses[key] = "active";
      } else {
        newStatuses[key] = "";
      }
    }
    clearErrorMessage();
    setIsActiveList(newStatuses);
  };

  return (
    <div id="cross_project_main" style={{ minHeight: "calc(100vh - 60px)" }}>
      <NavigationTab
        changeStyle={props.navTabChangeStyle}
        currentProjectId={props.currentProjectId}
      />
      <div id="cross_project_main_container">
        <div id="cross_project_main_container_1">
          <div
            id="cross_project_header_container"
            className="container-fluid pt-3 pb-3"
          >
            <h4 className="ms-4">Cross-Project</h4>
            <ErrorMessage />
          </div>
          {/* <Router basename={"/#/crossproject/"}> */}
          <ul className="nav nav-tabs" id="navTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${isActiveList["projectTracker"]}`}
                aria-current="page"
              >
                <Link
                  to="/crossProject/projectTracker"
                  className="text-decoration-none"
                  onClick={() => {
                    changeStyle("projectTracker");
                  }}
                >
                  Project Tracker
                </Link>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${isActiveList["defectAnalytics"]}`}
                aria-current="page"
              >
                <Link
                  to="/crossProject/defectAnalytics"
                  className="text-decoration-none"
                  onClick={() => {
                    changeStyle("defectAnalytics");
                  }}
                >
                  Defect Analytics
                </Link>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${isActiveList["defectByCategory"]}`}
                aria-current="page"
              >
                <Link
                  to="/crossProject/defectByCategory"
                  className="text-decoration-none"
                  onClick={() => {
                    changeStyle("defectByCategory");
                  }}
                >
                  Defect By Category
                </Link>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${isActiveList["eda"]}`}
                aria-current="page"
              >
                <Link
                  to="/crossProject/eda"
                  className="text-decoration-none"
                  onClick={() => {
                    changeStyle("eda");
                  }}
                >
                  Escaped Defect Analytics
                </Link>
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
                <button className={`nav-link ${isActiveList["issueBacklog"]}`}>
                  <Link
                    to="/issueBacklog"
                    className="text-decoration-none"
                    onClick={() => {
                      changeStyle("issueBacklog");
                    }}
                  >
                    Issue Backlog{" "}
                  </Link>
                </button>
              </li> */}
            {/* <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${isActiveList["issueBacklogRegression"]}`}
                >
                  <Link
                    to="/issueBacklogRegression"
                    className="text-decoration-none"
                    onClick={() => {
                      changeStyle("issueBacklogRegression");
                    }}
                  >
                    Issue Backlog (Regression/New Features)
                  </Link>
                </button>
              </li> */}
            {/* <li className="nav-item" role="presentation">
                <button className={`nav-link ${isActiveList["sonarQube"]}`}>
                  <Link
                    to="/sonarQube"
                    className="text-decoration-none"
                    onClick={() => {
                      changeStyle("sonarQube");
                    }}
                  >
                    SonarQube
                  </Link>
                </button>
              </li> */}
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${isActiveList["issueBacklogRegression"]}`}
              >
                <Link
                  to="/crossProject/issueBacklogRegression"
                  className="text-decoration-none"
                  onClick={() => {
                    changeStyle("issueBacklogRegression");
                  }}
                >
                  Issue Backlog Analytics
                </Link>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${isActiveList["issueBacklogRegressionNewFeature"]}`}
              >
                <Link
                  to="/crossProject/issueBacklogRegressionNewFeature"
                  className="text-decoration-none"
                  onClick={() => {
                    changeStyle("issueBacklogRegressionNewFeature");
                  }}
                >
                  Issue Backlog Analytics (Regression/New Features)
                </Link>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${isActiveList["automationTrending"]}`}
              >
                <Link
                  to="/crossProject/automationTrending"
                  className="text-decoration-none"
                  onClick={() => {
                    changeStyle("automationTrending");
                  }}
                >
                  Automation Trending
                </Link>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button className={`nav-link ${isActiveList["jiraCustom"]}`}>
                <Link
                  to="/crossProject/jiraCustom"
                  className="text-decoration-none"
                  onClick={() => {
                    changeStyle("jiraCustom");
                  }}
                >
                  Jira Custom
                </Link>
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
                <button className={`nav-link ${isActiveList["performance"]}`}>
                  <Link
                    to="/performance"
                    className="text-decoration-none"
                    onClick={() => {
                      changeStyle("performance");
                    }}
                  >
                    Performance
                  </Link>
                </button>
              </li> */}
          </ul>
          {/* <Navigate from="/" to="/crossproject/eda" /> */}
          <Routes>
            <Route
              exact
              path="projectTracker"
              element={
                <ProjectTracker token={props.token} changeStyle={changeStyle} />
              }
            ></Route>
            <Route
              path="defectAnalytics"
              element={
                <DefectAnalyticsPage
                  changeStyle={changeStyle}
                  token={props.token}
                />
              }
            ></Route>
            <Route
              path="defectByCategory"
              element={
                <DefectByCategoryPage
                  changeStyle={changeStyle}
                  token={props.token}
                />
              }
            ></Route>
            <Route
              path="eda"
              element={
                <EscapedDefectAnalytics
                  changeStyle={changeStyle}
                  token={props.token}
                />
              }
            ></Route>
            {/* <Route path="/issueBacklog">
                <div>Issue Backlog Under Construction</div>
              </Route> */}
            {/* <Route path="/issueBacklogRegression">
                <div>
                  Issue Backlog (Regression/New Features) Under Construction
                </div>
              </Route> */}
            {/* <Route path="/sonarQube">
                <div>Sonar Qube Under Construction</div>
              </Route> */}
            <Route
              path="automationTrending"
              element={
                <AutomationTrending
                  changeStyle={changeStyle}
                  token={props.token}
                />
              }
            ></Route>
            <Route
              path="jiraCustom"
              element={
                <JiraCustomPage changeStyle={changeStyle} token={props.token} />
              }
            ></Route>
            <Route
              path="issueBacklogRegression"
              element={
                <IssueBacklogRegression
                  changeStyle={changeStyle}
                  token={props.token}
                />
              }
            ></Route>
            <Route
              path="issueBacklogRegressionNewFeature"
              element={
                <IssueBacklogRegressionNewFeature
                  changeStyle={changeStyle}
                  token={props.token}
                />
              }
            ></Route>
            {/* <Route path="/performance">
                <div>Performance Under Construction</div>
              </Route> */}
          </Routes>
          {/* </Router> */}
        </div>
      </div>
    </div>
  );
};

export default CrossProject;
