import React, { useRef, useState, useEffect } from 'react';
import Select from 'react-select';
import { Table, Pagination } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';

const AssessmentTableComponent = ({ assessments, loading, programs, projects, showAssessmentReport, disableTable }) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7);
  const [columnFilters, setColumnFilters] = useState({
    assesmentname: '',
    program: '',
    project: '',
    assesmentType: '',
    enddate: '',
    assignedUser: '',
  });
  const dt2 = useRef(null);

  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const onColumnFilterChange = (e, column) => {
    setColumnFilters({ ...columnFilters, [column]: e.target.value });
  };

  const filteredData = assessments.filter(assessment => {
    return (
      assessment.assesmentname.toLowerCase().includes(globalFilter.toLowerCase()) ||
      assessment.program.toLowerCase().includes(globalFilter.toLowerCase()) ||
      assessment.project.toLowerCase().includes(globalFilter.toLowerCase()) ||
      assessment.assesmentType.toLowerCase().includes(globalFilter.toLowerCase()) ||
      assessment.enddate.toLowerCase().includes(globalFilter.toLowerCase()) ||
      assessment.assignedUser.toLowerCase().includes(globalFilter.toLowerCase())
    );
  }).filter(assessment => {
    return (
      assessment.assesmentname.toLowerCase().includes(columnFilters.assesmentname.toLowerCase()) &&
      assessment.program.toLowerCase().includes(columnFilters.program.toLowerCase()) &&
      assessment.project.toLowerCase().includes(columnFilters.project.toLowerCase()) &&
      assessment.assesmentType.toLowerCase().includes(columnFilters.assesmentType.toLowerCase()) &&
      assessment.enddate.toLowerCase().includes(columnFilters.enddate.toLowerCase()) &&
      assessment.assignedUser.toLowerCase().includes(columnFilters.assignedUser.toLowerCase())
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const allOption = { name: 'All' };

  return (
    <div>
      <div className="table-header" style={{ display: disableTable ? 'none' : 'table' }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" value={globalFilter} onChange={onGlobalFilterChange} placeholder="Global Search" disabled={disableTable} />
        </span>
      </div>
      <Table ref={dt2} striped bordered hover className="p-treetable-striped" scrollable={true} paginator={true} rows={7} style={{ display: disableTable ? 'none' : 'table' }}>
        <thead>
          <tr>
            <th>Assessment Name</th>
            <th>Program</th>
            <th>Project</th>
            <th>Type</th>
            <th>Submitted Date</th>
            <th>Assigned</th>
            <th style={{ width: '6rem' }}>Expired</th>
          </tr>
          <tr>
            <th>
              <input type="text" placeholder="Search Assessment Name" value={columnFilters.assesmentname} onChange={(e) => onColumnFilterChange(e, 'assesmentname')} />
            </th>
            <th>
              <Select
                options={[allOption, ...programs]}
                placeholder="Any"
                getOptionLabel={(option) => option.name}
                onChange={(e) => onColumnFilterChange({ target: { value: e && e.name !== 'All' ? e.name : '' } }, 'program')}
              />
            </th>
            <th>
              <Select
                options={[allOption, ...projects]}
                placeholder="Any"
                getOptionLabel={(option) => option.name}
                onChange={(e) => onColumnFilterChange({ target: { value: e && e.name !== 'All' ? e.name : '' } }, 'project')}
              />
            </th>
            <th>
              <Select
                options={[allOption, ...projects]}
                placeholder="Any"
                isClearable
                onChange={(e) => onColumnFilterChange({ target: { value: e && e.name !== 'All' ? e.name : '' } }, 'assesmentType')}
              />
            </th>
            <th>
              <input type="text" placeholder="Search Date" value={columnFilters.enddate} onChange={(e) => onColumnFilterChange(e, 'enddate')} />
            </th>
            <th>
              <input type="text" placeholder="Search Assigned" value={columnFilters.assignedUser} onChange={(e) => onColumnFilterChange(e, 'assignedUser')} />
            </th>
            <th>
              <input type="checkbox" />
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((assessment) => (
            <tr key={assessment.assessmentId}>
              <td>
                <button onClick={() => showAssessmentReport(assessment, assessment.assessmentId, assessment.templateId)}>
                  {assessment.assesmentname}
                </button>
              </td>
              <td>{assessment.program}</td>
              <td>{assessment.project}</td>
              <td>{assessment.assesmentType}</td>
              <td>{assessment.enddate}</td>
              <td>{assessment.assignedUser}</td>
              <td>
                <i className={`pi ${assessment.isExpired ? 'pi-check-circle' : 'pi-times-circle'}`}></i>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {!disableTable && (
        <div className="d-flex justify-content-center">
          <Pagination>
            <Pagination.First onClick={() => paginate(1)} />
            <Pagination.Prev onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)} />
            {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, index) => (
              <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => paginate(currentPage < Math.ceil(filteredData.length / itemsPerPage) ? currentPage + 1 : currentPage)} />
            <Pagination.Last onClick={() => paginate(Math.ceil(filteredData.length / itemsPerPage))} />
          </Pagination>
        </div>
      )}
    </div>
  );
};

export default AssessmentTableComponent;