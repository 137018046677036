export const projectTypesInitialState = [
  {
    projectTypeId: 1,
    projectTypeName: "ELM",
    projectTypeGroup: "ELM",
    sourceDefectType: "JIRA",
    sourceTestType: "qTest",
  },
  {
    projectTypeId: 2,
    projectTypeName: "Content Tech",
    projectTypeGroup: "Content Tech",
    sourceDefectType: "JIRA",
    sourceTestType: "qTest",
  },
  {
    projectTypeId: 3,
    projectTypeName: "Orion",
    projectTypeGroup: "GRC Tech",
    sourceDefectType: "JIRA",
    sourceTestType: "qTest",
  },
  {
    projectTypeId: 4,
    projectTypeName: "e-Store",
    projectTypeGroup: "e-Store",
    sourceDefectType: "JIRA",
    sourceTestType: "qTest",
  },
  {
    projectTypeId: 5,
    projectTypeName: "Global Atlas",
    projectTypeGroup: "GA",
    sourceDefectType: "JIRA",
    sourceTestType: "qTest",
  },
  {
    projectTypeId: 6,
    projectTypeName: "MediRegs",
    projectTypeGroup: "MediRegs",
    sourceDefectType: "JIRA",
    sourceTestType: "qTest",
  },
  {
    projectTypeId: 7,
    projectTypeName: "OneWeb",
    projectTypeGroup: "OneWeb",
    sourceDefectType: "JIRA",
    sourceTestType: "qTest",
  },
  {
    projectTypeId: 9,
    projectTypeName: "eReader",
    projectTypeGroup: "PS",
    sourceDefectType: "JIRA",
    sourceTestType: "qTest",
  },
  {
    projectTypeId: 10,
    projectTypeName: "HLRP Medical",
    projectTypeGroup: "HLRP Medical",
    sourceDefectType: "JIRA",
    sourceTestType: "qTest",
  },
  {
    projectTypeId: 11,
    projectTypeName: "HLRP Nursing",
    projectTypeGroup: "HLRP Nursing",
    sourceDefectType: "JIRA",
    sourceTestType: "qTest",
  },
  {
    projectTypeId: 12,
    projectTypeName: "WKAxcess",
    projectTypeGroup: "WKAxcess",
    sourceDefectType: "ADO",
    sourceTestType: "ADO",
  },
  {
    projectTypeId: 13,
    projectTypeName: "FS-RC",
    projectTypeGroup: "FS-RC",
    sourceDefectType: "ADO",
    sourceTestType: "ADO",
  },
  {
    projectTypeId: 14,
    projectTypeName: "FRR",
    projectTypeGroup: "FRR",
    sourceDefectType: "JIRA",
    sourceTestType: "qTest",
  },
  {
    projectTypeId: 15,
    projectTypeName: "TeamMate",
    projectTypeGroup: "TeamMate",
    sourceDefectType: "JIRA",
    sourceTestType: "qTest",
  },
];
