import FetchUtils from "./FetchUtils";

class Projects {
  static async getProjectTypes(token) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_PROJECT_SERVICE_URL + "/project-types",
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Project types cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting project types (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getAllProjects(token, activityFlag) {
    const headers = {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    let url = process.env.REACT_APP_PROJECT_SERVICE_URL + "/projects";
    if (activityFlag === true) {
      url += "/active";
    }
    if (activityFlag === false) {
      url += "/inactive";
    }

    let allProjects = [];

    while (true) {
      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || (data.status !== "OK" && data.status !== "100 CONTINUE")) {
        let errorMsg =
          "Projects cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      allProjects = allProjects.concat(data.data);

      if (data.status === "OK") {
        break;
      }
    }
    return allProjects;
  }

  static async getProjectDetails(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_PROJECT_SERVICE_URL + "/projects/" + projectId,
        "GET",
        headers
      );
      if (!data || data.status !== "OK") {
        let errorMsg =
          "Project details cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      if (Array.isArray(data.data.lpIterations)) {
      }

      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting project details (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async postProjectDetails(token, detailsObj) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Origin: process.env.REACT_APP_PROJECT_SERVICE_URL,
      };

      let url = process.env.REACT_APP_PROJECT_SERVICE_URL + "/projects";
      let body = JSON.stringify(detailsObj);
      const data = await FetchUtils.fetch(url, "POST", headers, body);

      if (!data || data.status !== "CREATED") {
        let errorMsg =
          "Error creating/updating the project: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error posting project details (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getStatusReportInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const data = await FetchUtils.fetch(
        process.env.REACT_APP_PROJECT_SERVICE_URL +
          "/projects/" +
          projectId +
          "/report-cache",
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Report cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting report (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async postStatusReportInfo(token, detailsObj) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Origin: process.env.REACT_APP_PROJECT_SERVICE_URL,
      };

      let url =
        process.env.REACT_APP_PROJECT_SERVICE_URL + "/projects/report-cache";
      let body = JSON.stringify(detailsObj);
      const data = await FetchUtils.fetch(url, "POST", headers, body);

      if (!data || data.status !== "CREATED") {
        let errorMsg =
          "Error during the project's report info saving: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error posting project report details (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getSyncInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_PROJECT_SERVICE_URL +
          "/projects/" +
          projectId +
          "/qtest-sync/latest",
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "qTest sync info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting qTest sync info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async postSyncJob(token, detailsObj) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Origin: process.env.REACT_APP_PROJECT_SERVICE_URL,
      };

      let url =
        process.env.REACT_APP_PROJECT_SERVICE_URL + "/projects/qtest-sync";
      let body = JSON.stringify(detailsObj);
      const data = await FetchUtils.fetch(url, "POST", headers, body);

      if (!data || data.status !== "CREATED") {
        let errorMsg =
          "Error submitting the sync job: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error submitting the sync job (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getProjectByProjectName(token, projectName) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_PROJECT_SERVICE_URL +
          "/projects/project-types/" +
          projectName,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Project types cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting project types (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }
}

export default Projects;
