import React, { useEffect, useState } from "react";
import NavigationTab from "../../components/common/NavigationTab";
import Loading from "../../components/common/Loading";
import ErrorMessage from "../../components/common/ErrorMessage";
import Insights from "../../services/Insights";
import InsightsCard from "../../components/common/InsightsCard";
import Button from "@mui/material/Button";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate, useLocation } from "react-router-dom";

async function getServiceInsightsSelectorData(token) {
  try {
    const response = await Insights.getServiceInsightsSelectorInfo(token);
    return response;
  } catch (err) {
    return {
      errMsg: err.message ? err.message : JSON.stringify(err),
    };
  }
}

async function getServiceInsightsCardData(token, payload) {
  try {
    const response = await Insights.getServiceInsightsCardInfo(token, payload);
    return response;
  } catch (err) {
    return {
      errMsg: err.message ? err.message : JSON.stringify(err),
    };
  }
}

const ServiceInsights = (props) => {
  const history = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [contentLoading, setContentLoading] = useState();
  const [secondCall, setSecondCall] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [insightsCardData, setInsightsCardData] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [environments, setEnvironments] = useState([]);
  const [selectedEnvironments, setSelectedEnvironments] = useState([]);
  const [components, setComponents] = useState([]);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [selectedQuery, setSelectedQuery] = useState({});
  const [previousQuery, setPreviousQuery] = useState(false);
  let hasInsightsDataStored = localStorage.getItem("serviceInsightsDetails");
  const ERROR_MESSAGE_STRING =
    "Service Insights information cannot be retrieved from server. Please try again in another moment or contact LaunchPad support team.";

  useEffect(() => {
    const getServiceInsightsData = async () => {
      const selectorResponse = await getServiceInsightsSelectorData(
        props.token
      );

      if (selectorResponse) {
        setErrorMessage();
        getSelectorOptions(selectorResponse);

        const cardsPayload = getCardsId(selectorResponse);

        try {
          const cardResponse = await getServiceInsightsCardData(
            props.token,
            cardsPayload
          );

          setSecondCall(true);
          setInsightsCardData(cardResponse);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setErrorMessage(ERROR_MESSAGE_STRING);
        }
      } else {
        setLoading(false);
        setErrorMessage(ERROR_MESSAGE_STRING);
      }
    };

    getServiceInsightsData();

    let store = JSON.parse(localStorage.getItem("serviceInsightsDetails"));

    if (store !== null) {
      setPreviousQuery(true);
    }
  }, []);

  useEffect(() => {
    const getDataOnChange = async () => {
      const cardsPayload = getCardsIdByInputValue();

      try {
        setContentLoading(true);
        const cardResponse = await getServiceInsightsCardData(
          props.token,
          cardsPayload
        );

        setInsightsCardData(cardResponse);
        setContentLoading(false);
      } catch (error) {
        return null;
      }
    };

    if (secondCall) {
      setSelectedQuery({});
      getDataOnChange();
    }
  }, [
    selectedPrograms,
    selectedServices,
    selectedComponents,
    selectedEnvironments,
    selectedRegions,
  ]);

  const getSelectorOptions = (options) => {
    let servicesArray = [];
    let regionsArray = [];
    let environmentsArray = [];
    let componentsArray = [];
    let programs = options.map((program) => {
      return {
        id: program.id,
        label: program.description,
        value: program.code,
      };
    });

    options.map((program) => {
      return program.services.forEach((service) => {
        servicesArray.push(service);
        service.regions.forEach((region) => {
          if (regionsArray.some((option) => option.code === region.code)) {
            return;
          }
          regionsArray.push(region);
        });
        service.environments.forEach((environment) => {
          if (
            environmentsArray.some((option) => option.code === environment.code)
          ) {
            return;
          }
          environmentsArray.push(environment);
        });
        service.components.forEach((component) => {
          if (
            componentsArray.some((option) => option.code === component.code)
          ) {
            return;
          }
          componentsArray.push(component);
        });
      });
    });

    let finalServices = servicesArray.map((service) => {
      return {
        id: service.id,
        label: service.name,
        value: service.name,
      };
    });
    let finalRegions = regionsArray.map((region) => {
      return {
        id: region.id,
        label: region.description,
        value: region.code,
      };
    });
    let finalEnvironments = environmentsArray.map((environment) => {
      return {
        id: environment.id,
        label: environment.description,
        value: environment.code,
      };
    });
    let finalComponents = componentsArray.map((component) => {
      return {
        id: component.id,
        label: component.code,
        value: component.code,
      };
    });

    setPrograms(programs);
    setSelectedPrograms(programs);
    setServices(finalServices);
    setSelectedServices(finalServices);
    setRegions(finalRegions);
    setSelectedRegions(finalRegions);
    setEnvironments(finalEnvironments);
    setSelectedEnvironments(finalEnvironments);
    setComponents(finalComponents);
    setSelectedComponents(finalComponents);
  };

  const getCardsId = (selectors) => {
    let payload = {};

    selectors.forEach((program) => {
      payload = {
        programsId: [program.id],
        servicesId: program.services.map((service) => service.id),
        componentsId: extractIds(program, "components"),
        environmentsId: extractIds(program, "environments"),
        regionsId: extractIds(program, "regions"),
      };
    });

    return payload;
  };

  const getCardsIdByInputValue = () => {
    let payload = {};

    payload = {
      programsId: selectedPrograms.map((program) => program.id),
      servicesId: selectedServices.map((service) => service.id),
      componentsId: selectedComponents.map((component) => component.id),
      environmentsId: selectedEnvironments.map((environment) => environment.id),
      regionsId: selectedRegions.map((region) => region.id),
    };

    return payload;
  };

  const extractIds = (array, keyToExtract) => {
    let extractedIdsArray = [];

    array.services.forEach((service) => {
      service[keyToExtract].forEach((elm) => extractedIdsArray.push(elm.id));
    });

    return extractedIdsArray;
  };

  const setQuery = (service) => {
    const { serviceName } = service;

    if (Object.keys(selectedQuery).includes(serviceName)) {
      let stateCopy = { ...selectedQuery };
      delete stateCopy[serviceName];

      return setSelectedQuery({ ...stateCopy });
    }

    setSelectedQuery((prevState) => {
      return { ...prevState, [serviceName]: service };
    });
  };

  const runQuery = (service) => {
    const { serviceName } = service;
    let objectToStore = { [serviceName]: service };
    let clickedService = services.find((service) => {
      return service.label === serviceName;
    });

    localStorage.setItem(
      "serviceInsightsDetails",
      JSON.stringify(objectToStore)
    );
    localStorage.setItem(
      "serviceInsightsDetailsIds",
      JSON.stringify([clickedService])
    );
    history(location.pathname, { replace: true });
    history(`/service-insights-details`);
  };

  const handleQueryClick = () => {
    let checkedServices = Object.keys(selectedQuery);
    let finalIds = [];

    checkedServices.map((service) => {
      return services.forEach((serviceInfo) => {
        if (service === serviceInfo.label) {
          finalIds.push(serviceInfo);
        }
      });
    });

    localStorage.setItem(
      "serviceInsightsDetails",
      JSON.stringify(selectedQuery)
    );
    localStorage.setItem("serviceInsightsDetailsIds", JSON.stringify(finalIds));
    history(location.pathname, { replace: true });
    history(`/service-insights-details`);
  };

  return (
    <div id="service_insights_main" style={{ minHeight: "calc(100vh - 60px)" }}>
      <NavigationTab
        changeStyle={props.navTabChangeStyle}
        currentProjectId={props.currentProjectId}
        currentInsightsDetails={hasInsightsDataStored}
      />
      {!loading ? (
        <div id="service_insights_main_container">
          <div id="service_insights_main_container_1">
            <div
              id="service_insights_header_container"
              className="container-fluid pt-3 pb-3"
            >
              <ErrorMessage errorMessage={errorMessage} />
              <h4 className="ms-4" style={{ marginBottom: "20px" }}>
                Service Insights
              </h4>
              <div className="container-fluid">
                <div className="row ms-1 me-1 border pt-3 pb-4 px-0 border-ligth border-1 rounded">
                  <div className="col-sm-12 px-5 py-2">
                    <div className="row justify-content-center mb-4">
                      <div className="col-sm-6">
                        <label htmlFor="programs" className="form-label fs-5">
                          Programs
                        </label>
                        <MultiSelect
                          id="programs"
                          options={programs}
                          placeholder="Select Programs"
                          onChange={setSelectedPrograms}
                          value={selectedPrograms}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="services" className="form-label fs-5">
                          Services
                        </label>
                        <MultiSelect
                          id="services"
                          options={services}
                          placeholder="Select Services"
                          onChange={setSelectedServices}
                          value={selectedServices}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-sm-4">
                        <label htmlFor="regions" className="form-label fs-5">
                          Regions
                        </label>
                        <MultiSelect
                          id="regions"
                          options={regions}
                          placeholder="Select Regions"
                          onChange={setSelectedRegions}
                          value={selectedRegions}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label
                          htmlFor="environments"
                          className="form-label fs-5"
                        >
                          Environments
                        </label>
                        <MultiSelect
                          id="environments"
                          options={environments}
                          placeholder="Select Environments"
                          onChange={setSelectedEnvironments}
                          value={selectedEnvironments}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="components" className="form-label fs-5">
                          Components
                        </label>
                        <MultiSelect
                          id="components"
                          options={components}
                          placeholder="Select Components"
                          onChange={setSelectedComponents}
                          value={selectedComponents}
                        />
                      </div>
                      <div className="d-flex justify-content-end mt-4">
                        {previousQuery && (
                          <div style={{ paddingRight: "10px" }}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                history(location.pathname, { replace: true });
                                history(`/service-insights-details`);
                              }}
                            >
                              Previous query
                            </Button>
                          </div>
                        )}
                        <div>
                          <Button
                            variant="contained"
                            disabled={
                              Object.keys(selectedQuery).length < 2 ||
                              Object.keys(selectedQuery).length >= 4
                            }
                            onClick={handleQueryClick}
                          >
                            Run Query
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Object.keys(selectedQuery).length < 2 ||
            (Object.keys(selectedQuery).length >= 4 && (
              <div
                className="alert alert-warning text-center w-50 mx-auto"
                role="alert"
              >
                Warning! You can only select up to 3 services query at the same
                time.
              </div>
            ))}
          <div
            id="service_insights_main_container_cards"
            className="bg-light container-fluid pt-3 pb-3 mt-3"
          >
            <div className="row row-cols-4 mx-3">
              {contentLoading ? (
                <Loading />
              ) : insightsCardData.length ? (
                insightsCardData &&
                insightsCardData.map((item, index) => {
                  return (
                    <div className="col-sm-4 mb-4" key={index}>
                      <InsightsCard
                        serviceName={item.serviceName}
                        serviceVersion={item.serviceVersion}
                        serviceRegions={item.availableRegionsIn}
                        infoLink={item.confluencePage}
                        indicator="Healthly"
                        checked={() => setQuery(item)}
                        onClick={() => {
                          runQuery(item);
                        }}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="mx-auto w-75">
                  <ErrorMessage
                    errorMessage={
                      "There are no results matching with the current selected filters, please make another search."
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ServiceInsights;
