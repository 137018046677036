import React, { useState, useEffect, useRef } from "react";
import CommonService from "../../../services/ScoreCardService";
import Select from "react-select";
import { Button, Tabs, Tab, Table } from "react-bootstrap";
import AssessmentTableComponent from "../reports/AssessmentTableComponent";
import ReportSectionComponent from "../reports/ReportSectionComponent";

const AdminReportsComponent = () => {
  const [token, setToken] = useState([]);
  const [assessmentReportDialog, setAssessmentReportDialog] = useState(false);
  const [assessmentReport, setAssessmentReport] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assessments, setAssessments] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [projects, setProjects] = useState([]);
  const [units, setUnits] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const [assessment, setAssessment] = useState({});
  const [quarters, setQuarters] = useState([]);
  const [selectedQuarter, setSelectedQuarter] = useState({});
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState({});
  const [heatMapScoreCardData, setHeatMapScoreCardData] = useState([]);
  const [heatMapSelfAssessmentData, setHeatMapSelfAssessmentData] = useState(
    []
  );
  const [heatMapProjects, setHeatMapProjects] = useState([]);
  const [heatMapList, setHeatMapList] = useState([]);
  const [key, setKey] = useState("userReports"); // Set default tab to 'userReports'
  const [globalFilter, setGlobalFilter] = useState("");
  const dt2 = useRef(null);
  const [disableTable, setDisableTable] = useState(false);

  useEffect(() => {
    // getAllAssessments();
    // getAllProgram();
    // getAllProjects();
    // getAllDepartments();
    setQuarters([
      { label: "Q1", value: "1" },
      { label: "Q2", value: "2" },
      { label: "Q3", value: "3" },
      { label: "Q4", value: "4" },
    ]);
    getYears();
  }, []);

  const filterScorecardAndSelfAssements = async () => {
    console.log(selectedDepartment, selectedQuarter, selectedYear);
    const data = await CommonService.getScorecardAndSelfAssements(
      token,
      selectedDepartment,
      selectedQuarter,
      selectedYear
    );
    console.log(data);
    const scorecardAssessmentList = data.scorecard.map((element) => ({
      assesmentname: element.assesmentname,
      assessmentId: element.id,
      program: element.program,
      project: element.project.name,
      assignedto: element.assignedto,
      assignedUser: element.assignedUser,
      createddate: element.createddate
        ? converDateToMMDDYYYY(element.createddate)
        : "--",
      startdate: element.startdate
        ? converDateToMMDDYYYY(element.startdate)
        : "--",
      enddate: element.enddate ? converDateToMMDDYYYY(element.enddate) : "--",
      expirydate: converDateToMMDDYYYY(element.expirydate),
      assesmentType: element.assesmentType,
      unit: element.unit.name,
      status: element.status,
      activity: element.activity,
      owners: element.owners,
      templateId: element.templateId,
      isExpired: new Date(element.expirydate) < new Date(),
    }));
    setHeatMapScoreCardData(scorecardAssessmentList);

    const selfAssessmentList = data.selfAssessment.map((element) => ({
      assesmentname: element.assesmentname,
      assessmentId: element.id,
      program: element.program,
      project: element.project.name,
      assignedto: element.assignedto,
      assignedUser: element.assignedUser,
      createddate: element.createddate
        ? converDateToMMDDYYYY(element.createddate)
        : "--",
      startdate: element.startdate
        ? converDateToMMDDYYYY(element.startdate)
        : "--",
      enddate: element.enddate ? converDateToMMDDYYYY(element.enddate) : "--",
      expirydate: converDateToMMDDYYYY(element.expirydate),
      assesmentType: element.assesmentType,
      unit: element.unit.name,
      status: element.status,
      activity: element.activity,
      owners: element.owners,
      templateId: element.templateId,
      isExpired: new Date(element.expirydate) < new Date(),
    }));
    setHeatMapSelfAssessmentData(selfAssessmentList);
    setHeatMapProjects(data.projects);
  };

  const getYears = () => {
    let currentYear = new Date().getFullYear();
    let startYear = currentYear - 4;
    let years = [];
    for (let i = startYear; i <= currentYear; i++) {
      years.push({ label: i, value: i });
    }
    setYears(years);
  };

  const getAllAssessments = async () => {
    const data = await CommonService.getAllAssessments();
    console.log(data);
    let assessmentList = [];
    data.forEach((element) => {
      let assessment = {
        assesmentname: element.assesmentname,
        assessmentId: element.id,
        program: element.program,
        project: element.project.name,
        assignedto: element.assignedto,
        assignedUser: element.assignedUser,
        createddate: element.createddate
          ? converDateToMMDDYYYY(element.createddate)
          : "--",
        startdate: element.startdate
          ? converDateToMMDDYYYY(element.startdate)
          : "--",
        enddate: element.enddate ? converDateToMMDDYYYY(element.enddate) : "--",
        expirydate: converDateToMMDDYYYY(element.expirydate),
        assesmentType: element.assesmentType,
        unit: element.unit.name,
        status: element.status,
        activity: element.activity,
        owners: element.owners,
        templateId: element.templateId,
        isExpired: new Date(element.expirydate) < new Date(),
      };
      if (element.status === "Completed") {
        assessmentList.push(assessment);
      }
    });
    assessmentList.sort((a, b) => {
      return new Date(b.enddate).getTime() - new Date(a.enddate).getTime();
    });
    setLoading(false);
    setAssessments(assessmentList);
    console.log(assessments);
  };

  const getAllProgram = async () => {
    const data = await CommonService.getAllPrograms();
    console.log(data);
    setPrograms(data);
  };

  const getAllProjects = async () => {
    const data = await CommonService.getAllProjects();
    console.log(data);
    setProjects(data);
  };

  const getAllDepartments = async () => {
    const data = await CommonService.getAllDepartments();
    console.log(data);
    setDepartments(data);
  };

  const converDateToMMDDYYYY = (pDate) => {
    let date = new Date();
    date.setMonth(Number(pDate.split("-")[1]) - 1);
    date.setDate(Number(pDate.split("-")[2]));
    date.setFullYear(Number(pDate.split("-")[0]));
    console.log(date);
    let convertedDate =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "/" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "/" +
      date.getFullYear();
    return convertedDate;
  };

  const showAssessmentReport = async (assessment, assesmentId, templateId) => {
    console.log(assesmentId, templateId);
    setAssessment(assessment);
    const data = await CommonService.getReortaByTemplateIdAndAssessmentId(
      token,
      templateId,
      assesmentId
    );
    setAssessmentReport(data);
    setAssessmentReportDialog(true);
    setDisableTable(true);
    console.log(assessmentReport);
  };

  const exportTableToExcel = (tableID, filename = "") => {
    let downloadLink;
    let dataType = "application/vnd.ms-excel";
    let tableSelect = document.getElementById(tableID);
    let tableHTML = tableSelect
      ? tableSelect.outerHTML.replace(/ /g, "%20")
      : "";
    var time = new Date().getTime();
    filename = filename
      ? filename + "_" + time + ".xls"
      : "export_data_" + time + ".xls";
    downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    if (navigator.msSaveOrOpenBlob) {
      var blob = new Blob(["\ufeff", tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = "data:" + dataType + ", " + tableHTML;
      downloadLink.download = filename;
      downloadLink.click();
    }
  };

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const filteredAssessments = assessments.filter((assessment) => {
    return (
      assessment.assesmentname
        .toLowerCase()
        .includes(globalFilter.toLowerCase()) ||
      assessment.program.toLowerCase().includes(globalFilter.toLowerCase()) ||
      assessment.project.toLowerCase().includes(globalFilter.toLowerCase()) ||
      assessment.assesmentType
        .toLowerCase()
        .includes(globalFilter.toLowerCase()) ||
      assessment.enddate.toLowerCase().includes(globalFilter.toLowerCase()) ||
      assessment.assignedUser.toLowerCase().includes(globalFilter.toLowerCase())
    );
  });

  const selectScoreCardAssessment = (assessment, project, type) => {
    let updatedHeatMapList = [...heatMapList];
    const existingProject = updatedHeatMapList.find(
      (item) => item.project === project
    );

    if (existingProject) {
      if (type === "scorecard-assessment") {
        existingProject["scorecard-assessment"] = assessment.assessmentId;
      } else {
        existingProject["self-assessment"] = assessment.assessmentId;
      }
    } else {
      if (type === "scorecard-assessment") {
        updatedHeatMapList.push({
          "scorecard-assessment": assessment.assessmentId,
          project: project,
          "self-assessment": "",
        });
      } else {
        updatedHeatMapList.push({
          "scorecard-assessment": "",
          project: project,
          "self-assessment": assessment.assessmentId,
        });
      }
    }

    setHeatMapList(updatedHeatMapList);
  };

  return (
    <div>
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        disabled={assessmentReportDialog}
      >
        <Tab
          eventKey="userReports"
          title="User Reports"
          disabled={assessmentReportDialog}
        >
          <AssessmentTableComponent
            assessments={filteredAssessments}
            loading={loading}
            programs={programs}
            projects={projects}
            showAssessmentReport={showAssessmentReport}
            disableTable={disableTable}
          />
          {assessmentReportDialog && (
            <ReportSectionComponent
              assessment={assessment}
              assessmentReport={assessmentReport}
              setAssessmentReportDialog={setAssessmentReportDialog}
              exportTableToExcel={exportTableToExcel}
              setDisableTable={setDisableTable}
            />
          )}
        </Tab>
        <Tab
          eventKey="heatmapReport"
          title="Heatmap Report"
          disabled={assessmentReportDialog}
        >
          <div
            style={{ minHeight: "65px", minWidth: "75rem", marginTop: "15px" }}
          >
            <div
              className="flex flex-column gap-4"
              style={{ marginLeft: "10px", float: "left" }}
            >
              <label style={{ marginRight: "8px" }} htmlFor="department">
                Department:
              </label>
              <Select
                options={departments}
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e)}
                getOptionLabel={(option) => option.name}
                placeholder="Please Select Department"
                isDisabled={assessmentReportDialog}
              />
            </div>
            <div
              className="flex flex-column gap-4"
              style={{ marginLeft: "10px", float: "left" }}
            >
              <label style={{ marginRight: "8px" }} htmlFor="year">
                Year:
              </label>
              <Select
                options={years}
                value={selectedYear}
                onChange={(e) => setSelectedYear(e)}
                getOptionLabel={(option) => option.value}
                placeholder="Please Select Year"
                isDisabled={assessmentReportDialog}
              />
            </div>
            <div
              className="flex flex-column gap-4"
              style={{ marginLeft: "10px", float: "left" }}
            >
              <label style={{ marginRight: "8px" }} htmlFor="quarter">
                Quarter:
              </label>
              <Select
                options={quarters}
                value={selectedQuarter}
                onChange={(e) => setSelectedQuarter(e)}
                getOptionLabel={(option) => option.label}
                placeholder="Please Select Quarter"
                isDisabled={assessmentReportDialog}
              />
            </div>
            <div
              className="flex flex-column gap-4"
              style={{ marginLeft: "18px", float: "left" }}
            >
              <Button
                variant="secondary"
                onClick={filterScorecardAndSelfAssements}
                disabled={assessmentReportDialog}
              >
                Get Assessments
              </Button>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            {heatMapProjects.map((project, index) => (
              <div key={index} style={{ marginBottom: "20px" }}>
                <h5>{project}</h5>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Assessment Name</th>
                          <th>Program</th>
                          <th>Assigned User</th>
                          <th>End Date</th>
                          <th>Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        {heatMapScoreCardData
                          .filter((item) => item.project === project)
                          .map((item, idx) => (
                            <tr key={idx}>
                              <td>{item.assesmentname}</td>
                              <td>{item.program}</td>
                              <td>{item.assignedUser}</td>
                              <td>{item.enddate}</td>
                              <td>
                                <input
                                  type="radio"
                                  name={`${project}_score`}
                                  onClick={() =>
                                    selectScoreCardAssessment(
                                      item,
                                      project,
                                      "scorecard-assessment"
                                    )
                                  }
                                  disabled={assessmentReportDialog}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px" }}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Assessment Name</th>
                          <th>Program</th>
                          <th>Assigned User</th>
                          <th>End Date</th>
                          <th>Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        {heatMapSelfAssessmentData
                          .filter((item) => item.project === project)
                          .map((item, idx) => (
                            <tr key={idx}>
                              <td>{item.assesmentname}</td>
                              <td>{item.program}</td>
                              <td>{item.assignedUser}</td>
                              <td>{item.enddate}</td>
                              <td>
                                <input
                                  type="radio"
                                  name={`${project}_self`}
                                  onClick={() =>
                                    selectScoreCardAssessment(
                                      item,
                                      project,
                                      "self-assessment"
                                    )
                                  }
                                  disabled={assessmentReportDialog}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AdminReportsComponent;
