import React, { useEffect, useState } from "react";
import ErrorMessage from "../../components/common/ErrorMessage";
import useErrorMessage from "../../components/hooks/useErrorMessage";
import RequirementAverageAgeChart from "../../components/charts/requirementsCharts/RequirementsAverageAgeChart";
import RequirementsStatusTrendingChart from "../../components/charts/requirementsCharts/RequirementsStatusTrendingChart";
import Jira from "../../services/Jira";
import Loading from "../../components/common/Loading";
import { useParams } from "react-router-dom";

async function getRequirementsAverageAgeChartInfo(token, projectId) {
  try {
    const resp = await Jira.getRequirementAverageAgeJiraInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error Jira info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

const RequirementsStatusPage = (props) => {
  const { setErrorMessage, errorMessage } = useErrorMessage();
  const [loading, setLoading] = useState(true);
  const [averageAgeData, setAverageAgeData] = useState();

  const { projectId } = useParams();

  let tokenId;

  if (props.token) {
    tokenId = props.token;
  } else {
    tokenId = window.localStorage.getItem("token");
  }

  useEffect(() => {
    const handleRequirementsAverageAgeData = async () => {
      if (!errorMessage) {
        const response = await getRequirementsAverageAgeChartInfo(
          tokenId,
          projectId
        );

        if (response?.errMsg) {
          setErrorMessage(response.errMsg);
          setAverageAgeData(null);
          setLoading(false);
        } else {
          setAverageAgeData(response);
          setLoading(false);
        }
      }
    };

    props.changeStyle("requirementsStatus");
    handleRequirementsAverageAgeData();
  }, []);

  return (
    <div>
      <h4 className="ms-4">Requirements Status</h4>
      {loading ? (
        <Loading />
      ) : (
        <div className="ms-4 mt-4">
          <ErrorMessage errorMessage={errorMessage} />
          <div className="row ms-3 me-3 mb-5">
            <div id="requirementsStatusTrendingChart" className="col-12">
              <div id="req-status-trending-root">
                <RequirementsStatusTrendingChart
                  token={props.token}
                  currentProjectId={props.currentProjectId}
                  setCurrentProjectId={props.setCurrentProjectId}
                />
              </div>
            </div>
          </div>
          <div className="row ms-3 me-3">
            <div id="requirementsAverageAgeChart" className="col-12">
              <div id="req-average-age-root">
                <h4>Requirements Average Age</h4>
                <RequirementAverageAgeChart
                  token={props.token}
                  currentProjectId={props.currentProjectId}
                  setCurrentProjectId={props.setCurrentProjectId}
                  data={averageAgeData}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequirementsStatusPage;
