// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.custom-treetable .p-treetable-thead > tr > th {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
  text-align: center;
}

.custom-treetable .p-treetable-tbody > tr > td {
  text-align: center;
  vertical-align: middle;
}

.custom-treetable .p-treetable-tbody > tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-treetable .p-treetable-tbody > tr:hover {
  background-color: #e0e0e0;
}

.custom-treetable .p-treetable-tbody > tr > td > button {
  color: #007ad9;
  font-weight: bold;
}

.custom-treetable .p-treetable-tbody > tr > td > button:hover {
  color: #005bb5;
}`, "",{"version":3,"sources":["webpack://./src/styles/custom.css"],"names":[],"mappings":";AACA;EACE,yBAAyB;EACzB,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["\n.custom-treetable .p-treetable-thead > tr > th {\n  background-color: #f4f4f4;\n  color: #333;\n  font-weight: bold;\n  text-align: center;\n}\n\n.custom-treetable .p-treetable-tbody > tr > td {\n  text-align: center;\n  vertical-align: middle;\n}\n\n.custom-treetable .p-treetable-tbody > tr:nth-child(even) {\n  background-color: #f9f9f9;\n}\n\n.custom-treetable .p-treetable-tbody > tr:hover {\n  background-color: #e0e0e0;\n}\n\n.custom-treetable .p-treetable-tbody > tr > td > button {\n  color: #007ad9;\n  font-weight: bold;\n}\n\n.custom-treetable .p-treetable-tbody > tr > td > button:hover {\n  color: #005bb5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
