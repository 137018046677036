import React, { useState } from 'react';

const ReportSectionComponent = ({ assessment, assessmentReport, setAssessmentReportDialog, exportTableToExcel, setDisableTable }) => {
    const [reportData, setReportData] = useState(assessmentReport);

    const showHideContent = (module) => {
        const updatedReportData = reportData.map((mod) => {
            if (mod.module_name === module.module_name) {
                return { ...mod, show: !mod.show };
            }
            return mod;
        });
        setReportData(updatedReportData);
    };

    return (
        <div>
            <div style={{ minWidth: '250px', width: '100%', float: 'left', marginBottom: '15px' }}>
                <button type="button" style={{ width: '240px', float: 'left' }} className="btn btn-secondary" onClick={() => { setAssessmentReportDialog(false); setDisableTable(false); }}>Back to Reports</button>
                <button type="button" style={{ width: '240px', float: 'right' }} className="btn btn-primary" onClick={() => exportTableToExcel('report-assessment', 'report')}>Export Report</button>
            </div>
            <div style={{ width: '100%', padding: '0px 0px 30px', minHeight: '130px' }}>
                <div style={{ width: '100%', float: 'left' }}>
                    <div style={{ float: 'left', fontSize: '18px' }}>{assessment.assesmentname}</div>
                    <div style={{ float: 'right', fontSize: '18px' }}>Submitted By: {assessment.assignedUser}</div>
                </div>
                <div style={{ width: '100%', float: 'left' }}>
                    <div style={{ float: 'left', fontSize: '18px' }}>{assessment.program} : {assessment.project}</div>
                    <div style={{ float: 'right', fontSize: '18px' }}>Submitted On: {assessment.enddate}</div>
                </div>
            </div>
            <div style={{ marginBottom: '30px', width: '100%', tableLayout: 'fixed' }}>
                <h4 className="report-title">ScoreCard: Assessment Result</h4>
            </div>
            <table style={{ tableLayout: 'fixed', minWidth: '900px' }} id="report-assessment" className="table table-bordered">
                <thead>
                    <tr>
                        <th className="text-center align-middle" style={{ width: '200px' }}>Area</th>
                        <th className="text-center align-middle" style={{ width: '500px' }}>Criteria</th>
                        <th className="text-center align-middle">Weight</th>
                        <th className="text-center align-middle">Assessed Level</th>
                        <th className="text-center align-middle">Target Level</th>
                        <th className="text-center align-middle" style={{ width: '80px' }}>WT-SCORE</th>
                        <th className="text-center align-middle" style={{ width: '80px' }}>MAX WT SC</th>
                        <th className="text-center align-middle">Result%</th>
                        <th className="text-center align-middle">Maturity Rating</th>
                    </tr>
                </thead>
                <tbody>
                    {reportData.map((module, i) => (
                        <React.Fragment key={i}>
                            <tr onClick={() => showHideContent(module)}>
                                <td colSpan={9} className="text-center align-middle report-data" style={{ backgroundColor: 'lightgray', fontSize: '18px', fontWeight: 'bold', cursor: 'pointer' }}>
                                    {module.module_name}
                                    <i className={`fa ${module.show ? 'fa-chevron-up' : 'fa-chevron-down'}`} style={{ color: 'rgb(34, 33, 31)', float: 'right' }}></i>
                                </td>
                            </tr>
                            {module.subModuleList.map((subModule, j) => (
                                subModule.ciList.map((criteria, k) => (
                                    <tr key={k} style={{ display: module.show ? 'table-row' : 'none' }}>
                                        {k === 0 && (
                                            <td rowSpan={subModule.ciList.length} className="text-center align-middle report-data" style={{ width: '200px', fontWeight: 'bold', color: '#1D4ED8' }}>
                                                {subModule.sub_module_name}
                                            </td>
                                        )}
                                        <td className="text-center align-middle report-data" style={{ width: '500px' }}>{criteria.criteria_description}</td>
                                        <td className="text-center align-middle report-data">{criteria.weight}</td>
                                        <td className="text-center align-middle report-data">{criteria.assessedLevel}</td>
                                        <td className="text-center align-middle report-data">{criteria.target_level}</td>
                                        <td className="text-center align-middle report-data">{criteria.wt_score}</td>
                                        <td className="text-center align-middle report-data">{criteria.max_wt_sc}</td>
                                        {j === 0 && k === 0 && (
                                            <>
                                                <td rowSpan={module.totalCriteria} className="text-center align-middle report-data" style={{ fontWeight: 'bold', color: '#1D4ED8' }}>{module.score}%</td>
                                                <td rowSpan={module.totalCriteria} className="text-center align-middle report-data" style={{ fontWeight: 'bold', color: '#1D4ED8' }}>{module.rating}</td>
                                            </>
                                        )}
                                    </tr>
                                ))
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            <div style={{ minWidth: '250px', width: '100%', float: 'left', marginTop: '80px' }}>
                <button type="button" style={{ width: '240px', float: 'left', marginLeft: '12px' }} className="btn btn-secondary" onClick={() => setAssessmentReportDialog(false)}>Back to Reports</button>
                <button type="button" style={{ width: '240px', float: 'right' }} className="btn btn-primary" onClick={() => exportTableToExcel('report-assessment', 'report')}>Export Report</button>
            </div>
        </div>
    );
};

export default ReportSectionComponent;