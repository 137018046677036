import React, { useState, useEffect } from "react";
import ProjectCard from "./ProjectCard";
import ProjectTypes from "./ProjectTypes";
import Projects from "../../services/Projects";
import EmptyStar from "../../assets/images/star-regular.svg";
import useErrorMessage from "../hooks/useErrorMessage";
import useProjects from "../hooks/useProjects";
import useProjectDetails from "../hooks/useProjectDetails";
import useUserInfo from "../hooks/useUserInfo";
import ReactModal from "react-modal";
import { useAppContext } from "../../contexts/AppContext";
import { useNavigate } from "react-router-dom";
import "../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

const favouriteProjectIdFromUserLoggedInMock = [1614, 1670, 1684];

async function getAllProjects(token) {
  try {
    return await Projects.getAllProjects(token);
  } catch (err) {
    return {
      message: "Projects cannot be retrieved! (" + JSON.stringify(err) + ")",
    };
  }
}

async function getActiveProjects(token) {
  try {
    return Projects.getAllProjects(token, true);
  } catch (err) {
    return {
      message:
        "Active projects cannot be retrieved! (" + JSON.stringify(err) + ")",
    };
  }
}

async function getInactiveProjects(token) {
  try {
    return Projects.getAllProjects(token, false);
  } catch (err) {
    return {
      message:
        "Inactive projects cannot be retrieved! (" + JSON.stringify(err) + ")",
    };
  }
}

export default function ProjectsList(props) {
  const initialProjectsCountState = { all: 0, active: 0, inactive: 0 };
  const history = useNavigate();
  const { selectedProjectType, setSelectedProjectType } = useProjects();
  const { projects, setProjects, toRefreshProjects } = useProjects();
  const {
    activeProjects,
    setActiveProjects,
    inactiveProjects,
    setInactiveProjects,
  } = useProjects();
  const { errorMessage, clearErrorMessage, setErrorMessage } =
    useErrorMessage();
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [activeFilterValue, setActiveFilterValue] = useState("ACTIVE");
  const [favoritesFilterValue, setFavoritesFilterValue] = useState();
  const [toFilter, setToFilter] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [projectsCount, setProjectsCount] = useState(initialProjectsCountState);
  const { setProjectDetails, setAddNewProjectFlag } = useProjectDetails();
  const { loggedUser } = useUserInfo({});
  const [addNewProjectDisabled, setAddNewProjectDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingActive, setLoadingActive] = useState(false);
  const [loadingInactive, setLoadingInactive] = useState(false);
  const [toDisableInactiveButton, setToDisableInactiveButton] = useState(false);
  const [toUpdateActiveProjects, setToUpdateActiveProjects] = useState(false);
  const [toUpdateInactiveProjects, setToUpdateInactiveProjects] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [date_modal, set_date_modal] = useState("");
  const [project_modal, set_project_modal] = useState("");
  const { selectedProjectTypeId, dispatch } = useAppContext();
  const styleDeleteModal = {
    content: {
      border: "4px",
      borderCcolor: "#000000",
      borderRadius: "4px",
      bottom: "auto",
      maxHeight: "20%",
      minHeight: "20rem",
      left: "50%",
      padding: "1rem",
      position: "fixed",
      right: "auto",
      top: "50%",
      transform: "translate(-50%,-50%)",

      width: "45%",

      backgroundColor: "#e7eff8",
      boxShadow: "0 5px 15px rgba(0,0,0,.5)",
    },
  };
  const favoritesFilterValueFromStorage =
    localStorage.getItem("favoritesFilterValue") === "true" ? true : false;

  useEffect(() => {
    clearErrorMessage();
  });

  useEffect(() => {
    if (
      toRefreshProjects ||
      !projects ||
      Object.entries(projects).length === 0
    ) {
      setToUpdateActiveProjects(true);
      setToUpdateInactiveProjects(true);
    }
  }, [toRefreshProjects]);

  useEffect(() => {
    let isStoreCreated = selectedProjectTypeId;

    if (!isStoreCreated) {
      dispatch({
        type: "EDIT_CURRENT_SELECTED_PROJECT_TYPE_ID",
        value: "All",
      });
    }
  }, []);

  useEffect(() => {
    const handleInactiveProjects = async (e) => {
      if (activeFilterValue === "INACTIVE" || activeFilterValue === "ALL") {
        setLoadingState(true);
      } else {
        setToDisableInactiveButton(true);
      }
      let response = await getInactiveProjects(props.token);
      if (activeFilterValue === "INACTIVE" || activeFilterValue === "ALL") {
        setLoadingState(false);
      } else {
        setToDisableInactiveButton(false);
      }
      if (response.message) {
        setErrorMessage(response.message);
        setInactiveProjects([]);
      } else {
        setInactiveProjects(response);
      }
    };

    if (toUpdateInactiveProjects) {
      handleInactiveProjects();
      setToUpdateInactiveProjects(false);
    }
  }, [toUpdateInactiveProjects]);

  useEffect(() => {
    const handleActiveProjects = async (e) => {
      if (activeFilterValue === "ACTIVE") {
        setLoadingState(true);
      }
      let responseActive = await getActiveProjects(props.token);
      if (activeFilterValue === "ACTIVE") {
        setLoadingState(false);
      }
      if (responseActive.message) {
        setErrorMessage(responseActive.message);
        setActiveProjects([]);
      } else {
        setActiveProjects(responseActive);
      }
    };

    if (toUpdateActiveProjects) {
      setToUpdateActiveProjects(false);
      handleActiveProjects();
    }
  }, [toUpdateActiveProjects]);

  useEffect(() => {
    if (activeProjects && !inactiveProjects) {
      let allProjects = activeProjects;
      setProjects(allProjects);
      setProjectsCount({
        all: activeProjects.length,
        active: activeProjects.length,
        inactive: 0,
      });
    } else if (activeProjects && inactiveProjects) {
      let allProjects = activeProjects.concat(inactiveProjects);
      setProjects(allProjects);
      setProjectsCount({
        all: activeProjects.length + inactiveProjects.length,
        active: activeProjects.length,
        inactive: inactiveProjects.length,
      });
    }
    setAddNewProjectDisabled(loggedUser?.projectTypes?.length < 1);
  }, [activeProjects, inactiveProjects]);

  useEffect(() => {
    const doFilter = () => {
      let tmpProjects = [];
      if (!projects) {
        setFilteredProjects(tmpProjects);
        return;
      }
      if (
        !selectedProjectType ||
        (selectedProjectType + "").toUpperCase() === "ALL"
      ) {
        tmpProjects = projects ? projects : [];
        setProjectsCount(() => {
          return {
            all: projects.length,
            active: projects.filter((project) => {
              return project.isActive;
            }).length,
            inactive: projects.filter((project) => {
              return !project.isActive;
            }).length,
          };
        });
      } else {
        tmpProjects = projects.filter((project) => {
          return project.projectTypeId + "" == selectedProjectType;
        });
        let projectsByType = projects.filter((project) => {
          return project.projectTypeId + "" == selectedProjectType;
        });
        setProjectsCount(() => {
          return {
            all: projectsByType.length,
            active: projectsByType.filter((project) => {
              return project.isActive;
            }).length,
            inactive: projectsByType.filter((project) => {
              return !project.isActive;
            }).length,
          };
        });
      }

      tmpProjects = tmpProjects.sort((a, b) => {
        return b.isFavourite - a.isFavourite;
      });
      switch (activeFilterValue) {
        case "ALL":
          break;
        case "ACTIVE":
          tmpProjects = tmpProjects.filter((project) => {
            return project.isActive;
          });
          break;
        case "INACTIVE":
          tmpProjects = tmpProjects.filter((project) => {
            return !project.isActive;
          });
          break;
        default:
          break;
      }

      if (searchValue) {
        tmpProjects = tmpProjects.filter((project) => {
          return (
            project.projectName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            (project.projectId + "").includes(searchValue.toLowerCase())
          );
        });
      }
      //check if Favorites is checked
      if (favoritesFilterValueFromStorage) {
        tmpProjects = tmpProjects.filter((project) => {
          return (
            props.loggedUser?.favoriteProjects?.indexOf(
              project.projectId + ""
            ) >= 0
          );
        });

        setProjectsCount(() => {
          return {
            all: tmpProjects.length,
            active: tmpProjects.filter((project) => {
              return project.isActive;
            }).length,
            inactive: tmpProjects.filter((project) => {
              return !project.isActive;
            }).length,
          };
        });
      }
      setFilteredProjects(tmpProjects);
    };

    doFilter();
    setToFilter(false);
  }, [
    activeFilterValue,
    searchValue,
    selectedProjectType,
    projects,
    toFilter,
    selectedProjectTypeId,
    favoritesFilterValue,
    props.loggedUser?.favoriteProjects,
  ]);

  const handleActiveChange = (event) => {
    setActiveFilterValue(event.target.id);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleProjectTypeChange = (event) => {
    setSelectedProjectType(event.target.value);
  };

  const handleFavoritesChange = (event) => {
    localStorage.setItem("favoritesFilterValue", event.target.checked);
    setFavoritesFilterValue(event.target.checked);
  };

  const handleAddProjectClick = () => {
    setProjectDetails({});
    props.setCurrentProjectId("");
    setAddNewProjectFlag(true);
    history("/project/new/settings");
  };
  const handleDeleteCloseClick = () => {
    setShowModal(false);
  };

  const setLoadingState = (value) => {
    switch (activeFilterValue) {
      case "ALL":
        setLoading(value);
        break;
      case "ACTIVE":
        setLoadingActive(value);
        break;
      case "INACTIVE":
        setLoadingInactive(value);
        break;
      default:
        break;
    }
  };
  const handleOpenModal = (project, date) => {
    setShowModal(true);
    set_date_modal(date);
    set_project_modal(project);
  };

  var resultProjects = filteredProjects.map((project, index) => {
    project.index = index;
    project.isFavourite = favouriteProjectIdFromUserLoggedInMock.includes(
      project.projectId
    )
      ? true
      : false;

    return (
      <ProjectCard
        project={project}
        key={project.index}
        navTabChangeStyle={props.navTabChangeStyle}
        setCurrentProjectId={props.setCurrentProjectId}
        loggedUser={props.loggedUser}
        setLoggedUser={props.setLoggedUser}
        token={props.token}
        handleOpenModal={handleOpenModal}
      />
    );
  }, this);

  return (
    <div
      id="main_container"
      style={
        (loading && activeFilterValue === "ALL") ||
        (loadingActive && activeFilterValue === "ACTIVE") ||
        (loadingInactive && activeFilterValue === "INACTIVE")
          ? {
              pointerEvents: "none",
              opacity: "0.4",
            }
          : {}
      }
    >
      {((loading && activeFilterValue === "ALL") ||
        (loadingActive && activeFilterValue === "ACTIVE") ||
        (loadingInactive && activeFilterValue === "INACTIVE")) && (
        <div className="d-flex justify-content-center bg-transparent align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div
        id="projects_header_container"
        className="container-fluid pt-3 pb-3 "
      >
        <h4 className="ms-4">Projects: {projects?.length}</h4>
        {/* {errorMessage && (
          <div className="form-outline mt-4">
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          </div>
        )} */}
      </div>
      <div
        id="app-project-status-select border-0"
        className="container-fluid pb-3 border"
      >
        <div className="pt-3 ms-4">
          <h6 id="02"></h6>
        </div>
        <div
          className="btn-toolbar mb-3 "
          role="toolbar"
          aria-label="Toolbar with button groups"
        >
          <ProjectTypes
            props={props}
            handleChange={handleProjectTypeChange}
            setErrorMessage={setErrorMessage}
            selectedProgram={true}
          />

          <div id="03" role="group" className="btn-group ms-4">
            <input
              type="radio"
              id="ALL"
              name="options"
              className="btn-check "
              value={projectsCount.all}
              autoComplete="off"
              onChange={handleActiveChange}
            />
            <label htmlFor="ALL" className="btn btn-outline-secondary">
              All: {projectsCount.all}
            </label>
            <input
              type="radio"
              id="ACTIVE"
              defaultChecked
              value={projectsCount.active}
              name="options"
              autoComplete="off"
              className="btn-check pt-3"
              onChange={handleActiveChange}
            />
            <label htmlFor="ACTIVE" className="btn btn-outline-secondary ">
              Active: {projectsCount.active}
            </label>
            <input
              type="radio"
              id="INACTIVE"
              value={projectsCount.inactive}
              name="options"
              autoComplete="off"
              className="btn-check pt-3"
              onChange={handleActiveChange}
              disabled={toDisableInactiveButton}
            />
            <label htmlFor="INACTIVE" className="btn btn-outline-secondary ">
              Inactive: {projectsCount.inactive}
            </label>
          </div>
          <div className="ms-4">
            <input
              type="checkbox"
              id="FAVORITES"
              checked={favoritesFilterValueFromStorage ? "checked" : ""}
              className="btn-check "
              onChange={handleFavoritesChange}
            />
            <label htmlFor="FAVORITES" className="btn btn-outline-secondary ">
              <img
                src={EmptyStar}
                alt="Favourites"
                style={{ width: "18px" }}
                className="mb-1 me-1"
              />
              Favorites
            </label>
          </div>
          <div className="input-group ms-4 border">
            <input
              className="form-control border-0"
              type="search"
              placeholder="Lookup project"
              id="projects-search-input"
              onChange={handleSearchChange}
            />
            <span className="input-group-append">
              <button
                className="btn btn-outline-secondary bg-white border-0 ms-n5"
                type="button"
              >
                <i className="fa fa-search"></i>
              </button>
            </span>
          </div>
          <div>
            <button
              className="btn text-light bg-primary border-1 ms-4"
              type="button"
              onClick={handleAddProjectClick}
              disabled={addNewProjectDisabled}
            >
              Add Project
            </button>
          </div>
        </div>
      </div>
      <div
        id="projects_main_container"
        className="bg-light container-fluid pt-3 pb-3"
      >
        <div className="row ms-3 row-cols-auto">{resultProjects}</div>
      </div>

      <ReactModal
        isOpen={showModal}
        shouldFocusAfterRender={true}
        dialogClassName="modal-dialog"
        shouldCloseOnOverlayClick={true}
        style={styleDeleteModal}
        scrollable={false}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        onRequestClose={() => handleDeleteCloseClick()}
      >
        <div
          id="modal-header"
          className="modal-header"
          style={{ padding: "0", paddingBottom: "6px" }}
        >
          <div className="container d-flex align-items-center justify-content-center">
            <h4>{project_modal}</h4>
          </div>
          <button
            type="button"
            className="btn btn-close btn-sm"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleDeleteCloseClick()}
          />
        </div>
        <div
          id="modal-body"
          className="modal-body"
          style={{
            minHeight: "10rem",
            maxHeight: "600px",
            overflowY: "auto",
            bottom: "0",
            top: "0",
            padding: "10px",
            fontSize: "20px",
            padding: "30px",
          }}
        >
          {" "}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "100px",
            }}
          >
            <p>
              {`The project end date has expired (${date_modal}). Please either update the end date or mark the project as inactive.`}{" "}
            </p>
            <button
              onClick={() => handleDeleteCloseClick()}
              style={{
                padding: "10px 30px",
                background: "#0e6efd",
                color: "white",
                borderRadius: "10px",
              }}
            >
              Close
            </button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
