import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const DepartmentsTab = ({ departments, addEditDept, setAddEditDept, dept, setDept, createUpdateDept, userRole }) => {
  return (
    <div style={{ width: '1158px' }}>
      <div style={{ padding: '1rem 2rem' }}>
        <Button label="Add Department or Division" icon="pi pi-plus" className="p-button-raised p-button-rounded p-button-success" onClick={() => setAddEditDept(true)} />
      </div>
      <div className="table-card">
        <DataTable value={departments} style={{ minWidth: '40rem' }} sortField="id" sortOrder={1}>
          <Column field="id" header="#ID" style={{ minWidth: '70px', textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} />
          <Column field="name" header="Department / Division" style={{ minWidth: '270px', textAlign: 'center' }} body={(rowData) => rowData.name} headerStyle={{ textAlign: 'center' }} />
          <Column body={(rowData) => (
            <>
              <Button icon="pi pi-pencil" disabled={userRole !== 'admin'} />
              &nbsp;
              <Button icon="pi pi-trash" disabled={userRole !== 'admin'} />
            </>
          )} header="Action" headerStyle={{ textAlign: 'center' }} />
        </DataTable>
      </div>
      <Dialog header="Add or Edit Department/Division" visible={addEditDept} modal style={{ width: '40vw' }} onHide={() => setAddEditDept(false)}>
        <div className="flex flex-column gap-4">
          <label htmlFor="dept">Department/Division Name : </label>
          <InputText style={{ width: '75%' }} id="dept" value={dept.name} onChange={(e) => setDept({ ...dept, name: e.target.value })} />
        </div>
        <div className="p-dialog-footer">
          <Button label="Cancel" className="p-button-secondary" onClick={() => setAddEditDept(false)} />
          <Button label="Save" className="p-button-secondary" onClick={createUpdateDept} />
        </div>
      </Dialog>
    </div>
  );
};

export default DepartmentsTab;
