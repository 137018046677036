import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ProjectsTab = ({ projects, programs, addEditProduct, setAddEditProduct, project, setProject, createUpdateProduct, userRole }) => {
  return (
    <div style={{ width: '1158px' }}>
      <div style={{ padding: '1rem 2rem' }}>
        <Button label="Add Product" icon="pi pi-plus" className="p-button-raised p-button-rounded p-button-success" onClick={() => setAddEditProduct(true)} />
      </div>
      <div className="table-card">
        <DataTable value={projects} style={{ minWidth: '40rem' }}>
          <Column field="name" header="Product" style={{ minWidth: '200px' }} />
          <Column field="programName" header="Program" style={{ minWidth: '200px' }} />
          <Column body={(rowData) => (
            <>
              <Button icon="pi pi-pencil" disabled={userRole !== 'admin'} />
              &nbsp;
              <Button icon="pi pi-trash" disabled={userRole !== 'admin'} />
            </>
          )} header="Action" />
        </DataTable>
      </div>
      <Dialog header="Add or Edit Product" visible={addEditProduct} modal style={{ width: '40vw' }} onHide={() => setAddEditProduct(false)}>
        <div className="flex flex-column gap-4">
          <label htmlFor="productName">Product / Project Name : </label>
          <InputText style={{ width: '80%' }} id="productName" value={project.name} onChange={(e) => setProject({ ...project, name: e.target.value })} />
        </div>
        <div className="flex flex-column gap-4" style={{ marginTop: '15px', marginBottom: '50px' }}>
          <label htmlFor="program">Program : </label>
          <Dropdown style={{ width: '80%' }} options={programs} value={project.programName} onChange={(e) => setProject({ ...project, programName: e.value })} optionLabel="name" optionValue="name" placeholder="Select a Program" />
        </div>
        <div className="p-dialog-footer">
          <Button label="Cancel" className="p-button-secondary" onClick={() => setAddEditProduct(false)} />
          <Button label="Save" className="p-button-secondary" onClick={createUpdateProduct} />
        </div>
      </Dialog>
    </div>
  );
};

export default ProjectsTab;
