import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const UsersTab = ({ users, roles, programs, addEditUser, setAddEditUser, user, setUser, isSmeUser, setIsSmeUser, createUpdateUser }) => {
  return (
    <div style={{ width: '1158px' }}>
      <div style={{ padding: '1rem 2rem' }}>
        <Button label="Add User" icon="pi pi-plus" className="p-button-raised p-button-rounded p-button-success" onClick={() => setAddEditUser(true)} />
      </div>
      <div className="table-card">
        <DataTable value={users} style={{ minWidth: '50rem' }}>
          <Column field="email" header="Email" />
          <Column field="full_name" header="Name" />
          <Column field="role" header="Type" />
          <Column field="team" header="Team" />
          <Column body={(rowData) => (
            <>
              <Button icon="pi pi-pencil" disabled={user.role !== 'admin'} />
              &nbsp;
              <Button icon="pi pi-trash" disabled={user.role !== 'admin'} />
            </>
          )} header="Action" />
        </DataTable>
      </div>
      <Dialog header="Add or Edit User" visible={addEditUser} modal style={{ width: '40vw' }} onHide={() => setAddEditUser(false)}>
        <div style={{ minHeight: '370px' }}>
          <div className="flex flex-column gap-4">
            <label htmlFor="username">User Email : </label>
            <InputText style={{ width: '75%' }} id="username" value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} />
          </div>
          <div className="flex flex-column gap-4" style={{ marginTop: '20px' }}>
            <label htmlFor="fullName">Full Name : </label>
            <InputText style={{ width: '75%' }} id="fullName" value={user.full_name} onChange={(e) => setUser({ ...user, full_name: e.target.value })} />
          </div>
          <div className="flex flex-column gap-4" style={{ marginTop: '15px' }}>
            <label htmlFor="program">Program : </label>
            <Dropdown style={{ width: '75%' }} options={programs} value={user.program_name} onChange={(e) => setUser({ ...user, program_name: e.value })} optionLabel="name" optionValue="name" />
          </div>
          <div className="flex flex-column gap-4" style={{ marginTop: '15px' }}>
            <label htmlFor="role">User Role : </label>
            <Dropdown style={{ width: '75%' }} options={roles} value={user.role} onChange={(e) => { setUser({ ...user, role: e.value }); setIsSmeUser(e.value === 'SME'); }} optionLabel="value" optionValue="value" placeholder="Select a User Role" />
          </div>
          {isSmeUser && (
            <div className="flex flex-column gap-4" style={{ marginTop: '15px' }}>
              <label htmlFor="team">SME Team : </label>
              <Dropdown style={{ width: '75%' }} options={programs} value={user.team} onChange={(e) => setUser({ ...user, team: e.value })} optionLabel="name" optionValue="name" placeholder="Select a User Team" />
            </div>
          )}
        </div>
        <div className="p-dialog-footer">
          <Button label="Cancel" className="p-button-secondary" onClick={() => setAddEditUser(false)} />
          <Button label="Save" className="p-button-secondary" onClick={createUpdateUser} />
        </div>
      </Dialog>
    </div>
  );
};

export default UsersTab;
