import { React, useState, useEffect } from "react";
import CommonService from "../../services/ScoreCardService";
import ActiveCriteriaDialog from "./ActiveCriteriaDialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataTable as Table } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faEdit } from "@fortawesome/free-solid-svg-icons";

const ActiveCriteria = () => {
  const [token, setToken] = useState(""); // Ensure token is set correctly
  const [showModule, setShowModule] = useState(true);
  const [showSubModule, setShowSubModule] = useState(false);
  const [showCriteria, setShowCriteria] = useState(false);
  const [editModeModule, setEditModeModule] = useState(false);
  const [editSubModeModule, setEditSubModeModule] = useState(false);
  const [templateDialog, setTemplateDialog] = useState(false);
  const [criteriaDialog, setCriteriaDialog] = useState(false);
  const [basicShow, setBasicShow] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [modules, setModules] = useState([]);
  const [subModules, setSubModules] = useState([]);
  const [criteriaList, setCriteriaList] = useState([]);
  const [currentModule, setCurrentModule] = useState("");
  const [currentModuleId, setCurrentModuleId] = useState(1);
  const [currentSubModule, setCurrentSubModule] = useState("");
  const [currentSubModuleId, setCurrentSubModuleId] = useState(0);
  const [criteriaEditable, setCriteriaEditable] = useState(false);
  const [displayModalItemName, setDisplayModalItemName] = useState("");
  const [operationType, setOperationType] = useState("add");
  const [displayHeaderName, setDisplayHeaderName] =
    useState("Update Module Name");
  const [operationLevel, setOperationLevel] = useState("module");
  const [index, setIndex] = useState(0);
  const [template, setTemplate] = useState("");
  const [criteria, setCriteria] = useState({});
  const [targetLevels, setTargetLevels] = useState([]);
  const [clonedProducts, setClonedProducts] = useState({});
  const [clonedTemplate, setClonedTemplate] = useState({});
  const [editingRows, setEditingRows] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(1); // Initialize with default template ID
  const [hoveredButton, setHoveredButton] = useState(null);

  const buttonStyle = {
    fontSize: "16px",
    padding: "10px 20px",
    border: "1px solid transparent",
    transition: "border-color 0.3s ease",
  };

  const buttonHoverStyle = {
    borderColor: "#007bff",
  };

  useEffect(() => {
    // getAllTemplates();
    // getModuleList(token, 1);
    // getCriteriaList(token, 1);
  }, []);

  const getAllTemplates = () => {
    CommonService.getAllTemplates().then((data) => setTemplateList(data));
  };

  const getModuleList = (token, templateId) => {
    setSelectedTemplate(templateId); // Set selected template
    CommonService.getAllModulesByTemplateId(token, templateId).then((data) => {
      setModules(data);
      setShowModule(true);
      setShowSubModule(false);
      setShowCriteria(false);
      CommonService.checkCriteriaEditable(token, templateId).then((data) =>
        setCriteriaEditable(data)
      );
    });
  };

  const getCriteriaList = (token, templateId) => {
    CommonService.getAllCriteriaByTemplateId(token, templateId).then((data) => {
      setCriteriaList(data);
    });
  };

  const getSubModules = (token, moduleId, moduleName) => {
    CommonService.getSubModules(token, moduleId).then((data) => {
      setSubModules(data);
      setShowModule(false);
      setShowSubModule(true);
      setShowCriteria(false);
      setCurrentModule(moduleName);
      setCurrentModuleId(moduleId); // Ensure currentModuleId is not reset
      console.log(data);
    });
  };

  const showDialog = (name, id, idx, type) => {
    setBasicShow(true);
    setDisplayModalItemName(name);
    setIndex(idx);
    switch (type) {
      case "moduleUpdate":
        setDisplayHeaderName("Update Module");
        setOperationType("update");
        setOperationLevel("module");
        break;
      case "moduleDelete":
        setDisplayHeaderName("Delete Module");
        setOperationType("delete");
        setOperationLevel("module");
        break;
      case "subModuleUpdate":
        setDisplayHeaderName("Update Sub Module");
        setOperationType("update");
        setOperationLevel("sub-module");
        break;
      case "subModuleDelete":
        setDisplayHeaderName("Delete Sub Module");
        setOperationType("delete");
        setOperationLevel("sub-module");
        break;
      default:
        break;
    }
  };

  const addNewModules = () => {
    setOperationType("add");
    setOperationLevel("module");
    setDisplayHeaderName("Add Module Name"); // Set correct dialog title
    setDisplayModalItemName("");
    setBasicShow(true);
  };

  const addNewSubModules = () => {
    setOperationType("add");
    setOperationLevel("sub-module");
    setDisplayHeaderName("Add Sub-Module Name"); // Set correct dialog title
    setDisplayModalItemName("");
    setBasicShow(true);
  };

  const saveNewTemplate = () => {
    CommonService.addNewTemplate(token, template).then(() => {
      getAllTemplates();
      setTemplateDialog(false);
      setTemplate("");
    });
  };

  const saveNewModule = () => {
    setBasicShow(false);
    if (operationLevel === "module" && operationType === "add") {
      CommonService.addNewModule(
        token,
        displayModalItemName,
        currentModuleId
      ).then(() => {
        getModuleList(token, currentModuleId);
      });
    }
    if (operationLevel === "sub-module" && operationType === "add") {
      CommonService.addNewSubModule(
        token,
        displayModalItemName,
        currentModuleId
      ).then(() => {
        getSubModules(token, currentModuleId, currentModule);
      });
    }
  };

  const updateModuleName = () => {
    if (operationLevel === "module") {
      subModules[index].module_name = displayModalItemName;
    } else {
      subModules[index].sub_module_name = displayModalItemName;
    }
    setBasicShow(false);
  };

  const deleteModuleName = () => {
    if (operationLevel === "module") {
      modules.splice(index, 1);
    } else {
      subModules.splice(index, 1);
    }
    setBasicShow(false);
  };

  const getCriteria = (token, subModuleId, subModuleName) => {
    CommonService.getCriteriaListBySubModuleId(token, subModuleId).then(
      (data) => {
        setCriteriaList(data);
        setShowModule(false);
        setShowSubModule(false);
        setShowCriteria(true);
        setCurrentSubModule(subModuleName);
        setCurrentSubModuleId(subModuleId);
        console.log(data);
      }
    );
  };

  const openNew = () => {
    setCriteria({});
    setCriteriaDialog(true);
    setTargetLevels([
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
    ]);
  };

  const saveNewCriteria = () => {
    const newCriteria = {
      ...criteria,
      subModuleId: currentSubModuleId,
      target_level: criteria.targetLevel,
      templateId: selectedTemplate,
    };
    CommonService.addNewCriteria(token, newCriteria).then(() => {
      CommonService.getCriteriaListBySubModuleId(
        token,
        currentSubModuleId
      ).then((data) => {
        setCriteriaList(data);
        setCriteriaDialog(false);
      });
    });
  };

  const onRowEditTemplateInit = (templateData) => {
    setClonedTemplate({
      ...clonedTemplate,
      [templateData.id]: { ...templateData },
    });
    setEditingRows((prev) => ({ ...prev, [templateData.id]: true }));
  };

  const onRowEditTemplateSave = (templateData, index) => {
    delete clonedTemplate[templateData.id];
    setEditingRows((prev) => ({ ...prev, [templateData.id]: false }));
  };

  const onRowEditTemplateCancel = (templateData, index) => {
    setTemplateList(
      templateList.map((item, i) =>
        i === index ? clonedTemplate[templateData.id] : item
      )
    );
    delete clonedTemplate[templateData.id];
    setEditingRows((prev) => ({ ...prev, [templateData.id]: false }));
  };

  const deleteTemplate = (templateData) => {
    confirmDialog({
      message: `Are you sure that you want to perform this delete template: <b>${templateData.name}</b> action? Remember this action will delete all the associated modules, submodules, and criteria!`,
      accept: () => {
        CommonService.deleteTemplate(token, templateData.id).then(() => {
          getAllTemplates();
        });
      },
    });
  };
  const cancelTemplate = () => {
    setTemplateDialog(false);
    setTemplate("");
  };

  const backToModule = () => {
    setShowModule(true);
    setShowSubModule(false);
    setShowCriteria(false);
  };

  const backToSubModule = () => {
    setShowModule(false);
    setShowSubModule(true);
    setShowCriteria(false);
  };

  const copyTemplate = (templateData) => {
    CommonService.copyTemplate(token, templateData.id, template).then(() => {
      getAllTemplates();
    });
  };

  const backToViewModule = () => {
    setEditModeModule(false);
  };

  const showEditModule = () => {
    setEditModeModule(true);
  };

  const backToViewSubModule = () => {
    setEditSubModeModule(false);
  };

  const showEditSubModule = () => {
    setEditSubModeModule(true);
  };

  return (
    <div style={{ minWidth: "1358px" }}>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div
        className="card-container"
        style={{
          margin: "0 0 35px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ marginLeft: "40px", float: "left" }}>
          <label style={{ marginRight: "10px" }} htmlFor="templateDropdown">
            Select a Template Type:{" "}
          </label>
          <Dropdown
            id="templateDropdown"
            options={templateList}
            value={selectedTemplate}
            onChange={(e) => {
              setSelectedTemplate(e.value);
              getModuleList(token, e.value);
            }}
            optionLabel="name"
            optionValue="id"
            appendTo={document.body}
          />
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Button
            type="button"
            style={{
              minWidth: "15%",
              margin: "0px 0px 30px",
              float: "right",
              fontSize: "16px",
              padding: "10px 20px",
            }}
            className="btn btn-primary"
            onClick={() => setTemplateDialog(true)}
          >
            Create or Edit Template
          </Button>
        </div>
      </div>
      {showModule && (
        <div className="card-container" style={{ textAlign: "center" }}>
          {editModeModule ? (
            <ul
              style={{
                listStyleType: "none",
                padding: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {modules.map((item, idx) => (
                <li
                  key={idx}
                  className="card-question"
                  tabIndex="0"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    margin: "10px 0",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "50%",
                  }}
                >
                  <span
                    style={{ flex: 1, textAlign: "left", fontSize: "14px" }}
                  >
                    {item.module_name}
                  </span>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{ cursor: "pointer", fontSize: "14px" }}
                      onClick={() =>
                        showDialog(
                          item.module_name,
                          item.idScoring_Module,
                          idx,
                          "moduleUpdate"
                        )
                      }
                    />
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      style={{ cursor: "pointer", fontSize: "14px" }}
                      onClick={() =>
                        showDialog(
                          item.module_name,
                          item.idScoring_Module,
                          idx,
                          "moduleDelete"
                        )
                      }
                    />
                  </div>
                </li>
              ))}
              <li
                className="card-question-progress"
                style={{ textAlign: "center", marginTop: "50px" }}
              >
                <Button
                  type="button"
                  style={{
                    minWidth: "300px",
                    fontSize: "16px",
                    padding: "10px 20px",
                  }}
                  className="btn btn-primary"
                  onClick={backToViewModule}
                >
                  View Mode
                </Button>
                <Button
                  label="Add New Module"
                  icon="pi pi-plus"
                  className="btn btn-primary"
                  style={{
                    minWidth: "300px",
                    fontSize: "16px",
                    padding: "10px 20px",
                  }}
                  onClick={addNewModules}
                />
              </li>
            </ul>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                {Array.isArray(modules) &&
                  modules.map((item, idx) => (
                    <Button
                      key={idx}
                      className="card card-small"
                      onClick={() =>
                        getSubModules(
                          token,
                          item.idScoring_Module,
                          item.module_name
                        )
                      }
                      tabIndex="0"
                      style={
                        hoveredButton === idx
                          ? { ...buttonStyle, ...buttonHoverStyle }
                          : buttonStyle
                      }
                      onMouseEnter={() => setHoveredButton(idx)}
                      onMouseLeave={() => setHoveredButton(null)}
                    >
                      <svg
                        className="material-icons"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                      </svg>
                      <b>{item.module_name}</b>
                    </Button>
                  ))}
              </div>
              <Button
                disabled={!criteriaEditable}
                type="button"
                style={{
                  minWidth: "75%",
                  marginTop: "120px",
                  fontSize: "16px",
                  padding: "10px 20px",
                }}
                className="btn btn-primary"
                onClick={showEditModule}
              >
                Edit Mode
              </Button>
            </>
          )}
        </div>
      )}
      {showSubModule && (
        <div className="card-container" style={{ textAlign: "center" }}>
          {editSubModeModule ? (
            <ul
              style={{
                listStyleType: "none",
                padding: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <li
                className="card-question-progress"
                style={{ marginBottom: "70px", width: "100%" }}
              >
                <h4 className="module-title">{currentModule}</h4>
              </li>
              {subModules.map((item, idx) => (
                <li
                  key={idx}
                  className="card-question"
                  tabIndex="0"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    margin: "10px 0",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "50%",
                  }}
                >
                  <span
                    style={{ flex: 1, textAlign: "left", fontSize: "14px" }}
                  >
                    {item.sub_module_name}
                  </span>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{ cursor: "pointer", fontSize: "14px" }}
                      onClick={() =>
                        showDialog(
                          item.sub_module_name,
                          item.id_sub_modules,
                          idx,
                          "subModuleUpdate"
                        )
                      }
                    />
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      style={{ cursor: "pointer", fontSize: "14px" }}
                      onClick={() =>
                        showDialog(
                          item.sub_module_name,
                          item.id_sub_modules,
                          idx,
                          "subModuleDelete"
                        )
                      }
                    />
                  </div>
                </li>
              ))}
              <li
                className="card-question-progress"
                style={{ textAlign: "center", marginTop: "50px" }}
              >
                <Button
                  type="button"
                  style={{
                    minWidth: "300px",
                    fontSize: "16px",
                    padding: "10px 20px",
                  }}
                  className="btn btn-primary"
                  onClick={backToViewSubModule}
                >
                  View Mode
                </Button>
                <Button
                  label="Add New Sub-Module"
                  icon="pi pi-plus"
                  className="btn btn-primary"
                  style={{
                    minWidth: "300px",
                    fontSize: "16px",
                    padding: "10px 20px",
                  }}
                  onClick={addNewSubModules}
                />
              </li>
            </ul>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                {subModules.map((item, idx) => (
                  <Button
                    key={idx}
                    className="card card-small"
                    onClick={() =>
                      getCriteria(
                        token,
                        item.id_sub_modules,
                        item.sub_module_name
                      )
                    }
                    tabIndex="0"
                    style={
                      hoveredButton === idx
                        ? { ...buttonStyle, ...buttonHoverStyle }
                        : buttonStyle
                    }
                    onMouseEnter={() => setHoveredButton(idx)}
                    onMouseLeave={() => setHoveredButton(null)}
                  >
                    <svg
                      className="material-icons"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                    </svg>
                    <b>{item.sub_module_name}</b>
                  </Button>
                ))}
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  type="button"
                  style={{
                    minWidth: "300px",
                    fontSize: "16px",
                    padding: "10px 20px",
                  }}
                  className="btn btn-secondary"
                  onClick={backToModule}
                >
                  Back
                </Button>
                <Button
                  disabled={!criteriaEditable}
                  type="button"
                  style={{
                    minWidth: "300px",
                    fontSize: "16px",
                    padding: "10px 20px",
                  }}
                  className="btn btn-primary"
                  onClick={showEditSubModule}
                >
                  Edit Mode
                </Button>
              </div>
            </>
          )}
        </div>
      )}
      {showCriteria && (
        <div className="card-container">
          <h4
            className="module-title"
            style={{ marginTop: "0px", marginBottom: "50px" }}
          >
            {currentSubModule}
          </h4>
          <div className="p-toolbar-group-left">
            <Button
              type="button"
              style={{
                minWidth: "300px",
                fontSize: "16px",
                padding: "10px 20px",
              }}
              className="btn btn-secondary"
              onClick={backToSubModule}
            >
              Back
            </Button>
          </div>
          <div className="p-toolbar-group-right">
            <Button
              disabled={!criteriaEditable}
              type="button"
              style={{
                minWidth: "300px",
                fontSize: "16px",
                padding: "10px 20px",
              }}
              className="btn btn-primary"
              onClick={openNew}
            >
              New
            </Button>
          </div>
          <Table
            value={Array.isArray(criteriaList) ? criteriaList : []}
            dataKey="id_criteria_info"
            editMode="row"
            tableStyle={{ minWidth: "50rem", marginBottom: "100px" }}
          >
            <Column
              field="criteria_description"
              header="Description"
              sortable
              editor={(props) => (
                <InputTextarea
                  rows={4}
                  cols={50}
                  value={props.rowData.criteria_description}
                  onChange={(e) => props.onEditorValueChange(e.target.value)}
                />
              )}
            />
            <Column
              field="target_level"
              header="Target Level"
              style={{ textAlign: "center" }}
              sortable
              editor={(props) => (
                <InputText
                  type="text"
                  value={props.rowData.target_level}
                  onChange={(e) => props.onEditorValueChange(e.target.value)}
                />
              )}
            />
            <Column
              field="weight"
              header="Weight"
              style={{ textAlign: "center" }}
              sortable
              editor={(props) => (
                <InputText
                  type="text"
                  value={props.rowData.weight}
                  onChange={(e) => props.onEditorValueChange(e.target.value)}
                />
              )}
            />
            <Column
              field="scoringSubModule.scoringModule.module_name"
              header="Module"
            />
            <Column
              field="scoringSubModule.sub_module_name"
              header="Sub Module"
            />
            <Column
              header="Action Item"
              body={(rowData, { rowIndex }) => (
                <div className="flex align-items-center justify-content-center gap-2">
                  <Button
                    disabled={!criteriaEditable || editingRows[rowData.id]}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-text"
                    onClick={() => onRowEditTemplateInit(rowData)}
                  />
                  <Button
                    disabled={!criteriaEditable}
                    icon="pi pi-trash"
                    className="p-button-rounded"
                    onClick={() => deleteTemplate(rowData)}
                  />
                  <Button
                    disabled={template === ""}
                    icon="pi pi-copy"
                    className="p-button-rounded"
                    onClick={() => copyTemplate(rowData)}
                  />
                  {editingRows[rowData.id] && (
                    <>
                      <Button
                        icon="pi pi-check"
                        className="p-button-rounded p-button-text p-button-success mr-2"
                        onClick={() => onRowEditTemplateSave(rowData, rowIndex)}
                      />
                      <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-text p-button-danger"
                        onClick={() =>
                          onRowEditTemplateCancel(rowData, rowIndex)
                        }
                      />
                    </>
                  )}
                </div>
              )}
            />
          </Table>
        </div>
      )}
      <ActiveCriteriaDialog
        basicShow={basicShow}
        setBasicShow={setBasicShow}
        displayModalItemName={displayModalItemName}
        setDisplayModalItemName={setDisplayModalItemName}
        operationType={operationType}
        updateModuleName={updateModuleName}
        deleteModuleName={deleteModuleName}
        saveNewModule={saveNewModule}
        criteriaDialog={criteriaDialog}
        setCriteriaDialog={setCriteriaDialog}
        criteria={criteria}
        setCriteria={setCriteria}
        targetLevels={targetLevels}
        saveNewCriteria={saveNewCriteria}
        displayHeaderName={displayHeaderName}
        templateDialog={templateDialog}
        setTemplateDialog={cancelTemplate}
        template={template}
        setTemplate={setTemplate}
        saveNewTemplate={saveNewTemplate}
        templateList={templateList}
        criteriaEditable={criteriaEditable}
        onRowEditTemplateInit={onRowEditTemplateInit}
        onRowEditTemplateSave={onRowEditTemplateSave}
        onRowEditTemplateCancel={onRowEditTemplateCancel}
        deleteTemplate={deleteTemplate}
        copyTemplate={copyTemplate}
        addNewModules={addNewModules}
        addNewSubModules={addNewSubModules}
        showDialog={showDialog}
        openNew={openNew}
        editingRows={editingRows} // Pass editingRows as prop
      />
    </div>
  );
};

export default ActiveCriteria;
