import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const TeamsTab = ({ bussinessUnits, addEditBussinessUnit, setAddEditBussinessUnit, bussinessUnit, setBussinessUnit, createUpdateBussinessUnit, editBussinessUnit, userRole }) => {
  return (
    <div style={{ width: '1158px' }}>
      <div style={{ padding: '1rem 2rem' }}>
        <Button label="Add New Team" icon="pi pi-plus" className="p-button-raised p-button-rounded p-button-success" onClick={() => setAddEditBussinessUnit(true)} />
      </div>
      <div className="table-card">
        <DataTable value={bussinessUnits} style={{ minWidth: '40rem' }}>
          <Column field="id" header="ID#" style={{ minWidth: '70px', textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} />
          <Column field="name" header="Team" style={{ minWidth: '270px', textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} />
          <Column body={(rowData) => (
            <>
              <Button icon="pi pi-pencil" disabled={userRole !== 'admin'} onClick={() => editBussinessUnit(rowData)} />
              &nbsp;
              <Button icon="pi pi-trash" disabled={userRole !== 'admin'} />
            </>
          )} header="Action" headerStyle={{ textAlign: 'center' }} />
        </DataTable>
      </div>
      <Dialog header="Add or Edit Bussiness Team" visible={addEditBussinessUnit} modal style={{ width: '40vw' }} onHide={() => setAddEditBussinessUnit(false)}>
        <div className="flex flex-column gap-4" style={{ marginTop: '20px' }}>
          <label htmlFor="bussinessunitName">Bussiness Team Name : </label>
          <InputText style={{ width: '75%' }} id="bussinessunitName" value={bussinessUnit.name} onChange={(e) => setBussinessUnit({ ...bussinessUnit, name: e.target.value })} />
        </div>
        <div className="p-dialog-footer">
          <Button label="Cancel" className="p-button-secondary" onClick={() => setAddEditBussinessUnit(false)} />
          <Button label="Save" className="p-button-secondary" onClick={createUpdateBussinessUnit} />
        </div>
      </Dialog>
    </div>
  );
};

export default TeamsTab;