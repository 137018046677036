import { useState } from "react";
import { useAppContext } from "../../store/AppProvider";

export default function useJiraInfo() {
  const {
    currentProjectId: jiraPrjStr,
    jiraInfo: jiraInfoStore,
    dispatch,
  } = useAppContext();

  const getJiraInfo = () => {
    if (!jiraInfoStore) {
      return null;
    }
    return jiraInfoStore;
  };

  const [jiraInfo, setJiraInfo] = useState(getJiraInfo());

  const saveJiraInfo = (info) => {
    dispatch({ type: "EDIT_JIRA_INFO", value: info });
    setJiraInfo(info);
  };

  const getSelectedJiraProject = () => {
    if (!jiraPrjStr) {
      return "ALL";
    }
    return jiraPrjStr;
  };
  const [selectedJiraProject, setSelectedJiraProject] = useState(
    getSelectedJiraProject()
  );

  const saveSelectedJiraProject = (jiraPrjId) => {
    dispatch({ type: "EDIT_CURRENT_PROJECT_ID", value: jiraPrjId });
    setSelectedJiraProject(jiraPrjId);
  };

  const getJiraChartsInfo = () => {
    const infoStr = sessionStorage.getItem("jiraChartsInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [jiraChartsInfo, setJiraChartsInfo] = useState(getJiraChartsInfo());

  const saveJiraChartsInfo = (info) => {
    sessionStorage.setItem("jiraChartsInfo", JSON.stringify(info));
    setJiraChartsInfo(info);
  };

  const getCurrentStateDefectsVsRelease = () => {
    const str = sessionStorage.getItem("currentStateDefectsVsRelease");
    if (!str || str === "undefined") {
      return "All";
    }
    const info = JSON.parse(str);
    return info;
  };

  const [currentStateDefectsVsRelease, setCurrentStateDefectsVsRelease] =
    useState(getCurrentStateDefectsVsRelease());

  const saveCurrentStateDefectsVsRelease = (info) => {
    sessionStorage.setItem(
      "currentStateDefectsVsRelease",
      JSON.stringify(info)
    );
    setCurrentStateDefectsVsRelease(info);
  };

  const getCurrentStateDefectAge = () => {
    const str = sessionStorage.getItem("currentStateDefectAge");
    if (!str || str === "undefined") {
      return "All";
    }
    const info = JSON.parse(str);
    return info;
  };

  const [currentStateDefectAge, setCurrentStateDefectAge] = useState(
    getCurrentStateDefectAge()
  );

  const saveCurrentStateDefectAge = (info) => {
    sessionStorage.setItem("currentStateDefectAge", JSON.stringify(info));
    setCurrentStateDefectAge(info);
  };

  const getDefectsMap = () => {
    const str = sessionStorage.getItem("defectsMap");
    if (!str || str === "undefined") {
      return "All";
    }
    const info = JSON.parse(str);
    return info;
  };

  const [defectsMap, setDefectsMap] = useState(getDefectsMap());

  const saveDefectsMap = (info) => {
    sessionStorage.setItem("defectsMap", JSON.stringify(info));
    setDefectsMap(info);
  };

  const getRequirementChartsInfo = () => {
    const infoStr = sessionStorage.getItem("requirementChartsInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [requirementChartsInfo, setRequirementChartsInfo] = useState(
    getRequirementChartsInfo()
  );

  const saveRequirementChartsInfo = (info) => {
    sessionStorage.setItem("requirementChartsInfo", JSON.stringify(info));
    setRequirementChartsInfo(info);
  };

  const getRequirementCoverageJiraInfo = () => {
    const infoStr = sessionStorage.getItem("requirementCoverageJiraInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [requirementCoverageJiraInfo, setRequirementCoverageJiraInfo] =
    useState(getRequirementCoverageJiraInfo());

  const saveRequirementCoverageJiraInfo = (info) => {
    sessionStorage.setItem("requirementCoverageJiraInfo", JSON.stringify(info));
    setRequirementCoverageJiraInfo(info);
  };

  const getRequirementCoverageByTestCaseInfo = () => {
    const infoStr = sessionStorage.getItem("requirementCoverageByTestCaseInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [
    requirementCoverageByTestCaseInfo,
    setrequirementCoverageByTestCaseInfo,
  ] = useState(getRequirementCoverageByTestCaseInfo());

  const saveRequirementCoverageByTestCaseInfo = (info) => {
    sessionStorage.setItem(
      "requirementCoverageByTestCaseInfo",
      JSON.stringify(info)
    );
    setrequirementCoverageByTestCaseInfo(info);
  };

  const getProcessComplianceTestIssuesInfo = () => {
    const infoStr = sessionStorage.getItem("processComplianceTestIssuesInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [processComplianceTestIssuesInfo, setProcessComplianceTestIssuesInfo] =
    useState(getProcessComplianceTestIssuesInfo());

  const saveProcessComplianceTestIssuesInfo = (info) => {
    sessionStorage.setItem(
      "processComplianceTestIssuesInfo",
      JSON.stringify(info)
    );
    setProcessComplianceTestIssuesInfo(info);
  };

  const getJiraSteercoDefectsInfo = () => {
    const infoStr = sessionStorage.getItem("jiraSteercoDefectsInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [jiraSteercoDefectsInfo, setJiraSteercoDefectsInfo] = useState(
    getJiraSteercoDefectsInfo()
  );

  const saveJiraSteercoDefectsInfo = (info) => {
    sessionStorage.setItem("jiraSteercoDefectsInfo", JSON.stringify(info));
    setJiraSteercoDefectsInfo(info);
  };

  const getProjectTrackerDefectSummaryInfo = () => {
    const infoStr = sessionStorage.getItem("projectTrackerDefectSummaryInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };
  const [projectTrackerDefectSummaryInfo, setProjectTrackerDefectSummaryInfo] =
    useState(getProjectTrackerDefectSummaryInfo());

  const saveProjectTrackerDefectSummaryInfo = (info) => {
    sessionStorage.setItem(
      "projectTrackerDefectSummaryInfo",
      JSON.stringify(info)
    );
    setProjectTrackerDefectSummaryInfo(info);
  };

  return {
    setJiraInfo: saveJiraInfo,
    jiraInfo,
    selectedJiraProject,
    setSelectedJiraProject: saveSelectedJiraProject,
    jiraChartsInfo,
    setJiraChartsInfo: saveJiraChartsInfo,
    currentStateDefectsVsRelease,
    setCurrentStateDefectsVsRelease: saveCurrentStateDefectsVsRelease,
    defectsMap,
    setDefectsMap: saveDefectsMap,
    requirementChartsInfo,
    setRequirementChartsInfo: saveRequirementChartsInfo,
    requirementCoverageJiraInfo,
    setRequirementCoverageJiraInfo: saveRequirementCoverageJiraInfo,
    requirementCoverageByTestCaseInfo,
    setRequirementCoverageByTestCaseInfo: saveRequirementCoverageByTestCaseInfo,
    processComplianceTestIssuesInfo,
    setProcessComplianceTestIssuesInfo: saveProcessComplianceTestIssuesInfo,
    jiraSteercoDefectsInfo,
    setJiraSteercoDefectsInfo: saveJiraSteercoDefectsInfo,
    projectTrackerDefectSummaryInfo,
    setProjectTrackerDefectSummaryInfo: saveProjectTrackerDefectSummaryInfo,
    currentStateDefectAge,
    setCurrentStateDefectAge: saveCurrentStateDefectAge,
  };
}
