import React from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ActiveCriteriaDialog = ({
  basicShow,
  setBasicShow,
  displayModalItemName,
  setDisplayModalItemName,
  operationType,
  updateModuleName,
  deleteModuleName,
  saveNewModule,
  criteriaDialog,
  setCriteriaDialog,
  criteria,
  setCriteria,
  targetLevels,
  saveNewCriteria,
  displayHeaderName,
  templateDialog,
  setTemplateDialog,
  template,
  setTemplate,
  saveNewTemplate,
  templateList,
  criteriaEditable,
  onRowEditTemplateInit,
  onRowEditTemplateSave,
  onRowEditTemplateCancel,
  deleteTemplate,
  copyTemplate,
  addNewModules,
  addNewSubModules,
  openNew,
  editingRows // Receive editingRows as prop
}) => {
  return (
    <>
      <Dialog header={displayHeaderName} visible={basicShow} style={{ width: '30vw' }} modal draggable={false} resizable={false} onHide={() => setBasicShow(false)}>
        <p>
          <InputText style={{ width: '100%' }} value={displayModalItemName} onChange={(e) => setDisplayModalItemName(e.target.value)} />
        </p>
        <div style={{ display: operationType === 'delete' ? 'block' : 'none' }}>
          <h4>Are you sure, do you want to delete <b>{displayModalItemName}</b>?</h4>
          <span>Note: If you delete this all association will be deleted, please make sure before delete it</span>
        </div>
        <div className="p-dialog-footer">
          <Button label="Cancel" className="btn btn-secondary" onClick={() => setBasicShow(false)} />
          <Button label="Update" className="btn btn-primary" onClick={updateModuleName} style={{ display: operationType === 'update' ? 'block' : 'none' }} />
          <Button label="Delete" className="btn btn-primary" onClick={deleteModuleName} style={{ display: operationType === 'delete' ? 'block' : 'none' }} />
          <Button label="Save" className="btn btn-primary" onClick={saveNewModule} style={{ display: operationType === 'add' ? 'block' : 'none' }} />
        </div>
      </Dialog>
      <Dialog visible={criteriaDialog} style={{ width: '550px', height: '550px' }} header="Create New Criteria" modal onHide={() => setCriteriaDialog(false)}>
        <div className="field">
          <label htmlFor="description">Criteria</label>
          <InputTextarea id="description" value={criteria.criteriaName} onChange={(e) => setCriteria({ ...criteria, criteriaName: e.target.value })} required rows={3} cols={20} />
        </div>
        <div className="field">
          <label htmlFor="targetLevel">Target Level</label>
          <Dropdown
            options={targetLevels}
            placeholder="Select a Target Level"
            optionLabel="label"
            showClear
            appendTo={document.body}
            value={criteria.targetLevel}
            onChange={(e) => setCriteria({ ...criteria, targetLevel: e.value })}
          />
        </div>
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="weight">Weight</label>
            <InputNumber value={criteria.weight} id="weight" mode="decimal" locale="en-US" minFractionDigits={1} min={0} max={100} onChange={(e) => setCriteria({ ...criteria, weight: e.value })} />
          </div>
        </div>
        <div className="p-dialog-footer">
          <Button label="Cancel" className="btn btn-secondary" onClick={() => setCriteriaDialog(false)} />
          <Button label="Save" className="btn btn-primary" onClick={saveNewCriteria} />
        </div>
      </Dialog>
      <Dialog visible={templateDialog} style={{ width: '650px', height: '750px' }} header="Create or Edit Template" modal onHide={() => setTemplateDialog(false)}>
        <div className="flex flex-column gap-4 add-template">
          <label style={{ marginRight: '10px', width: '15%' }} htmlFor="templateName">Template: </label>
          <InputText style={{ width: '60%' }} id="templateName" value={template} onChange={(e) => setTemplate(e.target.value)} />
          <div className="p-dialog-footer">
            <Button label="Add" className="btn btn-primary" onClick={saveNewTemplate} />
          </div>
        </div>
        <DataTable value={templateList} dataKey="id" editMode="row" editingRows={editingRows} onRowEditInit={onRowEditTemplateInit} onRowEditSave={onRowEditTemplateSave} onRowEditCancel={onRowEditTemplateCancel} tableStyle={{ minWidth: '20rem', marginBottom: '100px' }}>
          <Column field="id" header="Id" style={{ minWidth: '100px' }} />
          <Column field="name" header="Template" sortable editor={(props) => (
            <InputText type="text" value={props.rowData.name} onChange={(e) => props.rowData.name = e.target.value} />
          )} />
          <Column header="Action Item" body={(rowData, { rowIndex }) => (
            <div className="flex align-items-center justify-content-center gap-2">
              {!editingRows[rowData.id] ? (
                <>
                  <Button disabled={!criteriaEditable} icon="pi pi-pencil" className="p-button-rounded" onClick={() => onRowEditTemplateInit(rowData)} />
                  <Button disabled={!criteriaEditable} icon="pi pi-trash" className="p-button-rounded" onClick={() => deleteTemplate(rowData)} />
                  <Button disabled={template === ''} icon="pi pi-copy" className="p-button-rounded" onClick={() => copyTemplate(rowData)} />
                </>
              ) : (
                <>
                  <Button icon="pi pi-check" className="p-button-rounded p-button-text p-button-success mr-2" onClick={() => onRowEditTemplateSave(rowData, rowIndex)} />
                  <Button icon="pi pi-times" className="p-button-rounded p-button-text p-button-danger" onClick={() => onRowEditTemplateCancel(rowData, rowIndex)} />
                </>
              )}
            </div>
          )} />
        </DataTable>
      </Dialog>
    </>
  );
};

export default ActiveCriteriaDialog;