import React, { useState, useRef } from "react";
import { Chart } from "react-google-charts";
import "../../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

export default function OpenClosedDefectsBySeverityChart(props) {
  var defectTitles = [
    "Issue ID",
    "Issue Summary",
    "Status",
    "Resolution",
    "Severity",
  ];
  var stateRadioList =
    props.isADO === "ADO"
      ? [
          "All",
          "1 - Critical",
          "2 - High",
          "3 - Medium",
          "4 - Low",
          "Not Defined",
        ]
      : ["All", "Sev 1-Severe", "Sev 2-High", "Sev 3-Medium", "Sev 4-Low"];
  let columnArray =
    props.isADO === "ADO"
      ? ["Dates", "Created", "Closed/Done", "Open"]
      : ["Dates", "Created", "Closed", "Open"];
  let valEmptyArray =
    props.isADO === "ADO" ? ["Dates", 0, 0, 0] : ["Dates", 0, 0, 0];

  const currentIterationInfoRef = useRef();
  const closureRateRef = useRef();
  const currentDataTableRef = useRef();
  const currentIssueMapRef = useRef();
  const [currentSelectedSeverity, _setCurrentSelectedSeverity] =
    useState("All");

  const getValue = (value) => {
    return value ? value.length : 0;
  };

  const currentSelectedSeverityRef = useRef(currentSelectedSeverity);
  const setCurrentSelectedSeverity = (value) => {
    currentSelectedSeverityRef.current = value;
    _setCurrentSelectedSeverity(value);
  };

  var openDefectsByServerity =
    props.jiraSteercoDefectsInfo?.steerCoOpenClosedDefectsBySev;
  currentIssueMapRef.current = props.jiraSteercoDefectsInfo?.issueMap
    ? props.jiraSteercoDefectsInfo.issueMap
    : {};
  var currentIterationDataObj = openDefectsByServerity
    ? openDefectsByServerity[props.currentIteration]
    : null;
  currentIterationInfoRef.current = currentIterationDataObj;

  var dataAll = {};
  var columnMaxValues = {};

  //prepare headers for the chart
  var header = ["Dates"];
  for (var i = 1; i < columnArray.length; i++) {
    header.push(columnArray[i]);
  }
  for (let severity of stateRadioList) {
    dataAll[severity] = [];
    dataAll[severity].push(header);
    columnMaxValues[severity] = 0;
  }

  //sort by dates
  let currentIterationForOpendClosedDefects = {};
  if (currentIterationDataObj) {
    let sorted = Object.keys(currentIterationDataObj).sort(function (a, b) {
      return new Date(a).getTime() - new Date(b).getTime();
    });
    sorted.forEach(function (key) {
      currentIterationForOpendClosedDefects[key] = currentIterationDataObj[key];
    });
  }

  //iterate through the dates to prepare data object for the chart
  const datesArr = Object.keys(currentIterationForOpendClosedDefects);

  for (let date of datesArr) {
    var currentDateStatObj = currentIterationForOpendClosedDefects[date];

    for (let severity of stateRadioList) {
      let created = currentDateStatObj["Created"][severity]
        ? currentDateStatObj["Created"][severity].length
        : 0;
      let closed = 0;
      if (props.isADO !== "ADO") {
        closed = currentDateStatObj["Closed"][severity]
          ? currentDateStatObj["Closed"][severity].length
          : 0;
      }

      let open = currentDateStatObj["Open"][severity]
        ? currentDateStatObj["Open"][severity].length
        : 0;
      if (props.isADO === "ADO") {
        let done = 0;
        if (currentDateStatObj["Done"]) {
          done = currentDateStatObj["Done"][severity]
            ? currentDateStatObj["Done"][severity].length
            : 0;
        }
        let closed = currentDateStatObj["Closed"][severity]
          ? currentDateStatObj["Closed"][severity].length
          : 0;

        dataAll[severity].push([date, created, closed + done, open]);
      } else {
        dataAll[severity].push([date, created, closed, open]);
      }

      columnMaxValues[severity] =
        columnMaxValues[severity] < created
          ? created
          : columnMaxValues[severity];
    }
  }

  for (let severity of stateRadioList) {
    if (dataAll[severity].length === 1) {
      dataAll[severity].push(valEmptyArray);
    }
  }

  var data = dataAll[currentSelectedSeverity];
  currentDataTableRef.current = data;

  var options = {
    title: "Open & Closed Defects By Severity",
    titleTextStyle: { fontSize: "16" },
    hAxis: {
      slantedText: true,
      slantedTextAngle: 45,
      textStyle: { fontSize: 11 },
    },
    vAxis: { format: "0" },
    gridlines: { count: -1 },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    colors: ["violet", "green", "red"],
  };

  if (columnMaxValues[currentSelectedSeverity] < 5) {
    options.vAxis = { format: "#", viewWindow: { max: 4 } };
  } else {
    options.vAxis = {
      format: "#",
      viewWindow: { max: columnMaxValues[currentSelectedSeverity] + 1 },
    };
  }

  var chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function (selectedSeverity) {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row;
          var col = selection[0].column;

          var selectedStatus = currentDataTableRef.current[0][col];
          var selectedDate = currentDataTableRef.current[row + 1][0];

          const iteration_values = Object.values(
            currentIterationInfoRef.current
          )
            ? Object.values(currentIterationInfoRef.current)
            : [];

          let values_lenght = 0;
          const iteration = iteration_values[iteration_values.length - 1];
          if (iteration?.Done) {
            Object.values(iteration?.Done).map((item) => {
              values_lenght += item.length;
            });
          }
          const title = selectedStatus;
          if (selectedStatus == "Closed/Done") {
            if (
              iteration_values.length > 0 &&
              iteration?.Done &&
              values_lenght != 0
            ) {
              selectedStatus = "Done";
            } else {
              selectedStatus = "Closed";
            }
          }
          var defectsIdsArray = currentIterationInfoRef.current?.[
            selectedDate
          ]?.[selectedStatus][currentSelectedSeverityRef.current]
            ? currentIterationInfoRef.current[selectedDate][selectedStatus][
                currentSelectedSeverityRef.current
              ]
            : [];
          var defectsInfoArray = [];
          Object.keys(currentIssueMapRef.current).forEach((issueId) => {
            if (defectsIdsArray?.indexOf(issueId) >= 0) {
              defectsInfoArray.push(currentIssueMapRef.current[issueId]);
            }
          });

          props.setModalData({
            title: selectedDate + ": " + title,
            projectId: props.currentProjectId,
            createdDt: selectedDate,
            releaseName: null,
            status: selectedStatus,
            severity: selectedSeverity,
            tableTitles: defectTitles,
            defects: defectsInfoArray,
          });
          if (defectsInfoArray.length < 1) {
            props.setShowModal(false);
          } else {
            props.setShowModal(true);
          }
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          () => handler(currentSelectedSeverityRef.current)
        );
      },
    },
  ];

  var handleStateChange = async (event) => {
    setCurrentSelectedSeverity(event.target.value);
  };

  //calculate closureRate for the last date
  if (datesArr && datesArr.length > 0) {
    let lastDate = datesArr[datesArr.length - 1];
    let lastDateObj = currentIterationForOpendClosedDefects[lastDate];

    let value_lenght = 0;
    if (lastDateObj?.Done) {
      Object.values(lastDateObj?.Done).map((item) => {
        value_lenght += item.length;
      });
    }

    const completed_value =
      lastDateObj?.Done && value_lenght > 0
        ? parseInt(getValue(lastDateObj.Done.All))
        : parseInt(getValue(lastDateObj.Closed.All));
    let closureRateValue =
      (
        (completed_value / parseInt(getValue(lastDateObj.Created.All))) *
        100
      ).toFixed(2) + "%";
    closureRateRef.current = closureRateValue;
  } else {
    closureRateRef.current = "0.00 %";
  }

  return (
    <div className="bg-white w-100 me-5 pt-1 mt-6">
      <div
        className="bg-white w-100 text-primary text-end me-5 mt-6"
        style={{
          marginTop: "5px",
          paddingTop: "20px",
          paddingRight: "30px",
        }}
      >
        Closure Rate: {closureRateRef.current}
      </div>
      <Chart
        chartType="LineChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
        chartEvents={chartEvents}
      />
      <div id="03" role="group" className="btn-group ms-4">
        {stateRadioList.map((item, index) => {
          return (
            <div className="me-3" key={index}>
              <input
                type="radio"
                id={item}
                name="options"
                className="mb-4"
                value={item}
                autoComplete="off"
                checked={currentSelectedSeverity === item}
                onChange={handleStateChange}
              />
              <label htmlFor={item} className="mb-4 ms-1">
                {item}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
