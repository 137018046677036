import { useRef } from "react";
import { Chart } from "react-google-charts";

const getTooltip = function (statuses, releaseTitle, name, values) {
  var tooltip =
    '<div align="left" style="width: auto; float: left; table-layout: auto">' +
    '<table class="table table-hover table-condensed table-sm" >' +
    "<thead><tr><th>" +
    releaseTitle +
    "</th></tr></thead>" +
    "<tbody>";

  let index = 0;
  for (let statValue of statuses) {
    if (statValue === name) {
      tooltip =
        tooltip +
        '<tr class="table-danger" key={' +
        index +
        "}><td><b>" +
        name +
        "</b>";
    } else {
      tooltip = tooltip + "<tr  key={" + index + "}><td>" + statValue;
    }
    tooltip =
      tooltip +
      ": <b>" +
      Math.abs(values[statValue]?.length ? values[statValue].length : 0) +
      "</b></td></tr>";
    index++;
  }
  tooltip = tooltip + "</tbody></table></div>";

  return tooltip;
};

export default function TargetedOpenDefectsBySprintChart(props) {
  var sprintNamesMap = props.jiraChartsInfo?.targetedOpenDefectsInfo
    ?.productSprintMap
    ? props.jiraChartsInfo.targetedOpenDefectsInfo.productSprintMap
    : { All: "All" };

  let issueMap = {};
  if (props.isADO === "ADO") {
    issueMap =
      props.jiraChartsInfo?.targetedOpenDefectsInfo?.data?.issueMap || {};
  } else {
    issueMap = props.jiraChartsInfo?.targetedOpenDefectsInfo?.issueMap || {};
  }

  var severities =
    props.isADO === "ADO"
      ? ["1 - Severe", "2 - High", "3 - Medium", "4 - Low"]
      : ["Sev 1-Severe", "Sev 2-High", "Sev 3-Medium", "Sev 4-Low"];

  const currentIterationInfoRef = useRef();

  var defectTitles = [
    "Issue ID",
    "Issue Summary",
    "Status",
    "Resolution",
    "Severity",
  ];
  //find out current iteration's id
  var currIterationId = "All";
  if (props.currentIteration !== "Overall") {
    currIterationId = props.currentIteration;
  }

  let currentIterationInfo;
  let iterationName;

  if (props.isADO === "ADO") {
    currentIterationInfo =
      props?.jiraChartsInfo?.targetedOpenDefectsInfo?.data?.issueBySprintMap[
        currIterationId
      ] || null;
  } else {
    currentIterationInfo =
      props?.jiraChartsInfo?.targetedOpenDefectsInfo?.issueBySprintMap[
        currIterationId
      ] || null;
    iterationName =
      props?.jiraChartsInfo?.targetedOpenDefectsInfo?.productSprintMap[
        currIterationId
      ];
  }

  currentIterationInfoRef.current = currentIterationInfo;

  var dataTable = [];
  //prepare array of empty values
  var emptyDataArray = [];
  emptyDataArray.push("Sprint");

  let headerArr = [];
  headerArr.push("Sprint");

  for (let sev of severities) {
    headerArr.push(sev);
    headerArr.push({
      type: "string",
      role: "tooltip",
      p: { html: true },
    });
    emptyDataArray.push(0);
    emptyDataArray.push("");
  }

  dataTable.push(headerArr);

  // set default empty values if no results received from server
  if (!currentIterationInfo) {
    dataTable.push(emptyDataArray);
  } else {
    for (let targetRelease in currentIterationInfo) {
      //according to request in QE-990
      let releaseTitle = targetRelease ? targetRelease : iterationName;
      let data = currentIterationInfo[targetRelease];
      var dataArray = [];
      dataArray.push(releaseTitle);

      for (let sev of severities) {
        dataArray.push(data[sev] ? data[sev].length : 0);
        var tooltip = getTooltip(severities, releaseTitle, sev, data);

        dataArray.push(tooltip);
      }

      dataTable.push(dataArray);
    }
  }

  if (props.isADO === "ADO") {
    dataTable.forEach((item, index) => {
      if (index !== 0) {
        let itemName = item[0];
        let newItemName = itemName.split("\\").slice(1).join("\\");

        item[0] = newItemName;
      }
    });
  }

  var options = {
    title: "Targeted Open Defects by Sprint",
    titleTextStyle: { fontSize: "16" },
    bars: "vertical",
    isStacked: true,
    hAxis: { slantedText: true, textStyle: { fontSize: 11 } },
    vAxis: { format: "0" },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    tooltip: { isHtml: true },
    colors: ["red", "#ffbf00", "blue", "green"],
  };

  var chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function () {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row;
          var col = selection[0].column;

          var selectedSeverity = dataTable[0][col];
          var selectedRelease = Object.keys(currentIterationInfoRef.current)[
            row
          ];
          var defectsIdsArray =
            currentIterationInfoRef.current?.[selectedRelease]?.[
              selectedSeverity
            ];
          var defectsInfoArray = [];
          Object.keys(issueMap).forEach((issueId) => {
            if (defectsIdsArray?.indexOf(issueId) >= 0) {
              defectsInfoArray.push(issueMap[issueId]);
            }
          });

          //according to request in QE-990
          selectedRelease = selectedRelease ? selectedRelease : "Unknown";

          props.setModalData({
            title: selectedRelease + ": " + selectedSeverity,
            projectId: props.currentProjectId,
            createdDt: null,
            releaseName: selectedRelease,
            status: null,
            severity: selectedSeverity,
            tableTitles: defectTitles,
            defects: defectsInfoArray,
          });
          props.setShowModal(true);
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          () => handler()
        );
      },
    },
  ];

  return (
    <div>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={dataTable}
        options={options}
        chartEvents={chartEvents}
      />
    </div>
  );
}
