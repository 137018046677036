import React, { useState, useEffect, useRef } from "react";
import ErrorMessage from "../../components/common/ErrorMessage";
import Jira from "../../services/Jira";
import Qtest from "../../services/Qtest";
import Projects from "../../services/Projects";
import OpenDefectsTrendingChart from "../../components/charts/steercoCharts/OpenDefectsTrendingChart";
import OpenClosedDefectsChart from "../../components/charts/defectsCharts/OpenClosedDefectsChart";
import useProjectDetails from "../../components/hooks/useProjectDetails";
import { Chart } from "react-google-charts";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import "../../components/css/bootstrap.min.css";
import "../../components/css/custom.min.css";

const emptyInfoObjToSave = {
  projectId: 0,
  summary: "",
  scheduleChanges: "",
  risks: "",
  missingFeatures: "",
  blockingDefects: "",
  cacheDate: "",
};

const emptyEditableInfoObj = {
  currentIterationName: "",
  currentMilestones: "",
  nextMilestones: "",
  iteration: {},
  testExecutionSummary: {},
  testCaseDevTreding: {},
};

//objects for charts info
const emptyTestExecInfoObj = {
  total: { total: 0, passed: 0, failed: 0, blocked: 0 },
  executed: { total: 0, passed: 0, failed: 0, blocked: 0 },
};
const emptyTestDevInfoObj = {
  date: "",
  inProgress: 0,
  baselined: 0,
  readyForBaseline: 0,
  new: 0,
};
const emptyOpenClosedDefectsObj = {
  date: "",
  created: 0,
  open: 0,
  closed: 0,
  closureRate: 0,
};
const emptyOpenDefectsTrendingInfoObj = {
  date: "",
  all: 0,
  sev1: 0,
  sev2: 0,
  sev3: 0,
  sev4: 0,
};

async function getAllInfoFromServer(token, projectId) {
  let promises = [];

  promises.push(getStatusReportInfo(token, projectId));
  promises.push(getTestExecutionInfo(token, projectId));
  promises.push(getTestDevelopmentInfo(token, projectId));
  promises.push(getJiraChartsInfo(token, projectId));
  promises.push(getJiraSteercoDefectsInfo(token, projectId));

  return Promise.all(promises);
}

async function getProjectDetails(token, projectId) {
  try {
    const resp = await Projects.getProjectDetails(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting project details (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getStatusReportInfo(token, projectId) {
  try {
    const resp = await Projects.getStatusReportInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting project details (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function saveStatusReportInfo(token, detailsObj) {
  try {
    const resp = await Projects.postStatusReportInfo(token, detailsObj);
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting project details (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getTestExecutionInfo(token, projectId) {
  try {
    const resp = await Qtest.getTestExecutionInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting Test Cases info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}
async function getTestDevelopmentInfo(token, projectId) {
  try {
    const resp = await Qtest.getTestDevelopmentInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting Test Development info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}
async function getJiraChartsInfo(token, projectId) {
  try {
    const resp = await Jira.getJiraChartsForReportInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error Jira info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}
async function getJiraSteercoDefectsInfo(token, projectId) {
  try {
    const resp = await Jira.getJiraSteercoDefectsInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error Steerco Jira info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

const ReportPage = (props) => {
  const reportTableContainer = useRef(null);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [reportInfoToSave, setReportInfoToSave] = useState(emptyInfoObjToSave);
  const [reportInfo, setReportInfo] = useState(emptyEditableInfoObj);
  const [tcSummaryChartUri, setTcSummaryChartUri] = useState("");
  const [tcSummaryChartDisplay, setTcSummaryChartDisplay] = useState(true);
  const [tcSummaryInfo, setTcSummaryInfo] = useState(emptyTestExecInfoObj);
  const [tcDevChartUri, setTcDevChartUri] = useState("");
  const [tcDevChartDisplay, setTcDevChartDisplay] = useState(true);
  const [tcDevInfo, setTcDevInfo] = useState(emptyTestDevInfoObj);
  const [nextMilestonesValue, setNextMilestonesValue] = useState("");
  const [openClosedDefectsChartUri, setOpenClosedDefectsChartUri] =
    useState("");
  const [openClosedDefectsChartDisplay, setOpenClosedDefectsChartDisplay] =
    useState(true);
  const [openClosedDefectsInfo, setOpenClosedDefectsInfo] = useState(
    emptyOpenClosedDefectsObj
  );
  const [openDefectsTrendingChartUri, setOpenDefectsTrendingChartUri] =
    useState("");
  const [openDefectsTrendingChartDisplay, setOpenDefectsTrendingChartDisplay] =
    useState(true);
  const [openDefectsTrendingInfo, setOpenDefectsTrendingInfo] = useState(
    emptyOpenDefectsTrendingInfoObj
  );
  const { projectDetails, setProjectDetails } = useProjectDetails();
  const { projectReportInfo, setProjectReportInfo } = useProjectDetails({
    id: "",
  });
  const { projectId } = useParams();
  useEffect(() => {
    let handleNextMilestonesResponse = handleNextMilestonesStrings();

    if (nextMilestonesValue === "") {
      setNextMilestonesValue(handleNextMilestonesResponse);
    }
  }, [projectReportInfo?.nextMilestones, reportInfo?.nextMilestones]);

  const getProjectIdFromUrl = () => {
    let currentProjectId = projectId;
    // if (props.currentProjectId) {
    //   return props.currentProjectId;
    // }
    // let currentId;
    // var url = window.location.href;
    // let res = /project\/(\d+)/g.exec(url);

    // if (res?.length > 1 && res[1] !== "null") {
    //   currentId = res[1];
    //   props.setCurrentProjectId(currentId);
    // } else {
    //   setErrorMessage("Project is not selected!");
    // }
    return currentProjectId;
  };

  const getCurrentObjFromList = (listArr, toGetLatestNotCurrent) => {
    let todayDate = new Date(new Date().toDateString());
    let latestId;

    let foundList = listArr.filter((iteration) => {
      if (!latestId) {
        latestId = iteration.id;
      } else if (latestId < iteration.id) {
        latestId = iteration.id;
      }
      let endDate = iteration.endDate ? new Date(iteration.endDate) : null;
      let startDate = iteration.startDate
        ? new Date(iteration.startDate)
        : null;

      return (
        startDate && endDate && endDate >= todayDate && startDate <= todayDate
      );
    });

    //if the current iteration not found, find last iteration by id
    if (foundList.length < 1 && latestId && toGetLatestNotCurrent) {
      foundList = listArr.filter((iteration) => {
        return latestId === iteration.id;
      });
    }
    return foundList;
  };

  const getNextObjFromList = (listArr) => {
    let todayDate = new Date(new Date().toDateString());
    let latestId;

    let foundList = listArr.filter((iteration) => {
      if (!latestId) {
        latestId = iteration.id;
      } else if (latestId < iteration.id) {
        latestId = iteration.id;
      }
      let endDate = iteration.endDate ? new Date(iteration.endDate) : null;
      let startDate = iteration.startDate
        ? new Date(iteration.startDate)
        : null;

      return startDate && endDate && startDate > todayDate;
    });
    return foundList;
  };

  const storeProjectDetailsToReportInfoObj = (tempInfoObj, _projectDetails) => {
    let objProjectDetails;
    if (_projectDetails) {
      objProjectDetails = JSON.parse(JSON.stringify(_projectDetails));
    } else {
      objProjectDetails = JSON.parse(JSON.stringify(projectDetails));
    }
    if (!objProjectDetails) {
      return;
    }

    //set test plan link
    tempInfoObj.testPlanLink = objProjectDetails.testPlanLink;

    //find needed current iteration by dates from project info
    let currIterations = getCurrentObjFromList(
      objProjectDetails.lpIterations,
      true
    );
    tempInfoObj.iteration =
      currIterations?.length > 0
        ? JSON.parse(JSON.stringify(currIterations[0]))
        : null;
    tempInfoObj.currentIterationName = currIterations[0]?.iterationName
      ? currIterations[0].iterationName
      : "";

    //set current milestones: add current iterations
    let currentIterations = getCurrentObjFromList(
      objProjectDetails.lpIterations
    );
    currentIterations.forEach((item) => {
      tempInfoObj.currentMilestones +=
        tempInfoObj.iteration.iterationName +
        " " +
        tempInfoObj.iteration.startDate +
        " to " +
        tempInfoObj.iteration.endDate +
        "\n";
    });

    /**
         * @jira QE-1325, QE-1351
         * milestones are not needed in the "current" list

        // //set current milestones: add current milestones
        let currentMilestones = getCurrentObjFromList(
            objProjectDetails.projectMilestones
        );
        currentMilestones.forEach((item) => {
            tempInfoObj.currentMilestones +=
                item.milestone + " " + item.startDate + " to " + item.endDate + "\n";
        });
        */

    //set next milestones

    /**
         * @jira QE-1325, QE-1351:
        * next iterations are not needed in the "Milestones" list
        let nextIterations = getNextObjFromList(objProjectDetails.lpIterations);
        nextIterations.forEach((item) => {
            tempInfoObj.nextMilestones +=
                item.iterationName + " " + item.startDate + " to " + item.endDate + "\n";
        });
         */

    let nextMilestones = getNextObjFromList(
      objProjectDetails.projectMilestones
    );
    nextMilestones.forEach((item) => {
      tempInfoObj.nextMilestones +=
        item.milestone + " " + item.startDate + " to " + item.endDate + "\n";
    });

    return tempInfoObj;
  };

  useEffect(() => {
    const handleProjectReportInfo = async () => {
      var currentId = getProjectIdFromUrl();
      if (!currentId) {
        return;
      }
      let errorMsg = "";

      let objInfo = JSON.parse(JSON.stringify(reportInfo));

      setLoading(true);
      if (!projectDetails || projectDetails.projectId !== currentId) {
        //get project info from the server
        const projectDetailsResponse = await getProjectDetails(
          props.token,
          currentId
        );
        if (projectDetailsResponse.message) {
          errorMsg += projectDetailsResponse.message + "\n";
          setProjectDetails({});
        } else {
          setProjectDetails(projectDetailsResponse);
          objInfo = storeProjectDetailsToReportInfoObj(
            objInfo,
            projectDetailsResponse
          );
        }
      } else {
        objInfo = storeProjectDetailsToReportInfoObj(objInfo);
      }

      const reportResponse = await getAllInfoFromServer(props.token, currentId);
      setTcSummaryChartDisplay(true);
      setTcDevChartDisplay(true);
      setOpenClosedDefectsChartDisplay(true);
      setOpenDefectsTrendingChartDisplay(true);
      setLoading(false);

      //set next milestones info
      setNextMilestonesValue(objInfo.nextMilestones);

      //get the needed info from the responses
      //saved report info
      emptyInfoObjToSave.projectId = currentId;
      if (!reportResponse[0]) {
        setReportInfoToSave(emptyInfoObjToSave);
      } else if (reportResponse[0].message) {
        errorMsg += reportResponse[0]?.message + "\n";
        setReportInfoToSave(emptyInfoObjToSave);
      } else {
        setReportInfoToSave(reportResponse[0]);
      }

      //TC execution info
      if (reportResponse[1].message) {
        errorMsg += reportResponse[1].message + "\n";
      } else {
        objInfo.testExecutionSummary = reportResponse[1].testExecutionSummary;
        //objInfo.testCaseDevTreding = reportResponse[1].testExecutionSummary;
      }

      //TC Dev Treding info
      if (reportResponse[2].statusCode !== 200) {
        errorMsg += reportResponse[2].message + "\n";
      } else {
        objInfo.testCaseDevTreding =
          reportResponse[2].qtestTestCaseDevTrendingDto?.testDevelopmentMap;
      }

      //Open & Closed Defects
      if (reportResponse[3].message) {
        errorMsg += reportResponse[3].message + "\n";
      } else {
        objInfo.openClosedDefects = reportResponse[3].openClosedDefects;
      }

      // Open Defects Trending
      if (reportResponse[4].message) {
        errorMsg += reportResponse[4].message + "\n";
      } else {
        objInfo.openDefectsTrending = reportResponse[4];
      }

      setReportInfo(objInfo);

      if (errorMsg) {
        setErrorMessage(errorMsg);
      }
    };
    props.changeStyle("report");
    handleProjectReportInfo();
  }, []);

  useEffect(() => {
    if (
      !projectReportInfo ||
      projectReportInfo.projectId !== projectDetails?.projectId
    ) {
      setProjectReportInfo({
        currentMilestones: "",
        nextMilestones: "",
        projectId: projectDetails?.projectId,
      });
    }
  }, [projectReportInfo]);

  useEffect(() => {
    if (
      !reportInfo?.testExecutionSummary ||
      !reportInfo?.currentIterationName ||
      !reportInfo.testExecutionSummary[reportInfo.currentIterationName] ||
      Object.keys(
        reportInfo.testExecutionSummary[reportInfo.currentIterationName]
      ).length < 1
    ) {
    } else {
      const currentExecSummary =
        reportInfo.testExecutionSummary[reportInfo.currentIterationName];

      //prepare info for Test Execution Info
      let execObjInfo = currentExecSummary["Total"];
      let tmpInfoObj = JSON.parse(JSON.stringify(emptyTestExecInfoObj));

      tmpInfoObj.total.total = execObjInfo?.Total ? execObjInfo.Total : 0;
      tmpInfoObj.total.passed = execObjInfo?.Passed ? execObjInfo.Passed : 0;
      tmpInfoObj.total.failed = execObjInfo?.Failed ? execObjInfo.Failed : 0;
      tmpInfoObj.total.blocked = execObjInfo?.Blocked ? execObjInfo.Blocked : 0;

      let executed = tmpInfoObj.total.passed + tmpInfoObj.total.failed;
      tmpInfoObj.executed.total = Math.round(
        (executed / tmpInfoObj.total.total) * 100
      );
      tmpInfoObj.executed.passed = Math.round(
        (tmpInfoObj.total.passed / executed) * 100
      );
      tmpInfoObj.executed.failed = Math.round(
        (tmpInfoObj.total.failed / executed) * 100
      );
      tmpInfoObj.executed.blocked = Math.round(
        (tmpInfoObj.total.blocked / tmpInfoObj.total.total) * 100
      );

      setTcSummaryInfo(tmpInfoObj);
    }
  }, [reportInfo.testExecutionSummary]);

  useEffect(() => {
    if (
      !reportInfo?.testCaseDevTreding ||
      !reportInfo?.testCaseDevTreding ||
      !reportInfo.testCaseDevTreding[reportInfo.currentIterationName] ||
      Object.keys(
        reportInfo.testCaseDevTreding[reportInfo.currentIterationName]
      ).length < 1
    ) {
    } else {
      let notSortedInfo =
        reportInfo.testCaseDevTreding[reportInfo.currentIterationName];

      let sortedDates = Object.keys(notSortedInfo).sort(function (a, b) {
        return new Date(a).getTime() - new Date(b).getTime();
      });

      //prepare info for Test Case Development Info
      let tmpInfoObj = JSON.parse(JSON.stringify(emptyTestDevInfoObj));
      tmpInfoObj.date = sortedDates[sortedDates.length - 1];
      let lastDateInfoObj = notSortedInfo[tmpInfoObj.date];
      tmpInfoObj.inProgress = lastDateInfoObj.InProgress
        ? lastDateInfoObj.InProgress.length
        : 0;
      tmpInfoObj.baselined = lastDateInfoObj.Baselined
        ? lastDateInfoObj.Baselined.length
        : 0;
      tmpInfoObj.readyForBaseline = lastDateInfoObj["Ready For Baseline"]
        ? lastDateInfoObj["Ready For Baseline"].length
        : 0;
      tmpInfoObj.new = lastDateInfoObj.New ? lastDateInfoObj.New.length : 0;

      setTcDevInfo(tmpInfoObj);
    }
  }, [reportInfo.testCaseDevTreding]);

  useEffect(() => {
    if (
      !reportInfo?.openClosedDefects ||
      !reportInfo.openClosedDefects["Overall"] ||
      Object.keys(reportInfo.openClosedDefects["Overall"]).length < 1
    ) {
    } else {
      let notSortedInfo = reportInfo.openClosedDefects["Overall"];

      let sortedDates = Object.keys(notSortedInfo).sort(function (a, b) {
        return new Date(a).getTime() - new Date(b).getTime();
      });

      //prepare info for Open Closed Defects Info
      let tmpInfoObj = JSON.parse(JSON.stringify(emptyOpenClosedDefectsObj));
      tmpInfoObj.date = sortedDates[sortedDates.length - 1];
      let lastDateInfoObj = notSortedInfo[tmpInfoObj.date];
      tmpInfoObj.created = lastDateInfoObj.Created
        ? lastDateInfoObj.Created
        : 0;
      tmpInfoObj.open = lastDateInfoObj.Open ? lastDateInfoObj.Open : 0;
      tmpInfoObj.closed = lastDateInfoObj.Closed ? lastDateInfoObj.Closed : 0;
      tmpInfoObj.closureRate = (
        (tmpInfoObj.closed.length / tmpInfoObj.created.length) *
        100
      ).toFixed(2);

      setOpenClosedDefectsInfo(tmpInfoObj);
    }
  }, [reportInfo.openClosedDefects]);

  useEffect(() => {
    if (
      !reportInfo?.openDefectsTrending?.steerCoOpenClosedDefectsBySev ||
      !reportInfo.openDefectsTrending?.steerCoOpenClosedDefectsBySev[
        "Overall"
      ] ||
      Object.keys(
        reportInfo.openDefectsTrending.steerCoOpenClosedDefectsBySev["Overall"]
      ).length < 1
    ) {
    } else {
      let notSortedInfo =
        reportInfo.openDefectsTrending.steerCoOpenClosedDefectsBySev["Overall"];

      let sortedDates = Object.keys(notSortedInfo).sort(function (a, b) {
        return new Date(a).getTime() - new Date(b).getTime();
      });

      //prepare info for Open Defects Trending Info
      let tmpInfoObj = JSON.parse(
        JSON.stringify(emptyOpenDefectsTrendingInfoObj)
      );
      tmpInfoObj.date = sortedDates[sortedDates.length - 1];
      let lastDateInfoObj = notSortedInfo[tmpInfoObj.date].Open;
      tmpInfoObj.all = lastDateInfoObj.All ? lastDateInfoObj.All.length : 0;
      tmpInfoObj.sev1 = lastDateInfoObj["Sev 1-Severe"]
        ? lastDateInfoObj["Sev 1-Severe"].length
        : 0;
      tmpInfoObj.sev2 = lastDateInfoObj["Sev 2-High"]
        ? lastDateInfoObj["Sev 2-High"].length
        : 0;
      tmpInfoObj.sev3 = lastDateInfoObj["Sev 3-Medium"]
        ? lastDateInfoObj["Sev 3-Medium"].length
        : 0;
      tmpInfoObj.sev4 = lastDateInfoObj["Sev 4-Low"]
        ? lastDateInfoObj["Sev 4-Low"].length
        : 0;

      setOpenDefectsTrendingInfo(tmpInfoObj);
    }
  }, [reportInfo.openDefectsTrending]);

  const handleDownload = () => {
    saveStatusReportInfo(props.token, reportInfoToSave)
      .then((resp) => {
        if (resp.message) {
          setErrorMessage(resp.message);
        }
      })
      .catch((err) => {
        setErrorMessage(err.message);
      });

    saveReport();
  };

  const saveReport = async () => {
    const css =
      "<style>" +
      //'@page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}' +
      "div#report_main_container_1" +
      "table{border-collapse:collapse;}td{border:1px gray solid;width:5em;padding:2px;}" +
      "textarea{border:none;width:100%;height:100%;padding:0;overflow:hidden;resize:none}" +
      "-webkit-scrollbar{display:none;}" +
      "</style>";

    let html = reportTableContainer.current.innerHTML;
    html = html.replaceAll("textarea", "span");

    let blob = new Blob(["\ufeff", css + html], {
      type: "application/msword",
    });

    saveAs(
      blob,
      "QE Status report - " +
        new Date().toLocaleDateString() +
        " " +
        new Date().toLocaleTimeString() +
        " - " +
        projectDetails?.projectName +
        ".doc"
    );
  };

  const TestExecutionChart = () => {
    const columns = ["Test Cycle", "Executed", "Passed", "Failed", "Blocked"];
    const emptyData = [
      ["Total", 0, 0, 0, 0],
      ["Regression", 0, 0, 0, 0],
      ["New Feature", 0, 0, 0, 0],
      ["Performance", 0, 0, 0, 0],
    ];

    var data = [];
    data.push(columns);

    //if no data then create an empty chart
    if (
      !reportInfo?.testExecutionSummary ||
      !reportInfo?.currentIterationName ||
      !reportInfo.testExecutionSummary[reportInfo.currentIterationName] ||
      Object.keys(
        reportInfo.testExecutionSummary[reportInfo.currentIterationName]
      ).length < 1
    ) {
      data = [...data, ...emptyData];
    } else {
      const currentExecSummary =
        reportInfo.testExecutionSummary[reportInfo.currentIterationName];

      //prepare data for the chart
      const labels = Object.keys(currentExecSummary);

      labels.forEach(function (label) {
        let labelData = currentExecSummary[label];
        labelData = labelData ? labelData : {};
        labelData.Total = labelData.Total ? labelData.Total : 0;
        labelData.Passed = labelData?.Passed ? labelData.Passed : 0;
        labelData.Failed = labelData?.Failed ? labelData.Failed : 0;
        labelData.Blocked = labelData?.Blocked ? labelData.Blocked : 0;

        const totalTestRuns = labelData.Total;
        let executed = labelData.Passed + labelData.Failed;

        const rowValue = [label, Math.round((executed / totalTestRuns) * 100)];
        let percentageValue = Math.round((labelData.Passed / executed) * 100);
        rowValue.push(percentageValue);

        percentageValue = Math.round((labelData.Failed / executed) * 100);
        rowValue.push(percentageValue);

        percentageValue = Math.round((labelData.Blocked / totalTestRuns) * 100);
        rowValue.push(percentageValue);

        data.push(rowValue);
      });
    }

    var options = {
      title: "Test Execution Summary",
      titleTextStyle: { fontSize: "16" },
      bars: "vertical",
      vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
      colors: ["#3366cc", "#109618", "#dc3912", "#ff9900"],
      legend: {
        position: "",
        maxLines: 1,
      },
    };

    var chartEvents = [
      {
        eventName: "ready",
        callback: ({ chartWrapper, google }) => {
          let url = chartWrapper.getChart().getImageURI();
          setTcSummaryChartUri(url);
          setTcSummaryChartDisplay(false);
        },
      },
    ];

    return (
      <Chart
        id="test-execution-chart"
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
        chartEvents={chartEvents}
      />
    );
  };

  const TestCaseDevelopmentChart = () => {
    const getValue = (value) => {
      return value ? value.length : 0;
    };

    let columnArray = [
      "Dates",
      "In Progress",
      "Baselined",
      "Ready For Baseline",
      "New",
    ];
    let valEmptyArray = ["Dates", 0, 0, 0, 0];

    var dataTable = [];
    dataTable.push(columnArray);

    //if no data then create an empty chart
    if (
      !reportInfo?.testCaseDevTreding ||
      !reportInfo?.currentIterationName ||
      !reportInfo.testCaseDevTreding[reportInfo.currentIterationName] ||
      Object.keys(
        reportInfo.testCaseDevTreding[reportInfo.currentIterationName]
      ).length < 1
    ) {
      dataTable.push(valEmptyArray);
    } else {
      let notSortedInfo =
        reportInfo.testCaseDevTreding[reportInfo.currentIterationName];

      var currentIterationTrendingInfo = {};
      if (notSortedInfo) {
        Object.keys(notSortedInfo)
          .sort(function (a, b) {
            return new Date(a).getTime() - new Date(b).getTime();
          })
          .forEach(function (key) {
            currentIterationTrendingInfo[key] = notSortedInfo[key];
          });
      }

      const datesArr = Object.keys(currentIterationTrendingInfo);
      for (let date of datesArr) {
        var tempStatObj = {};
        tempStatObj.InProgress = getValue(
          currentIterationTrendingInfo[date].InProgress
        );
        tempStatObj.Baselined = getValue(
          currentIterationTrendingInfo[date].Baselined
        );
        tempStatObj.ReadyForBaseline = getValue(
          currentIterationTrendingInfo[date]["Ready For Baseline"]
        );
        tempStatObj.New = getValue(currentIterationTrendingInfo[date].New);

        var dataArray = [
          date,
          tempStatObj.InProgress,
          tempStatObj.Baselined,
          tempStatObj.ReadyForBaseline,
          tempStatObj.New,
        ];
        dataTable.push(dataArray);
      }
    }
    var data = dataTable;

    var options = {
      title: "Test Case Development Trending",
      titleTextStyle: { fontSize: "16" },
      bars: "vertical",

      isStacked: true,
      hAxis: {
        slantedText: true,
        slantedTextAngle: 45,
        textStyle: { fontSize: 11 },
      },
      vAxis: { format: "0" },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    };

    var chartEvents = [
      {
        eventName: "ready",
        callback: ({ chartWrapper, google }) => {
          let url = chartWrapper.getChart().getImageURI();
          setTcDevChartUri(url);
          setTcDevChartDisplay(false);
        },
      },
    ];

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
        chartEvents={chartEvents}
      />
    );
  };

  const handleSummaryChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(reportInfoToSave));
    tmp.summary = e.target.value;
    setReportInfoToSave(tmp);
  };

  const handleScheduleChangesChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(reportInfoToSave));
    tmp.scheduleChanges = e.target.value;
    setReportInfoToSave(tmp);
  };

  const handleRisksAndMitigationChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(reportInfoToSave));
    tmp.risks = e.target.value;
    setReportInfoToSave(tmp);
  };

  const handleMissingFeatureChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(reportInfoToSave));
    tmp.missingFeatures = e.target.value;
    setReportInfoToSave(tmp);
  };

  const handleBlokingDefectsChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(reportInfoToSave));
    tmp.blockingDefects = e.target.value;
    setReportInfoToSave(tmp);
  };

  const handleTestPlanChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(reportInfo));
    tmp.testPlanLink = e.currentTarget.textContent;
    setReportInfo(tmp);
  };

  const handleCurrentMilestonesChange = (e, index) => {
    let tmp = JSON.parse(JSON.stringify(reportInfo));
    tmp.currentMilestones = e.target.value;
    setReportInfo(tmp);
    //add value to page's cash
    projectReportInfo.currentMilestones = e.target.value;
    setProjectReportInfo(projectReportInfo);
  };

  const handleNextMilestonesChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(reportInfo));
    tmp.nextMilestones = e.target.value;
    setReportInfo(tmp);
    //add value to page's cash
    projectReportInfo.nextMilestones = e.target.value;
    setProjectReportInfo(projectReportInfo);
    setNextMilestonesValue(e.target.value);
  };

  const handleNextMilestonesStrings = () => {
    let responseString = projectReportInfo?.nextMilestones
      ? projectReportInfo.nextMilestones
      : reportInfo.nextMilestones;

    let subStrings = responseString.split("Sprint");

    if (subStrings[0].trim() === "") {
      subStrings.shift();
    }

    for (var i = 0; i < subStrings.length; i++) {
      subStrings[i] = "Sprint " + subStrings[i].trim();
    }

    let stringsWithSignOffWord = [];
    let stringsWithoutSignOffWord = [];

    for (var i = 0; i < subStrings.length; i++) {
      if (subStrings[i].includes("Sign Off")) {
        stringsWithSignOffWord.push(subStrings[i]);
      } else {
        stringsWithoutSignOffWord.push(subStrings[i]);
      }
    }

    subStrings = stringsWithoutSignOffWord.concat(stringsWithSignOffWord);

    let finalString = subStrings.join(", ").replace(/, /g, "\n");

    return finalString;
  };

  return (
    <div
      id="report_main_container_1"
      className="bg-light  container-fluid pt-3 pb-3"
      style={
        loading
          ? {
              pointerEvents: "none",
              opacity: "0.4",
            }
          : {}
      }
    >
      {loading && (
        <div className="d-flex justify-content-center bg-transparent align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <h4 className="ms-4">QE Status Report</h4>
      <ErrorMessage errorMessage={errorMessage} />
      <div className="ms-4 mt-4">
        <button type="button" onClick={handleDownload}>
          Download
        </button>
      </div>
      <div id="download_link"></div>
      <div className="row ms-3 mt-2 me-3">
        <div className="col-lg-9 col-xl-9" ref={reportTableContainer}>
          <table
            id="reportTable"
            className="table table-bordered table-responsive table-condensed table-sm bg-white"
            style={{ border: "1px solid grey", borderCollapse: "collapse" }}
          >
            <tbody>
              <tr
                style={{ backgroundColor: "#428bca", color: "rgb(33, 37, 41)" }}
              >
                <td colSpan="2">
                  {projectDetails?.projectName +
                    " - QE Status Report - " +
                    new Date().toLocaleDateString() +
                    " " +
                    new Date().toLocaleTimeString()}
                </td>
              </tr>
              <tr className="m-0 p-0">
                <td className="col-sm-2">Summary:</td>
                <td id="rptSummaryTD" className="m-0 p-0">
                  <textarea
                    className="tableTextArea"
                    onChange={handleSummaryChange}
                    value={reportInfoToSave.summary}
                  />
                </td>
              </tr>
              <tr className="m-0 p-0">
                <td className="col-sm-1">Current Sprint:</td>
                <td className="m-0 p-0">
                  <textarea
                    className="tableTextArea"
                    onChange={handleCurrentMilestonesChange}
                    value={
                      projectReportInfo?.currentMilestones
                        ? projectReportInfo.currentMilestones
                        : reportInfo.currentMilestones
                    }
                  />
                </td>
              </tr>
              <tr className="m-0 p-0">
                <td className="col-sm-1">Milestones:</td>
                <td className="m-0 p-0">
                  <textarea
                    className="tableTextArea"
                    onChange={handleNextMilestonesChange}
                    value={nextMilestonesValue}
                  />
                </td>
              </tr>
              <tr className="m-0 p-0">
                <td className="col-sm-1">Schedule Changes:</td>
                <td className="m-0 p-0">
                  <textarea
                    className="tableTextArea"
                    onChange={handleScheduleChangesChange}
                    value={reportInfoToSave.scheduleChanges}
                  />
                </td>
              </tr>
              <tr className="m-0 p-0">
                <td>Risks and Mitigation:</td>
                <td className="m-0 p-0">
                  <textarea
                    className="tableTextArea"
                    onChange={handleRisksAndMitigationChange}
                    value={reportInfoToSave.risks}
                  />
                </td>
              </tr>
              <tr>
                <td>Missing Features:</td>
                <td className="m-0 p-0">
                  <textarea
                    className="tableTextArea"
                    onChange={handleMissingFeatureChange}
                    value={reportInfoToSave?.missingFeatures}
                  />
                </td>
              </tr>
              <tr>
                <td>Blocking Defects for Test Cases:</td>
                <td className="m-0 p-0">
                  <textarea
                    className="tableTextArea"
                    onChange={handleBlokingDefectsChange}
                    value={reportInfoToSave.blockingDefects}
                  />
                </td>
              </tr>
              <tr className="m-0 p-0">
                <td>Test Plan:</td>
                <td className="m-0 p-0">
                  <div
                    contentEditable="true"
                    onInput={handleTestPlanChange}
                    suppressContentEditableWarning={true}
                  >
                    {reportInfo?.testPlanLink}
                  </div>
                </td>
              </tr>
              <tr>
                <td>All Defects:</td>
                <td>
                  <div id="all-defects-report-container">
                    <div id="open-and-closed-defects-container">
                      <div>
                        {openClosedDefectsChartDisplay && (
                          <OpenClosedDefectsChart
                            jiraChartsInfo={reportInfo}
                            currentIteration={"Overall"}
                            currentProjectId={props.currentProjectId}
                            setOpenClosedDefectsChartUri={
                              setOpenClosedDefectsChartUri
                            }
                            setOpenClosedDefectsChartDisplay={
                              setOpenClosedDefectsChartDisplay
                            }
                            event={true}
                          />
                        )}
                      </div>
                      <div id="open-and-closed-defects-img-container">
                        {!!!openClosedDefectsChartDisplay && (
                          <img
                            id="open-and-closed-defects-img"
                            className="img-fluid float-start"
                            src={openClosedDefectsChartUri}
                            alt=""
                          />
                        )}
                      </div>
                      <div id="open-and-closed-defects-info">
                        {openClosedDefectsInfo.date +
                          ": Created: " +
                          openClosedDefectsInfo.created.length +
                          " Open: " +
                          openClosedDefectsInfo.open.length +
                          " Closed: " +
                          openClosedDefectsInfo.closed.length +
                          "  Closure Rate: " +
                          openClosedDefectsInfo.closureRate +
                          "%"}
                      </div>
                    </div>
                    <div id="open-defects-trending-report-container">
                      <div>
                        {openDefectsTrendingChartDisplay && (
                          <OpenDefectsTrendingChart
                            jiraSteercoDefectsInfo={
                              reportInfo.openDefectsTrending
                            }
                            currentIteration={"Overall"}
                            currentProjectId={props.currentProjectId}
                            setOpenDefectsTrendingChartUri={
                              setOpenDefectsTrendingChartUri
                            }
                            setOpenDefectsTrendingChartDisplay={
                              setOpenDefectsTrendingChartDisplay
                            }
                            event={true}
                          />
                        )}
                      </div>
                      <div id="open-defects-trending-img-container">
                        {!!!openDefectsTrendingChartDisplay && (
                          <img
                            id="open-and-closed-defects-trending-img"
                            className="img-fluid float-start"
                            src={openDefectsTrendingChartUri}
                            alt=""
                          />
                        )}
                      </div>
                      <div id="open-defects-trending-info" className="w-100">
                        {openDefectsTrendingInfo.date +
                          ": All: " +
                          openDefectsTrendingInfo.all +
                          " Sev 1-Severe: " +
                          openDefectsTrendingInfo.sev1 +
                          " Sev 2-High: " +
                          openDefectsTrendingInfo.sev2 +
                          " Sev 3-Medium: " +
                          openDefectsTrendingInfo.sev3 +
                          " Sev 4-Low:" +
                          openDefectsTrendingInfo.sev4}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Test Case Development: {reportInfo.currentIterationName}
                </td>
                <td>
                  <div id="test-dev-chart-report-container">
                    <div>
                      {tcDevChartDisplay && <TestCaseDevelopmentChart />}
                    </div>
                    <div id="test-execution-chart-img-container">
                      {!!!tcDevChartDisplay && (
                        <img
                          id="test-dev-chart-img"
                          className="img-fluid float-start"
                          src={tcDevChartUri}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <div id="test-dev-chart-info" className="w-100">
                    {tcDevInfo.date +
                      ": New: " +
                      tcDevInfo.new +
                      ", In Progress: " +
                      tcDevInfo.inProgress +
                      ",  Baselined: " +
                      tcDevInfo.baselined +
                      ",  Ready For Baseline: " +
                      tcDevInfo.readyForBaseline}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Test Case Execution Chart: {reportInfo.currentIterationName}
                </td>
                <td>
                  <div id="test-execution-chart-report-container">
                    {tcSummaryChartDisplay && <TestExecutionChart />}
                  </div>
                  <div id="test-execution-chart-img-container">
                    {!!!tcSummaryChartDisplay && (
                      <img
                        id="test-execution-chart-img"
                        className="img-fluid float-start"
                        src={tcSummaryChartUri}
                        alt=""
                      />
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Test Case Execution: {reportInfo.currentIterationName}</td>
                <td>
                  <div id="test-execution-chart-info">
                    <div>
                      {"Total Executed: " +
                        tcSummaryInfo.executed.total +
                        "%  Pass: " +
                        tcSummaryInfo.executed.passed +
                        "%  Fail: " +
                        tcSummaryInfo.executed.failed +
                        "%  Blocked: " +
                        tcSummaryInfo.executed.blocked +
                        "%"}
                    </div>
                    <div>
                      {"Total Tests: " +
                        tcSummaryInfo.total.total +
                        " Passed: " +
                        tcSummaryInfo.total.passed +
                        " Failed: " +
                        tcSummaryInfo.total.failed +
                        " Blocked: " +
                        tcSummaryInfo.total.blocked}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
