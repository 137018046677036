import React, { useState, useEffect } from "react";
import ErrorMessage from "../../components/common/ErrorMessage";
import ProjectTypes from "../../components/projects/ProjectTypes";
import DefectByCategoryChart from "../../components/charts/defectByCategoryCharts/DefectByCategoryChart";
import Projects from "../../services/Projects";
import Loading from "../../components/common/Loading";
import useProjects from "../../components/hooks/useProjects";
import Button from "@mui/material/Button";
import Jira from "../../services/Jira";
import { MultiSelect } from "react-multi-select-component";
import "../../components/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getJiraCustomInfo(token, projectType) {
  try {
    const resp = await Jira.getIssueBacklogRegression(token, projectType);
    return resp;
  } catch (err) {
    return {
      errMsg: err.message ? err.message : JSON.stringify(err),
    };
  }
}

async function getProjectTypes(token) {
  try {
    return await Projects.getProjectTypes(token);
  } catch (err) {
    return {
      errMsg: "Project Types list was not received! (" + err.message + ")",
    };
  }
}

export default function DefectByCategoryPage(props) {
  const { projectTypes, setProjectTypes } = useProjects();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [chartsInfo, setChartsInfo] = useState({});
  const [jiraItems, setJiraItems] = useState([]);
  const [selectedProjectType, setSelectedProjectType] = useState("");
  const [productValues, setProductValues] = useState([]);
  const [selectedProductValues, setSelectedProductValues] = useState([]);
  const [severityValues, setSeverityValues] = useState([]);
  const [selectedSeverityValues, setSelectedSeverityValues] = useState([]);
  const [resolutionValues, setResolutionValues] = useState([]);
  const [selectedResolutionValues, setSelectedResolutionValues] = useState([]);
  const [categoryValues, setCategoryValues] = useState([]);
  const [selectedCategoryValues, setSelectedCategoryValues] = useState([]);
  const [priorityValues, setPriorityValues] = useState([]);
  const [selectedPriorityValues, setSelectedPriorityValues] = useState([]);
  const [releaseFoundValues, setReleaseFoundValues] = useState([]);
  const [selectedReleaseFoundValues, setSelectedReleaseFoundValues] = useState(
    []
  );
  const [statusValues, setStatusValues] = useState([]);
  const [selectedStatusValues, setSelectedStatusValues] = useState([]);
  const [discoveredByValues, setDiscoveredByValues] = useState([]);
  const [selectedDiscoverByValues, setSelectedDiscoveredByValues] = useState(
    []
  );
  const [escapedValues, setEscapedValues] = useState([]);
  const [selectedEscapedValues, setSelectedEscapedValues] = useState([]);
  const [safeBusinessPriorityValues, setSafeBusinessPriorityValues] = useState(
    []
  );
  const [
    selectedSafeBusinessPriorityValues,
    setSelectedSafeBusinessPriorityValues,
  ] = useState([]);
  const [safeTeamsValues, setSafeTeamsValues] = useState([]);
  const [selectedSafeTeamsValues, setSelectedSafeTeamsValues] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedFiltersList, setSelectedFiltersList] = useState({
    selectedDates,
    selectedProductValues,
    selectedSeverityValues,
    selectedResolutionValues,
    selectedCategoryValues,
    selectedPriorityValues,
    selectedReleaseFoundValues,
    selectedStatusValues,
    selectedDiscoverByValues,
    selectedEscapedValues,
    selectedSafeBusinessPriorityValues,
    selectedSafeTeamsValues,
  });
  const [ableToEdit, setAbleToEdit] = useState(false);
  const [ableToCalculate, setAbleToCalculate] = useState(false);

  let localProps = {};
  Object.assign(localProps, props);
  localProps.style = "multy";
  localProps.styleValue = { height: "auto" };
  localProps.notAll = true;

  useEffect(() => {
    const handleProjectTypes = async (e) => {
      if (!projectTypes || Object.entries(projectTypes).length === 0) {
        const response = await getProjectTypes(props.token);
        if (response.message) {
          setErrorMessage(response.message);
          setProjectTypes([]);
        } else {
          if (!response || response.length < 1) {
            setErrorMessage("Projects types list is empty!");
          }
          setProjectTypes(response);
        }
      }
    };

    props.changeStyle("defectByCategory");

    handleProjectTypes();
  }, [projectTypes]);

  const handleProjectTypeChange = async (prjTypeObj) => {
    let projectTypeName = prjTypeObj[0].projectTypeName;
    setLoading(true);
    setAbleToCalculate(false);
    setAbleToEdit(false);
    setSelectedProjectType([]);
    setProductValues([]);
    setSeverityValues([]);
    setResolutionValues([]);
    setCategoryValues([]);
    setPriorityValues([]);
    setReleaseFoundValues([]);
    setStatusValues([]);
    setDiscoveredByValues([]);
    setEscapedValues([]);
    setSafeBusinessPriorityValues([]);
    setSafeTeamsValues([]);
    setSelectedProductValues([]);
    setSelectedSeverityValues([]);
    setSelectedResolutionValues([]);
    setSelectedCategoryValues([]);
    setSelectedPriorityValues([]);
    setSelectedReleaseFoundValues([]);
    setSelectedStatusValues([]);
    setSelectedDiscoveredByValues([]);
    setSelectedEscapedValues([]);
    setSelectedSafeBusinessPriorityValues([]);
    setSelectedSafeTeamsValues([]);

    if (!chartsInfo[projectTypeName]) {
      let result = await getJiraCustomInfo(props.token, projectTypeName);

      if (result.statusCode === 500) {
        setErrorMessage(result.errMsg);
        setLoading(false);
      } else {
        chartsInfo[projectTypeName] = result;
        transformChartInfo(result.data);
        setChartsInfo(chartsInfo);
        setJiraItems(result.data);
        setLoading(false);
      }
    }
  };

  const transformChartInfo = (data) => {
    const datesWithHours = Object.keys(data.trendingIssueMap);
    let availableDates = [];
    if (datesWithHours.length > 1) {
      availableDates = datesWithHours.map((element) => {
        let splitElement = element.split(" ");
        return splitElement[0];
      });
      availableDates = availableDates.sort();
    }

    let products = data.availableProducts.filter((product) => product !== null);
    let severities = data.availableSeverities.filter(
      (severity) => severity !== null
    );
    let resolutions = data.availableResolutions.filter(
      (resolution) => resolution !== null
    );
    let categories = data.availableCategories.filter(
      (category) => category !== null
    );
    let priorities = data.availablePriorities.filter(
      (priority) => priority !== null
    );
    let releaseFounds = data.availableTargetReleases.filter(
      (release) => release !== null && release !== ""
    );
    let statuses = data.availableStatuses.filter((status) => status !== null);
    let discoveredBys = data.availableDiscoveredBys.filter(
      (discoveredBy) => discoveredBy !== null
    );
    let escapeds = data.availableEscapedDefectOptions.filter(
      (escaped) => escaped !== null
    );
    let safeBusinessPriority = data.availableSafeBusinessPriorities.filter(
      (safebusinesspriority) => safebusinesspriority !== null
    );
    let safeTeam = data.availableSafeTeams.filter(
      (safeteam) => safeteam !== null
    );

    let datesOptions = availableDates.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let productOptions = products.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let severitiesOptions = severities.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let resolutionOptions = resolutions.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let categoriesOptions = categories.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let prioritiesOptions = priorities.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let releaseFoundsOptions = releaseFounds.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let statusesOptions = statuses.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let discoveredBysOptions = discoveredBys.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let escapedOptions = escapeds.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let safeBusinessPriorityOptions = safeBusinessPriority.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let safeTeamOptions = safeTeam.map((item) => {
      return {
        label: item,
        value: item,
      };
    });

    setDates(datesOptions);
    setProductValues(productOptions);
    setSeverityValues(severitiesOptions);
    setResolutionValues(resolutionOptions);
    setCategoryValues(categoriesOptions);
    setPriorityValues(prioritiesOptions);
    setReleaseFoundValues(releaseFoundsOptions);
    setStatusValues(statusesOptions);
    setDiscoveredByValues(discoveredBysOptions);
    setEscapedValues(escapedOptions);
    setSafeBusinessPriorityValues(safeBusinessPriorityOptions);
    setSafeTeamsValues(safeTeamOptions);
  };

  const editFilters = () => {
    setAbleToCalculate(false);
    setAbleToEdit(false);
    setSelectedFiltersList({
      selectedDates: [],
      selectedProductValues,
      selectedSeverityValues,
      selectedResolutionValues,
      selectedCategoryValues,
      selectedPriorityValues,
      selectedReleaseFoundValues,
      selectedStatusValues,
      selectedDiscoverByValues,
      selectedEscapedValues,
      selectedSafeBusinessPriorityValues,
      selectedSafeTeamsValues,
    });
  };

  const sendInformationTochart = () => {
    setAbleToCalculate(true);
    setAbleToEdit(true);
    setSelectedFiltersList({
      selectedDates,
      selectedProductValues,
      selectedSeverityValues,
      selectedResolutionValues,
      selectedCategoryValues,
      selectedPriorityValues,
      selectedReleaseFoundValues,
      selectedStatusValues,
      selectedDiscoverByValues,
      selectedEscapedValues,
      selectedSafeBusinessPriorityValues,
      selectedSafeTeamsValues,
    });
  };

  return (
    <div id="jira_custom_main_container">
      <div
        id="jira_custom_main_container_1"
        className="bg-light container-fluid pt-3 pb-3"
        style={
          loading
            ? {
                pointerEvents: "none",
                opacity: "0.4",
              }
            : {}
        }
      >
        <h4 className="ms-4">Defect By Category</h4>
        <ErrorMessage errorMessage={errorMessage} />
        <div id="" className="container-fluid ">
          <div className="row ms-1 me-1 border pb-3 border-ligth border-1 rounded p-3">
            <div className="col col-sm-2 border-end border-ligth  mt-3">
              <label htmlFor="projectTypes" className="form-label fs-7 ">
                Program
              </label>
              <div id="new" style={{ height: "40px" }} className="me-3">
                <ProjectTypes
                  id="projectTypes"
                  props={localProps}
                  handleChange={handleProjectTypeChange}
                  placeholder="Select..."
                />
              </div>
            </div>
            <div className="col-sm-10">
              <div className="row ms-3">
                <div className="col-sm-4">
                  <label htmlFor="dates" className="form-label fs-7 mt-3">
                    Dates
                  </label>

                  <MultiSelect
                    id="dates"
                    options={dates}
                    placeholder="Select date"
                    onChange={setSelectedDates}
                    value={selectedDates}
                    disabled={ableToEdit}
                  />
                </div>
                <div className="col-sm-4">
                  <label htmlFor="product" className="form-label fs-7 mt-3">
                    Product
                  </label>
                  <MultiSelect
                    id="product"
                    options={productValues}
                    placeholder="Select Product"
                    onChange={setSelectedProductValues}
                    value={selectedProductValues}
                    disabled={ableToEdit}
                  />
                </div>
                <div className="col-sm-4 mt-3">
                  <label htmlFor="severity" className="form-label fs-7 ">
                    Severity
                  </label>
                  <MultiSelect
                    id="severity"
                    options={severityValues}
                    placeholder="Select Severity"
                    onChange={setSelectedSeverityValues}
                    value={selectedSeverityValues}
                    disabled={ableToEdit}
                  />
                </div>
              </div>
              <div className="row ms-3">
                <div className="col-sm-4 mt-3">
                  <label htmlFor="resolution" className="form-label fs-7 ">
                    Resolution
                  </label>
                  <MultiSelect
                    id="resolution"
                    options={resolutionValues}
                    placeholder="Select Resolution"
                    onChange={setSelectedResolutionValues}
                    value={selectedResolutionValues}
                    disabled={ableToEdit}
                  />
                </div>
                <div className="col-sm-4 mt-3">
                  <label htmlFor="category" className="form-label fs-7 ">
                    Category
                  </label>
                  <MultiSelect
                    id="category"
                    options={categoryValues}
                    placeholder="Select Category"
                    onChange={setSelectedCategoryValues}
                    value={selectedCategoryValues}
                    disabled={ableToEdit}
                  />
                </div>
                <div className="col-sm-4 mt-3">
                  <label htmlFor="priority" className="form-label fs-7 ">
                    Priority
                  </label>
                  <MultiSelect
                    id="priority"
                    options={priorityValues}
                    placeholder="Select Priority"
                    onChange={setSelectedPriorityValues}
                    value={selectedPriorityValues}
                    disabled={ableToEdit}
                  />
                </div>
                <div className="col-sm-4 mt-3">
                  <label htmlFor="releaseFound" className="form-label fs-7 ">
                    Release Found
                  </label>
                  <MultiSelect
                    id="releaseFound"
                    options={releaseFoundValues}
                    placeholder="Select Release Found"
                    onChange={setSelectedReleaseFoundValues}
                    value={selectedReleaseFoundValues}
                  />
                </div>
                <div className="col-sm-4 mt-3">
                  <label htmlFor="status" className="form-label fs-7 ">
                    Status
                  </label>
                  <MultiSelect
                    id="status"
                    options={statusValues}
                    placeholder="Select Status"
                    onChange={setSelectedStatusValues}
                    value={selectedStatusValues}
                  />
                </div>
                <div className="col-sm-4 mt-3">
                  <label htmlFor="discoveredBy" className="form-label fs-7 ">
                    Discovered By
                  </label>
                  <MultiSelect
                    id="discoveredBy"
                    options={discoveredByValues}
                    placeholder="Select Discovered By"
                    onChange={setSelectedDiscoveredByValues}
                    value={selectedDiscoverByValues}
                  />
                </div>
                <div className="col-sm-4 mt-3">
                  <label htmlFor="escaped" className="form-label fs-7 ">
                    Escaped
                  </label>
                  <MultiSelect
                    id="escaped"
                    options={escapedValues}
                    placeholder="Select Escaped"
                    onChange={setSelectedEscapedValues}
                    value={selectedEscapedValues}
                  />
                </div>
                <div className="col-sm-4 mt-3">
                  <label
                    htmlFor="safebusinesspriority"
                    className="form-label fs-7 "
                  >
                    Safe Business Priority
                  </label>
                  <MultiSelect
                    id="safebusinesspriority"
                    options={safeBusinessPriorityValues}
                    placeholder="Select Safe Business Priority"
                    onChange={setSelectedSafeBusinessPriorityValues}
                    value={selectedSafeBusinessPriorityValues}
                    disabled={ableToEdit}
                  />
                </div>
                <div className="col-sm-4 mt-3">
                  <label htmlFor="safeTeam" className="form-label fs-7 ">
                    Safe Team
                  </label>
                  <MultiSelect
                    id="safeteam"
                    options={safeTeamsValues}
                    placeholder="Select Safe Team"
                    onChange={setSelectedSafeTeamsValues}
                    value={selectedSafeTeamsValues}
                    disabled={ableToEdit}
                  />
                </div>
              </div>
              <div className="row ms-3 ">
                <div className="col mt-5 d-flex justify-content-end gap-3">
                  <Button
                    variant="outlined"
                    disabled={!ableToCalculate}
                    onClick={() => {
                      editFilters();
                    }}
                  >
                    Edit filters
                  </Button>
                  <Button
                    variant="contained"
                    disabled={ableToCalculate}
                    onClick={() => {
                      sendInformationTochart();
                    }}
                  >
                    Calculate
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="row ms-3 me-3 mb-4 mt-3 justify-content-around">
            {loading ? (
              <Loading />
            ) : (
              <div id="defect-by-category-chart" className="col">
                <div id="defect-by-category-chart-root">
                  <DefectByCategoryChart
                    selectedFiltersList={selectedFiltersList}
                    backLogItems={jiraItems}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
