import useJiraInfo from "../../hooks/useJiraInfo";
import { useEffect, useRef } from "react";
import { Chart } from "react-google-charts";

const getTooltip = function (statuses, date, name, values) {
  var tooltip =
    '<div align="left" style="width: auto; float: left; table-layout: auto">' +
    '<table class="table table-hover table-condensed table-sm" >' +
    "<thead><tr><th>" +
    date +
    "</th></tr></thead>" +
    "<tbody>";

  let index = 0;
  for (let statValue of statuses) {
    if (statValue === name) {
      tooltip =
        tooltip +
        '<tr class="table-danger" key={' +
        index +
        "}><td><b>" +
        name +
        "</b>";
    } else {
      tooltip = tooltip + "<tr  key={" + index + "}><td>" + statValue;
    }
    tooltip =
      tooltip +
      ": <b>" +
      Math.abs(values[statValue]?.length ? values[statValue].length : 0) +
      "</b></td></tr>";
    index++;
  }
  tooltip = tooltip + "</tbody></table></div>";

  return tooltip;
};

export default function DefectsvsReleaseItemsChart(props) {
  const { currentStateDefectsVsRelease, setCurrentStateDefectsVsRelease } =
    useJiraInfo();
  var stateRadioList = ["All", "Open", "Closed", "Done"];

  const severities =
    props.projectTestSource === "ADO"
      ? ["1 - Critical", "2 - High", "3 - Medium", "4 - Low", "Not Defined"]
      : ["Sev 1-Severe", "Sev 2-High", "Sev 3-Medium", "Sev 4-Low"];
  const selectedStateValue = useRef();

  useEffect(() => {
    selectedStateValue.current = currentStateDefectsVsRelease;
  }, [currentStateDefectsVsRelease]);

  var defectTitles = ["Issue ID", "Issue Summary", "Status"];

  const currentIterationInfo =
    props.isADO === "ADO"
      ? props.jiraChartsInfo?.defectCurrentReleaseInfo?.data
          ?.issueByCurrentRelMap
      : props.jiraChartsInfo?.defectCurrentReleaseInfo?.issueByCurrentRelMap;

  let issueMap = {};

  if (props.isADO === "ADO") {
    issueMap = props?.jiraChartsInfo?.defectCurrentReleaseInfo?.data?.issueMap;
  } else {
    issueMap = props?.jiraChartsInfo?.defectCurrentReleaseInfo?.issueMap;
  }

  var dataTable = {};
  let chartCount =
    props.isADO === "ADO"
      ? props.jiraChartsInfo?.defectCurrentReleaseInfo?.data
      : props.jiraChartsInfo?.defectCurrentReleaseInfo;
  var chartTotal = {
    All: chartCount?.totalIssues || 0,
    Open: chartCount?.openIssues || 0,
    Closed: chartCount?.closeIssues || 0,
  };

  let arrayErrorDataEmpty = ["Target Release", 0, "", 0, "", 0, "", 0, ""];
  var emptyDataArray = [];
  emptyDataArray.push("Target Release");
  severities.forEach(() => {
    emptyDataArray.push(0);
    emptyDataArray.push("");
  });

  for (var state of stateRadioList) {
    dataTable[state] = [];
    let headerArr = [];
    headerArr.push("Target Release");

    for (let sev of severities) {
      headerArr.push(sev);
      headerArr.push({
        type: "string",
        role: "tooltip",
        p: { html: true },
      });
    }
    dataTable[state].push(headerArr);
  }

  for (let state in currentIterationInfo) {
    let data = currentIterationInfo[state];
    if (!data) {
      dataTable[state].push(emptyDataArray);
      continue;
    }

    for (let targetRelease in data) {
      var tempMap = data[targetRelease];
      var dataArray = [];
      dataArray.push(targetRelease);

      for (let sev of severities) {
        dataArray.push(tempMap[sev]?.length ? tempMap[sev].length : 0);
        var tooltip = getTooltip(severities, targetRelease, sev, tempMap);

        dataArray.push(tooltip);
      }
      dataTable[state].push(dataArray);
    }
  }

  //check if some state data is misssed and add empty data array
  for (let i in stateRadioList) {
    let state = stateRadioList[i];
    if (currentIterationInfo && !currentIterationInfo[state]) {
      dataTable[state].push(emptyDataArray);
    }
  }

  var options = {
    title:
      "Defects created against the Current Release (Total: " +
      chartTotal[currentStateDefectsVsRelease] +
      ")",
    titleTextStyle: { fontSize: "16" },
    bars: "vertical",
    isStacked: true,
    hAxis: { slantedText: true, textStyle: { fontSize: 11 } },
    vAxis: { format: "0" },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    tooltip: { isHtml: true },
    colors: ["red", "#ffbf00", "blue", "green", "violet"],
  };

  var chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function () {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row;
          var col = selection[0].column;

          var selectedSeverity = dataTable[selectedStateValue.current][0][col];
          var selectedRelease =
            dataTable[selectedStateValue.current][row + 1][0];

          var defectsIdsArray = currentIterationInfo[
            selectedStateValue.current
          ][selectedRelease]?.[selectedSeverity]
            ? currentIterationInfo[selectedStateValue.current][selectedRelease][
                selectedSeverity
              ]
            : [];
          var defectsInfoArray = [];
          Object.keys(issueMap).forEach((issueId) => {
            if (defectsIdsArray?.indexOf(issueId) >= 0) {
              defectsInfoArray.push(issueMap[issueId]);
            }
          });

          props.setModalData({
            title: selectedRelease + ": " + selectedSeverity,
            projectId: props.currentProjectId,
            createdDt: null,
            releaseName: selectedRelease,
            status: selectedStateValue.current,
            severity: selectedSeverity,
            tableTitles: defectTitles,
            defects: defectsInfoArray,
          });
          if (defectsInfoArray.length < 1) {
            props.setShowModal(false);
          } else {
            props.setShowModal(true);
          }
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          () => handler()
        );
      },
    },
  ];

  var handleStateChange = async (event) => {
    setCurrentStateDefectsVsRelease(event.target.value);
  };

  if (!props.jiraChartsInfo) {
    Object.keys(dataTable).forEach((key) => {
      dataTable[key].push(arrayErrorDataEmpty);
    });
  }

  return (
    <div>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={dataTable[currentStateDefectsVsRelease]}
        options={options}
        chartEvents={chartEvents}
      />
      <div
        id="03"
        role="group"
        className="btn-group ms-4"
        style={{ display: "flex", alignItems: "baseline" }}
      >
        <input
          type="radio"
          id="All"
          name="options"
          className="mb-4"
          value="All"
          autoComplete="off"
          checked={currentStateDefectsVsRelease === "All"}
          onChange={handleStateChange}
        />
        <label htmlFor="All" className="mb-4" style={{ paddingLeft: "5px" }}>
          All
        </label>
        <input
          type="radio"
          id="Open"
          value="Open"
          name="options"
          autoComplete="off"
          className="ms-4 "
          checked={currentStateDefectsVsRelease === "Open"}
          onChange={handleStateChange}
        />
        <label htmlFor="Open" className="mb-4" style={{ paddingLeft: "5px" }}>
          Open
        </label>
        <input
          type="radio"
          id="Closed"
          value="Closed"
          name="options"
          autoComplete="off"
          className="ms-4 "
          checked={currentStateDefectsVsRelease === "Closed"}
          onChange={handleStateChange}
        />
        <label htmlFor="Closed" className="mb-4" style={{ paddingLeft: "5px" }}>
          Closed/Done
        </label>
      </div>
    </div>
  );
}
