import LoginForm from "./components/login/LoginForm";
import { useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./NavigationClient";
import { AppProvider } from "./store/AppProvider";

function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <AppProvider>
      <MsalProvider instance={pca}>
        <LoginForm />
      </MsalProvider>
    </AppProvider>
  );
}
export default App;
