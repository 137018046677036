import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ProgramsTab = ({ programs, departments, addEditProgram, setAddEditProgram, program, setProgram, createUpdateProgram, userRole }) => {
  return (
    <div style={{ width: '1158px' }}>
      <div style={{ padding: '1rem 2rem' }}>
        <Button label="Add Program" icon="pi pi-plus" className="p-button-raised p-button-rounded p-button-success" onClick={() => setAddEditProgram(true)} />
      </div>
      <div className="table-card">
        <DataTable value={programs} style={{ minWidth: '40rem' }}>
          <Column field="name" header="Program" style={{ minWidth: '270px' }} />
          <Column field="dept" header="Department" style={{ minWidth: '270px' }} />
          <Column body={(rowData) => (
            <>
              <Button icon="pi pi-pencil" disabled={userRole !== 'admin'} />
              &nbsp;
              <Button icon="pi pi-trash" disabled={userRole !== 'admin'} />
            </>
          )} header="Action" />
        </DataTable>
      </div>
      <Dialog header="Add or Edit Program" visible={addEditProgram} modal style={{ width: '40vw' }} onHide={() => setAddEditProgram(false)}>
        <div className="flex flex-column gap-4">
          <label htmlFor="programName">Program Name : </label>
          <InputText style={{ width: '100%' }} id="programName" value={program.name} onChange={(e) => setProgram({ ...program, name: e.target.value })} />
        </div>
        <div className="flex flex-column gap-4" style={{ marginTop: '15px', marginBottom: '50px' }}>
          <label htmlFor="department">Department : </label>
          <Dropdown style={{ width: '100%' }} options={departments} value={program.departmentName} onChange={(e) => setProgram({ ...program, departmentName: e.value })} optionLabel="name" optionValue="name" placeholder="Select a Department" />
        </div>
        <div className="p-dialog-footer">
          <Button label="Cancel" className="p-button-secondary" onClick={() => setAddEditProgram(false)} />
          <Button label="Save" className="p-button-secondary" onClick={createUpdateProgram} />
        </div>
      </Dialog>
    </div>
  );
};

export default ProgramsTab;
