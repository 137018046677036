import React, { useRef } from "react";
import { Chart } from "react-google-charts";
import "../../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

const getTooltip = function (statuses, date, name, values) {
  var tooltip =
    '<div align="left" style="width: auto; float: left; table-layout: auto">' +
    '<table class="table table-hover table-condensed table-sm" >' +
    "<thead><tr><th>" +
    date +
    "</th></tr></thead>" +
    "<tbody>";

  let index = 0;
  for (let statValue of statuses) {
    if (statValue === name) {
      tooltip =
        tooltip +
        '<tr class="table-danger" key={' +
        index +
        "}><td><b>" +
        name +
        "</b>";
    } else {
      tooltip = tooltip + "<tr  key={" + index + "}><td>" + statValue;
    }
    tooltip =
      tooltip +
      ": <b>" +
      Math.abs(getValue(values[statValue])) +
      "</b></td></tr>";
    index++;
  }
  tooltip = tooltip + "</tbody></table></div>";

  return tooltip;
};

const getValue = (value) => {
  return value ? value.length : 0;
};

export default function OpenDefectsTrendingChart(props) {
  var severities =
    props.isADO === "ADO"
      ? [
          "All",
          "1 - Critical",
          "2 - High",
          "3 - Medium",
          "4 - Low",
          "Not Defined",
        ]
      : ["All", "Sev 1-Severe", "Sev 2-High", "Sev 3-Medium", "Sev 4-Low"];
  var defectTitles = [
    "Issue ID",
    "Issue Summary",
    "Status",
    "Resolution",
    "Severity",
  ];

  const currentIterationInfoRef = useRef();
  const currentDataTableRef = useRef();
  const currentIssueMapRef = useRef();

  let col_max = 0;

  currentIssueMapRef.current = props.jiraSteercoDefectsInfo?.issueMap
    ? props.jiraSteercoDefectsInfo.issueMap
    : {};
  const notSortedInfo = props?.jiraSteercoDefectsInfo
    ?.steerCoOpenClosedDefectsBySev
    ? props?.jiraSteercoDefectsInfo?.steerCoOpenClosedDefectsBySev[
        props.currentIteration
      ]
    : null;
  currentIterationInfoRef.current = notSortedInfo;

  var dataTable = [];
  //prepare header for the chart
  var header = ["Dates"];
  for (var i = 0; i < severities.length; i++) {
    header.push(severities[i]);
    header.push({ type: "string", role: "tooltip", p: { html: true } });
  }
  dataTable.push(header);

  let valEmptyArray =
    props.isADO === "ADO"
      ? ["Dates", 0, "", 0, "", 0, "", 0, "", 0, "", 0, ""]
      : ["Dates", 0, "", 0, "", 0, "", 0, "", 0, ""];

  let currentIterationInfo = {};
  //sort by dates
  if (notSortedInfo) {
    Object.keys(notSortedInfo)
      .sort(function (a, b) {
        return new Date(a).getTime() - new Date(b).getTime();
      })
      .forEach(function (key) {
        currentIterationInfo[key] = notSortedInfo[key];
      });
  }

  const datesArr = Object.keys(currentIterationInfo);
  var isStatEmpty = true;
  for (let date of datesArr) {
    isStatEmpty = false;
    var tempStatObj = currentIterationInfo[date]["Open"];

    let currentDateObjArray = [];
    currentDateObjArray.push(date);

    for (let severity of severities) {
      if (!tempStatObj[severity]) {
        tempStatObj[severity] = 0;
      }

      if (col_max < getValue(tempStatObj[severity])) {
        col_max = getValue(tempStatObj[severity]);
      }

      currentDateObjArray.push(getValue(tempStatObj[severity]));
      currentDateObjArray.push(
        getTooltip(severities, date, severity, tempStatObj)
      );
    }

    dataTable.push(currentDateObjArray);
  }

  // set default value if no results received from server
  if (isStatEmpty) {
    dataTable.push(valEmptyArray);
  }

  var data = dataTable;
  currentDataTableRef.current = data;

  const colorHeaderArray = props.isADO
    ? ["black", "red", "#ffbf00", "blue", "green", "violet"]
    : ["black", "red", "#ffbf00", "blue", "green"];

  var options = {
    title: "Open Defects Trending",
    titleTextStyle: { fontSize: "16" },
    hAxis: {
      slantedText: true,
      slantedTextAngle: 45,
      textStyle: { fontSize: 11 },
    },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    tooltip: { isHtml: true },
    gridlines: { count: -1 },
    vAxis: { format: "#" },
    colors: colorHeaderArray,
  };

  if (col_max < 5) {
    options.vAxis = { format: "#", viewWindow: { max: 4 } };
  } else {
    options.vAxis = { format: "#", viewWindow: { max: col_max + 1 } };
  }

  var chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function (e) {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row;
          var col = selection[0].column;

          var selectedSeverity = currentDataTableRef.current[0][col];
          var selectedDate = currentDataTableRef.current[row + 1][0];

          var defectsIdsArray = currentIterationInfoRef.current?.[selectedDate][
            "Open"
          ]?.[selectedSeverity]
            ? currentIterationInfoRef.current[selectedDate]["Open"][
                selectedSeverity
              ]
            : [];
          var defectsInfoArray = [];
          Object.keys(currentIssueMapRef.current).forEach((issueId) => {
            if (defectsIdsArray?.indexOf(issueId) >= 0) {
              defectsInfoArray.push(currentIssueMapRef.current[issueId]);
            }
          });

          props.setModalData({
            title: selectedDate + ": " + selectedSeverity,
            projectId: props.currentProjectId,
            createdDt: selectedDate,
            releaseName: null,
            status: "Open",
            severity: selectedSeverity,
            tableTitles: defectTitles,
            defects: defectsInfoArray,
          });
          if (defectsInfoArray.length < 1) {
            props.setShowModal(false);
          } else {
            props.setShowModal(true);
          }
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          handler
        );
      },
    },
  ];

  if (props.event) {
    chartEvents.push({
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        let url = chartWrapper.getChart().getImageURI();
        props.setOpenDefectsTrendingChartUri(url);
        props.setOpenDefectsTrendingChartDisplay(false);
      },
    });
  }

  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
      chartEvents={chartEvents}
    />
  );
}
