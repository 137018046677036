import { useState } from "react";
import { useAppContext } from "../../store/AppProvider";

export default function useProjectDetails() {
  const {
    currentProjectDetails,
    currentProjectReportInfo,
    currentProjectId: currentProjectIdStore,
    addNewProjectFlag: addNewProjectFlagStore,
    dispatch,
  } = useAppContext();

  const getProjectDetails = () => {
    if (
      currentProjectDetails === "undefined" ||
      currentProjectDetails === "{}"
    ) {
      return null;
    }
    return currentProjectDetails;
  };

  const [projectDetails, setProjectDetails] = useState(getProjectDetails());

  const saveProjectDetails = (projectDetails) => {
    dispatch({
      type: "EDIT_CURRENT_PROJECT_DETAILS",
      value: projectDetails,
    });
    setProjectDetails(projectDetails);
  };

  const getProjectReportInfo = () => {
    if (
      currentProjectReportInfo === "undefined" ||
      currentProjectReportInfo === "{}"
    ) {
      return null;
    }
    //const projectReportInfo = JSON.parse(projectReportInfoStr);
    return currentProjectReportInfo;
  };

  const [projectReportInfo, setProjectReportInfo] = useState(
    getProjectReportInfo()
  );

  const saveProjectReportInfo = (projectReportInfo) => {
    dispatch({
      type: "EDIT_CURRENT_PROJECT_REPORT_INFO",
      value: projectReportInfo,
    });
    setProjectReportInfo(projectReportInfo);
  };

  const getCurrentProjectId = () => {
    if (
      currentProjectIdStore === "undefined" ||
      currentProjectIdStore === "{}"
    ) {
      return null;
    }
    //const projectId = JSON.parse(projectIdStr);
    return currentProjectIdStore;
  };

  const [currentProjectId, setCurrentProjectId] = useState(
    getCurrentProjectId()
  );

  const saveCurrentProjectId = (projectDetails) => {
    dispatch({
      type: "EDIT_CURRENT_PROJECT_ID",
      value: projectDetails,
    });
    setCurrentProjectId(projectDetails);
  };

  const getAddNewProjectFlag = () => {
    if (
      addNewProjectFlagStore === "undefined" ||
      addNewProjectFlagStore === "{}"
    ) {
      return null;
    }
    //const flag = JSON.parse(str);
    return addNewProjectFlagStore;
  };

  const [addNewProjectFlag, setAddNewProjectFlag] = useState(
    getAddNewProjectFlag()
  );

  const saveAddNewProjectFlag = (flag) => {
    dispatch({
      type: "EDIT_ADD_NEW_PROJECT_FLAG",
      value: flag,
    });
    setAddNewProjectFlag(flag);
  };

  return {
    setProjectDetails: saveProjectDetails,
    projectDetails,
    setCurrentProjectId: saveCurrentProjectId,
    currentProjectId,
    setAddNewProjectFlag: saveAddNewProjectFlag,
    addNewProjectFlag,
    setProjectReportInfo: saveProjectReportInfo,
    projectReportInfo,
  };
}
