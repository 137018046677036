import React, { useEffect } from "react";
import useUserInfo from "../hooks/useUserInfo";
import "../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../css/custom.min.css";

function ButtonsBar({
  props,
  setDisabled,
  prepareEditObj,
  clearEditProjectDetails,
  handleSubmit,
  isActiveList,
  isNewProject,
  setIsActiveList,
  projectDateError,
  saveIsNotAllowed,
  editMode,
  setEditMode,
  currentProjectType,
}) {
  const { loggedUser } = useUserInfo({});

  useEffect(() => {
    if (editMode) {
      if (saveIsNotAllowed) {
        setIsActiveList({ edit: true, save: true, cancel: "" });
        setDisabled(false);
      } else {
        setIsActiveList({ edit: true, save: "", cancel: "" });
        setDisabled(false);
      }
    } else {
      setIsActiveList({ edit: "", save: true, cancel: true });
      setDisabled(true);
    }
  }, [saveIsNotAllowed]);

  useEffect(() => {
    let projectValueId = JSON.parse(
      localStorage.getItem("selectedProjectTypeId")
    );
    let valueToEvaluate =
      projectValueId === "All"
        ? currentProjectType?.projectTypeId
        : projectValueId;

    if (isNewProject) {
      setIsActiveList({ edit: "disabled", save: "", cancel: "" });
    } else if (
      loggedUser.projectTypes?.length < 0 ||
      loggedUser.projectTypes.indexOf(Number(valueToEvaluate)) < 0
    ) {
      setIsActiveList({
        edit: "",
        save: "disabled",
        cancel: "disabled",
      });
    }
  }, []);

  const handleEditClick = () => {
    setEditMode(true);
    setIsActiveList({ edit: true, save: "", cancel: "" });
    setDisabled(false);
    prepareEditObj();
    props.clearErrorMessage("");
    props.clearResultMessage();
  };

  const handleSaveClick = () => {
    handleSubmit();
    setEditMode(false);
    setIsActiveList({ edit: "", save: true, cancel: true });
    setDisabled(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setIsActiveList({ edit: "", save: true, cancel: true });
    setDisabled(true);
    clearEditProjectDetails();
    props.clearResultMessage();
    refreshPage();
  };

  const refreshPage = () => {
    if (isNewProject) {
      return window.location.assign("/");
    }

    window.location.reload();
  };

  const handleDeleteClick = () => {};

  return (
    <div className="row mb-4">
      <div className="col-md-6">
        <button
          className="btn  btn-primary  btn-block ms-0 "
          disabled={isActiveList["edit"]}
          type="button"
          onClick={() => handleEditClick()}
        >
          Edit
        </button>
        <button
          className="btn  btn-primary  btn-block ms-4"
          disabled={isActiveList["save"] || projectDateError}
          type="submit"
          onClick={() => handleSaveClick("details")}
        >
          Save
        </button>
        <button
          className="btn  btn-primary  btn-block ms-4"
          disabled={isActiveList["cancel"]}
          type="reset"
          onClick={() => handleCancelClick("details")}
        >
          Cancel
        </button>
      </div>
      <div className="col-md-2 ms-auto">
        <button
          className="btn  btn-danger  btn-block ms-0"
          style={{ display: "none" }}
          type="button"
          disabled
          onClick={() => handleDeleteClick("details")}
        >
          Delete
        </button>
      </div>
    </div>
  );
}

export default ButtonsBar;
