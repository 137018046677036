import React, { useEffect, useState } from "react";
import Jira from "../../services/Jira";
import ErrorMessage from "../common/ErrorMessage";
import Multiselect from "multiselect-react-dropdown";
import useProjectDetails from "../hooks/useProjectDetails";
import useJiraInfo from "../hooks/useJiraInfo";
import "../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../css/custom.min.css";

async function getJiraInfo(token) {
  try {
    return await Jira.getJiraInfo(token);
  } catch (err) {
    return {
      message:
        "Error getting Jira info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getJiraTargetReleaseFields(token, jiraProjectKey) {
  try {
    return await Jira.getTargetReleaseFields(token, jiraProjectKey);
  } catch (err) {
    return {
      message:
        "Error getting Target Release fields (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

function JiraInfo(props) {
  const { projectDetails } = useProjectDetails();
  const { jiraInfo, setJiraInfo } = useJiraInfo();
  const disabled = props.disabled ? true : false;
  const [jiraFields, setJiraFields] = useState();
  const [currentProjectType, setCurrentProjectType] = useState();
  const [releaseValues, setReleaseValues] = useState();
  const [selectedReleaseValues, setSelectedReleaseValues] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [populatedFields, setPopulatedFields] = useState({
    target: "",
    release: "",
    environment: "",
    product: "",
    default: "",
  });
  const [isAllPopulated, setIsAllPopulated] = useState(false);

  useEffect(() => {
    if (Object.values(populatedFields).some((elm) => elm !== "")) {
      props.props.setPopulatedJiraInfoFields(populatedFields);
    }
  }, [populatedFields]);

  useEffect(() => {
    handleJiraInfo();
  }, [disabled, currentProjectType]);

  const handleJiraInfo = async (e) => {
    if (
      (!jiraInfo || !jiraFields || Object.entries(jiraInfo).length === 0) &&
      !errorMessage
    ) {
      setLoading(true);
      setJiraInfo([]);
      setJiraFields([]);
      handleSetReleaseValues(null);
      //get List of all Jira projects
      const response = await getJiraInfo(props.token);
      if (response.message) {
        setErrorMessage(response.message);
        setJiraInfo([]);
        setLoading(false);
      } else {
        if (!response || response.length < 1) {
          setErrorMessage("Jira projects types list is empty!");
        } else {
          response.sort((a, b) =>
            a.projectName > b.projectName
              ? 1
              : b.projectName > a.projectName
              ? -1
              : 0
          );
          setJiraInfo(response);
          //get target releases
          //if the current project selected then get Jira fields for related Jira project
          let releasesResponse;
          setLoading(true);
          if (
            projectDetails?.lpJiraRelations &&
            projectDetails?.lpJiraRelations[0]?.projectKey
          ) {
            releasesResponse = await getJiraTargetReleaseFields(
              props.token,
              projectDetails.lpJiraRelations[0].projectKey
            );
          } else {
            //for the first project in the list
            releasesResponse = await getJiraTargetReleaseFields(
              props.token,
              response[0].projectKey
            );
          }
          setLoading(false);
          if (releasesResponse.message) {
            setErrorMessage(releasesResponse.message);
            setJiraFields([]);
          } else {
            releasesResponse.sort((a, b) =>
              a.fieldName > b.fieldName ? 1 : b.fieldName > a.fieldName ? -1 : 0
            );
            setJiraFields(releasesResponse);
            if (projectDetails?.lpJiraRelations?.[0]?.projectKey) {
              setJiraFields(releasesResponse);
              handleSetReleaseValues(
                releasesResponse,
                projectDetails.lpJiraRelations[0].targetReleaseFieldId
              );
              //check if any release selected
              if (
                projectDetails?.lpJiraRelations[0]?.targetReleaseValues
                  ?.length > 0
              ) {
                setSelectedReleaseValues(
                  projectDetails.lpJiraRelations[0].targetReleaseValues
                );
                setIsAllPopulated(true);
              } else {
                setIsAllPopulated(false);
                setSelectedReleaseValues([]);
              }
            } else {
              if (props.isNewProject && jiraInfo[0]) {
                let projectKey = jiraInfo[0].projectKey || "";
                props.props.handleDefaultProjectKey(projectKey);
              }
              handleSetReleaseValues(
                releasesResponse,
                releasesResponse[0].fieldId
              );
            }
          }
        }
      }
    }
  };

  const handleProjectChange = async (event) => {
    setCurrentProjectType(event.target.value);
    setJiraFields([]);
    setReleaseValues([]);
    setPopulatedFields({
      target: "",
      release: "",
      environment: "",
      product: "",
    });

    setErrorMessage();
    setIsAllPopulated(false);
    setLoading(true);

    props.props.saveJiraProjectChange(event);

    const response = await getJiraTargetReleaseFields(
      props.token,
      event.target.value
    );

    setLoading(false);
    if (response.message || !response.length) {
      setErrorMessage(response.message);
    } else {
      response.sort((a, b) =>
        a.fieldName > b.fieldName ? 1 : b.fieldName > a.fieldName ? -1 : 0
      );
      setJiraFields(response);

      let hasSafeTargetReleaseField = response.some((field) => {
        return field.fieldId === "customfield_13791";
      });

      hasSafeTargetReleaseField
        ? handleSetReleaseValues(response, "customfield_13791")
        : handleSetReleaseValues(response, response[0].fieldId);
      populateFields(response);
    }
  };

  const handleTargetReleaseChange = (event) => {
    handleSetReleaseValues(jiraFields, event.target.value);
    props.props.handleTargetReleaseChange(event);
  };

  const handleSetReleaseValues = (fieldsObj, selectedTargetReleaseId) => {
    setReleaseValues([]);
    setSelectedReleaseValues([]);

    let areValuesEmpty = true;

    if (fieldsObj) {
      for (let fieldObj of fieldsObj) {
        if (fieldObj.fieldId === selectedTargetReleaseId) {
          setReleaseValues(fieldObj.allowedValues);
          areValuesEmpty = fieldObj.allowedValues?.length > 0 ? false : true;
          break;
        }
      }
    }
    if (areValuesEmpty) {
      setReleaseValues([]);
      setIsAllPopulated(false);
    }
  };

  const checkSafeFields = (field, fieldType) => {
    if (
      !projectDetails?.lpJiraRelations ||
      projectDetails?.lpJiraRelations[0]?.releaseFoundFieldId !== undefined
    ) {
      return false;
    }

    const fields = {
      targetRelease: "customfield_13791",
      releaseFound: "customfield_13792",
      environment: "customfield_13793",
      product: "customfield_13790",
    };

    const fieldToSearch = fields[fieldType];
    const hasSafeField = field.fieldId === fieldToSearch;

    return hasSafeField;
  };

  const populateFields = async (response) => {
    const fields = {
      targetRelease: "customfield_13791",
      releaseFound: "customfield_13792",
      environment: "customfield_13793",
      product: "customfield_13790",
      default: "",
    };

    response.forEach((field) => {
      let fieldName = field.fieldId;

      if (fieldName === fields["targetRelease"]) {
        setPopulatedFields((prevFields) => ({
          ...prevFields,
          ["target"]: fieldName,
        }));
      } else if (fieldName === fields["releaseFound"]) {
        setPopulatedFields((prevFields) => ({
          ...prevFields,
          ["release"]: fieldName,
        }));
      } else if (fieldName === fields["environment"]) {
        setPopulatedFields((prevFields) => ({
          ...prevFields,
          ["environment"]: fieldName,
        }));
      } else if (fieldName === fields["product"]) {
        setPopulatedFields((prevFields) => ({
          ...prevFields,
          ["product"]: fieldName,
        }));
      } else {
        setPopulatedFields((prevFields) => ({
          ...prevFields,
          ["default"]: response[0].fieldId,
        }));
      }
    });
  };

  return (
    <div id="jira-tool-container">
      <ErrorMessage errorMessage={errorMessage} />
      <div className="row mb-4">
        <div className="col md-6">
          <label htmlFor="testReleases" className="form-label fs-7 fw-bold">
            <span style={isAllPopulated ? {} : { color: "red" }}>* </span>
            JIRA:
          </label>
          {loading && (
            <div
              className="spinner-border text-secondary spinner-border-sm"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
          <div
            className="col-6"
            style={
              loading
                ? {
                    pointerEvents: "none",
                    opacity: "0.4",
                  }
                : {}
            }
          >
            <ul className="list-group input-group-sm">
              <li className="list-group-item">
                <div className="input-group input-group-sm">
                  <span className="input-group-text" id="basic-addon3">
                    Jira Project:
                  </span>
                  <select
                    id="jiraProjects"
                    className="form-select"
                    aria-label="Select Jira Project"
                    value={
                      (projectDetails?.lpJiraRelations &&
                        projectDetails?.lpJiraRelations[0]?.projectKey) ||
                      ""
                    } // Controla el valor seleccionado
                    onChange={handleProjectChange}
                    disabled={!!props.disabled}
                  >
                    {jiraInfo &&
                      jiraInfo.map((jira) => {
                        return (
                          <option
                            value={jira.projectKey}
                            key={jira.projectKey}
                            selected={
                              jira.projectKey ===
                                projectDetails?.lpJiraRelations &&
                              projectDetails?.lpJiraRelations[0]?.projectKey
                                ? true
                                : false
                            }
                          >
                            {jira.projectName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </li>

              <li className="list-group-item">
                <div className="input-group input-group-sm ">
                  <span className="input-group-text" id="basic-addon3">
                    Target Release Field:
                  </span>
                  <select
                    id="jiraTargetreleaseField"
                    className="form-select"
                    aria-label="Select Jira Target Release Field"
                    defaultValue={
                      (projectDetails?.lpJiraRelations &&
                        projectDetails?.lpJiraRelations[0]
                          ?.targetReleaseFieldId) ||
                      ""
                    }
                    onChange={(e) => handleTargetReleaseChange(e)}
                    disabled={!!props.disabled}
                  >
                    {jiraFields &&
                      jiraFields.map((field) => {
                        return (
                          <option
                            value={field.fieldId}
                            key={field.fieldId}
                            selected={
                              (field.fieldId ===
                                projectDetails?.lpJiraRelations &&
                                projectDetails?.lpJiraRelations[0]
                                  ?.targetReleaseFieldId) ||
                              checkSafeFields(field, "targetRelease")
                            }
                          >
                            {field.fieldName}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="input-group input-group-sm ">
                  <span className="input-group-text" id="basic-addon3">
                    Releases:
                  </span>
                  {releaseValues && (
                    <Multiselect
                      id="jiraTargetreleaseValues"
                      showArrow
                      options={releaseValues}
                      showCheckbox={true}
                      onSelect={(values) => {
                        props.props.handleReleasesChange(values);
                        setSelectedReleaseValues(values);
                        setIsAllPopulated(true);
                      }}
                      onRemove={(values) => {
                        props.props.handleReleasesChange(values);
                        setSelectedReleaseValues(values);
                        setIsAllPopulated(false);
                      }}
                      placeholder="Select Jira Release(s)"
                      selectedValues={selectedReleaseValues}
                      keepSearchTerm={true}
                      hidePlaceholder={true}
                      avoidHighlightFirstOption={true}
                      isObject={false}
                      disable={!!props.disabled}
                      style={{
                        inputField: {
                          margin: "0px",
                          padding: "0px",
                          textDecoration: "none",
                        },
                        multiselectContainer: { margin: "0px", padding: "0px" },
                        searchBox: { margin: "0px", padding: "3px" },
                      }}
                    />
                  )}
                </div>
              </li>

              <li className="list-group-item">
                <div className="input-group input-group-sm ">
                  <span className="input-group-text" id="basic-addon3">
                    Release Found Field:
                  </span>
                  <select
                    id="jiraReleaseFoundField"
                    className="form-select"
                    aria-label="Select Release Found Field"
                    defaultValue={
                      (projectDetails?.lpJiraRelations &&
                        projectDetails?.lpJiraRelations[0]
                          ?.releaseFoundFieldId) ||
                      ""
                    }
                    onChange={props.props.handleReleaseFoundChange}
                    disabled={!!props.disabled}
                  >
                    {jiraFields &&
                      jiraFields.map((field) => {
                        return (
                          <option
                            value={field.fieldId}
                            key={field.fieldId}
                            selected={
                              field.fieldId ===
                                (projectDetails?.lpJiraRelations &&
                                  projectDetails?.lpJiraRelations[0]
                                    ?.releaseFoundFieldId) ||
                              checkSafeFields(field, "releaseFound")
                            }
                          >
                            {field.fieldName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </li>

              <li className="list-group-item">
                <div className="input-group input-group-sm">
                  <span className="input-group-text" id="basic-addon3">
                    Environment Field:
                  </span>
                  <select
                    id="jiraEnvironmentField"
                    className="form-select"
                    aria-label="Select Environment Field"
                    defaultValue={
                      (projectDetails?.lpJiraRelations &&
                        projectDetails?.lpJiraRelations[0]
                          ?.environmentFieldId) ||
                      ""
                    }
                    onChange={props.props.handleEnvironmentChange}
                    disabled={!!props.disabled}
                  >
                    {jiraFields &&
                      jiraFields.map((field) => {
                        return (
                          <option
                            value={field.fieldId}
                            key={field.fieldId}
                            selected={
                              field.fieldId ===
                                (projectDetails?.lpJiraRelations &&
                                  projectDetails?.lpJiraRelations[0]
                                    ?.environmentFieldId) ||
                              checkSafeFields(field, "environment")
                            }
                          >
                            {field.fieldName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </li>

              <li className="list-group-item">
                <div className="input-group input-group-sm">
                  <span className="input-group-text" id="basic-addon3">
                    Product Field:
                  </span>
                  <select
                    id="jiraProductField"
                    className="form-select"
                    aria-label="Select Product Field"
                    defaultValue={
                      (projectDetails?.lpJiraRelations &&
                        projectDetails?.lpJiraRelations[0]?.productFieldId +
                          "") ||
                      ""
                    }
                    onChange={props.props.handleProductChange}
                    disabled={!!props.disabled}
                  >
                    {jiraFields &&
                      jiraFields.map((field, index) => {
                        return (
                          <option
                            value={field.fieldId + ""}
                            key={index}
                            selected={
                              field.fieldId + "" ===
                                (projectDetails?.lpJiraRelations &&
                                  projectDetails?.lpJiraRelations[0]
                                    ?.productFieldId + "") ||
                              checkSafeFields(field, "product")
                            }
                          >
                            {field.fieldName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JiraInfo;
