import React, { useState, useRef } from "react";
import { Chart } from "react-google-charts";

const DefectsSeverityChart = (props) => {
  const data_from_props =
    props.isADO == "ADO"
      ? props.jiraChartsInfo?.defectAgeChartMap?.data?.sevStatMap
      : props.jiraChartsInfo?.sevStatMap;
  const [severityData, setSeverityData] = useState(data_from_props || {});

  const issueMap =
    props.isADO == "ADO"
      ? props.jiraChartsInfo?.defectAgeChartMap?.data?.issueMap
      : props?.jiraChartsInfo?.issueMap;
  const currentIterationInfoRef = useRef();
  const currentDataTableRef = useRef();

  const dataTable = [["Severity", "Quantity"]];
  let severityHeaders = Object.keys(severityData[props.currentIteration] || {});
  let severityQuantity = Object.values(
    severityData[props.currentIteration] || {}
  );
  currentIterationInfoRef.current = severityData[props.currentIteration];

  severityHeaders.forEach((severity, index) => {
    dataTable.push([severity, severityQuantity[index].length]);
  });

  currentDataTableRef.current = dataTable;

  const chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function () {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row + 1;
          let selectedSeverity = currentDataTableRef.current[row][0];
          var defectTitles = [
            "Issue ID",
            "Issue Summary",
            "Status",
            "Resolution",
            "Severity",
            "Assignee",
          ];
          var defectsIdsArray =
            currentIterationInfoRef.current?.[selectedSeverity];
          defectsIdsArray = defectsIdsArray ? defectsIdsArray : [];
          var defectsInfoArray = [];

          Object.keys(issueMap).forEach((issueId) => {
            if (defectsIdsArray.indexOf(issueId) >= 0) {
              defectsInfoArray.push(issueMap[issueId]);
            }
          });

          props.setModalData({
            title: `Defect Severity : ${currentDataTableRef.current[row][0]}`,
            projectId: props.currentProjectId,
            createdDt: null,
            releaseName: null,
            status: null,
            severity: currentDataTableRef.current[row][0],
            tableTitles: defectTitles,
            defects: defectsInfoArray,
          });

          if (defectsInfoArray.length < 1) {
            props.setShowModal(false);
          } else {
            props.setShowModal(true);
          }
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          () => handler()
        );
      },
    },
  ];

  const severitiesColors = {
    "Sev 1-Severe": "red",
    "Sev 2-High": "#ffbf00",
    "Sev 3-Medium": "blue",
    "Sev 4-Low": "green",
    "1 - Critical": "red",
    "2 - High": "#ffbf00",
    "3 - Medium": "blue",
    "4 - Low": "green",
  };

  const options = {
    title: "Defect Severity",
    titleTextStyle: { fontSize: "16" },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    is3D: true,
    slices: {
      0: { color: severitiesColors[severityHeaders[0]] },
      1: { color: severitiesColors[severityHeaders[1]] },
      2: { color: severitiesColors[severityHeaders[2]] },
      3: { color: severitiesColors[severityHeaders[3]] },
    },
  };

  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={dataTable}
      options={options}
      chartEvents={chartEvents}
    />
  );
};

export default DefectsSeverityChart;
