import React, { useState, useEffect } from "react";
import Projects from "../../services/Projects";
import Loading from "../common/Loading";
import ButtonsBar from "./ButtonsBar";
import ProjectInfo from "./ProjectInfo";
import TestManagementTool from "./TestManagementTool";
import ADODefectManagementTool from "./ADODefectManagementTool";
import ADOTestManagementTool from "./ADOTestManagementTool";
import ADOSprintsManagementTool from "./ADOSprintsManagementTool";
import ADO from "../../services/ADO";
import JiraInfo from "./JiraInfo";
import Milestones from "./Milestones";
import ReactTooltip from "react-tooltip";
import ErrorMessage from "../common/ErrorMessage";
import useUserInfo from "../hooks/useUserInfo";
import useEditProjectDetails from "../hooks/useEditProjectDetails";
import useErrorMessage from "../hooks/useErrorMessage";
import useProjectDetails from "../hooks/useProjectDetails";
import useProjects from "../hooks/useProjects";
import { useAppContext } from "../../contexts/AppContext";
import { useParams, useNavigate } from "react-router-dom";
import "../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../css/custom.min.css";
async function getProjectDetails(token, projectId, navigate) {
  try {
    const resp = await Projects.getProjectDetails(token, projectId);
    if (Array.isArray(resp.lpIterations)) {
    }
    if (resp.message) {
      navigate("/");
    }
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting project details (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function submitDetails(token, detailsObj) {
  try {
    const response = await Projects.postProjectDetails(token, detailsObj);
    return response;
  } catch (err) {
    return {
      message:
        "Error submitting the form (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getProjectTypes(token) {
  try {
    const resp = await Projects.getProjectTypes(token);
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting project details (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}
async function getSyncInfo(token, projectId) {
  try {
    const resp = await Projects.getSyncInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting project details (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function submitSyncJob(token, detailsObj) {
  try {
    const response = await Projects.postSyncJob(token, detailsObj);
    return response;
  } catch (err) {
    return {
      message:
        "Error submitting the form (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function submitADOSyncJob(token, detailsObj) {
  try {
    const response = await ADO.submitSync(token, detailsObj);
    return response;
  } catch (err) {
    return {
      message:
        "Error submitting the form (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

export default function ProjectDetails(props) {
  const {
    //projectDetails,
    setProjectDetails,
    addNewProjectFlag,
    setAddNewProjectFlag,
  } = useProjectDetails();
  const navigate = useNavigate();
  const { setToRefreshProjects } = useProjects();
  const { projectTypes, setProjectTypes } = useProjects();
  const { errorMessage, setErrorMessage, clearErrorMessage } =
    useErrorMessage();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [program, setProgram] = useState();
  const { projectEditDetails, setEditProjectDetails, clearEditProjectDetails } =
    useEditProjectDetails({});
  const { loggedUser, setLoggedUser } = useUserInfo({});
  const [testToolProjectId, setTestToolProjectId] = useState();
  const [testToolProjectName, setTestToolProjectName] = useState("");
  const [saveStatus, setSaveStatus] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [projectIdNumber, setProjectIdNumber] = useState("");
  const [isNewProject, setIsNewProject] = useState(false);
  const [syncModalText, setSyncModalText] = useState("No information...");
  const [isSyncButtonDisabled, setIsSyncButtonDisabled] = useState(false);
  const [projectDateError, setProjectDateError] = useState();
  const [isActiveEditValue, setIsActiveEditValue] = useState();
  const [saveIsNotAllowed, setSaveIsNotAllowed] = useState(false);
  const [emptyFieldsMsg, setEmptyFieldsMsg] = useState();
  const [editMode, setEditMode] = useState();
  const [sourceTestDataType, setSourceTestDataType] = useState();
  const [sourceDefectDataType, setSourceDefectDataType] = useState();
  const [ADORequestData, setADORequestData] = useState([]);
  const [ADOTestPlansReleases, setADOTestPlansReleases] = useState([]);
  const [ADOSprintsRequestData, setADOSprintsRequestData] = useState([]);
  const [isADO, setIsADO] = useState();
  const [ADO_Project_Selected, set_ADO_Project_Selected] = useState([]);
  const [testPlansOptions, setTestPlansOptions] = useState([]);
  const { projectId } = useParams();
  const {
    currentProjectDetails: projectDetails,
    currentSelectedProjectTypeId,
    selectedProjectTypeId,
    dispatch,
  } = useAppContext();
  if (projectId === "new") {
    //setIsNewProject(true);
  }
  const [isADOTestPlansReleasesLoading, setIsADOTestPlansReleasesLoading] =
    useState(false);
  const [isActiveButtonsList, setIsActiveButtonsList] = useState({
    edit: "©",
    save: "disabled",
    cancel: "disabled",
  });
  const DataSource = {
    JIRA: "JIRA",
    ADO: "ADO",
    QTEST: "qTest",
  };
  const syncStatuses = {
    1: "Waiting",
    2: "In Progress",
    3: "Completed",
    4: "Failed",
  };
  let updatedProps = {};
  Object.assign(updatedProps, props);

  let emptyProject = {
    projectId: -1,
    lpAdoRelations: [],
    lpJiraRelations: [{}],
    lpIterations: [],
    lpQtestRelations: [{}],
    projectMilestones: [
      {
        id: 1,
        milestone: "",
        startDate: "",
        endDate: "",
        projectId: "",
      },
    ],
    projectTestSource: "qtest",
    projectName: "",
    isActive: false,
    startDate: "",
    endDate: "",
    testPlanLink: "",
    projectDefectSource: "",
    projectTypeId: 1,
  };

  const verifyRequiredFields = (projectDetails) => {
    if (!editMode) {
      setSaveIsNotAllowed(false);
      setEmptyFieldsMsg();
      return;
    }

    let requiredFields = [];
    let projectIsADO = getProgramSourceByID(projectDetails.projectTypeId);
    let messageRequiredFieldsADO = {
      adoProjName: "ADO Project",
      adoAreaFieldName: "ADO Area Field",
      adoAreaFieldValue: "ADO Area Value(s)",
      adoWorkItemType: "ADO Work Item Type",
      adoTargetReleaseFieldName: "ADO Target Release Field",
      adoTargetReleaseValues: "ADO Target Release Value(s)",
    };
    setIsADO(projectIsADO);

    if (!projectDetails.projectName?.trim()) {
      requiredFields.push("Project Name");
    }

    if (!projectDetails.testPlanLink) {
      if (!projectIsADO) {
        requiredFields.push("qTest Release Link");
      } else {
        requiredFields.push("ADO Release Link");
      }
    }

    if (!projectDetails.startDate) {
      requiredFields.push("Start Date");
    }

    if (!projectDetails.endDate) {
      requiredFields.push("End Date");
    }

    if (
      projectDetails.lpJiraRelations &&
      !(projectDetails.lpJiraRelations?.[0]?.targetReleaseValues?.length > 0) &&
      !projectIsADO
    ) {
      requiredFields.push("Jira Release");
    }

    if (
      projectDetails.lpQtestRelations &&
      !projectDetails.lpQtestRelations?.[0]?.qtestReleaseId &&
      !projectIsADO
    ) {
      requiredFields.push("qTest Release");
    }

    if (projectIsADO && ADORequestData.length) {
      let data = Object.entries(ADORequestData[0]);
      let requiredFieldsADO = [
        "adoProjName",
        "adoAreaFieldName",
        "adoAreaFieldValue",
        "adoWorkItemType",
        "adoTargetReleaseFieldName",
        "adoTargetReleaseValues",
      ];

      data.forEach((field) => {
        let key = field[0];
        let value = field[1];

        requiredFieldsADO.forEach((requiredField) => {
          if (
            (requiredField === key && !value) ||
            (requiredField === key && !value.length)
          ) {
            requiredFields.push(messageRequiredFieldsADO[key]);
          }
        });
      });
    } else if (projectIsADO && !ADORequestData.length) {
      requiredFields.push(messageRequiredFieldsADO["adoProjName"]);
      requiredFields.push(messageRequiredFieldsADO["adoAreaFieldName"]);
      requiredFields.push(messageRequiredFieldsADO["adoAreaFieldValue"]);
      requiredFields.push(messageRequiredFieldsADO["adoWorkItemType"]);
      requiredFields.push(
        messageRequiredFieldsADO["adoTargetReleaseFieldName"]
      );
      requiredFields.push(messageRequiredFieldsADO["adoTargetReleaseValues"]);
    }

    // Sprints and Milestones are required only for active projects

    if (projectDetails.isActive) {
      let result = projectDetails.projectMilestones?.some((item) => {
        if (item.milestone) return true;
        return false;
      });

      if (!result) requiredFields.push("Milestones");

      result = projectIsADO
        ? verifyRequiredSprintsFieldsADO()
        : projectDetails.lpIterations?.some((item) => {
            if (item.iterationName && item?.iterationCycles?.length > 0)
              return true;
            return false;
          });

      if (!result) requiredFields.push("Sprints");
    }

    if (requiredFields.length > 0) {
      setSaveIsNotAllowed(true);
      setEmptyFieldsMsg(requiredFields);
    } else {
      setSaveIsNotAllowed(false);
      setEmptyFieldsMsg();
    }
  };

  const verifyRequiredSprintsFieldsADO = () => {
    let result = false;

    if (ADOSprintsRequestData.length) {
      result = ADOSprintsRequestData.some((iteration) => {
        if (
          iteration.iterationName?.length > 0 &&
          iteration.iterationPath?.length > 0 &&
          iteration.iterationCycles?.length > 0
        ) {
          return true;
        }
        return false;
      });
    }

    return result;
  };

  useEffect(() => {
    verifyRequiredFields(projectEditDetails);
  }, [projectEditDetails, editMode, ADORequestData, ADOSprintsRequestData]);

  useEffect(() => {
    const handleProjectDetails = async (e) => {
      clearErrorMessage();
      const ProjTypes = await getProjectTypes(props.token);
      setProjectTypes(ProjTypes);

      if (!!addNewProjectFlag) {
        setDisabled(false);
        setIsNewProject(true);
        setEditMode(true);
        if (selectedProjectTypeId === "All") {
          dispatch({
            type: "EDIT_SELECTED_PROJECT_TYPE_ID",
            value: 1,
          });
          emptyProject.projectTypeId = "1";
          setEditProjectDetails(emptyProject);
        } else {
          dispatch({
            type: "EDIT_SELECTED_PROJECT_TYPE_ID",
            value: selectedProjectTypeId,
          });
          emptyProject.projectTypeId = selectedProjectTypeId;
          setEditProjectDetails(emptyProject);
        }
        setIsActiveEditValue(false);
      } else {
        setIsNewProject(false);
        let currentId = projectId || props.currentProjectId || projectIdNumber;

        if (!currentId) {
          if (projectId) {
            props.setCurrentProjectId(projectId);
            setErrorMessage();
          } else {
            clearEditProjectDetails();
            setAddNewProjectFlag(true);
            setLoading(false);
            setErrorMessage("Project is not selected!");
            return;
          }
        }

        if (!projectDetails || projectDetails.projectId !== currentId) {
          const response = await getProjectDetails(
            props.token,
            currentId,
            navigate
          );

          if (response && response.message) {
            setErrorMessage(response.message);
            setProjectDetails({});
            clearEditProjectDetails();
            props.setCurrentProjectName("");
            setIsActiveEditValue(false);
          } else {
            if (sourceTestDataType === DataSource.QTEST || !isADOProject()) {
              let syncInfoResponse = await getSyncInfo(props.token, currentId);

              if (syncInfoResponse && !syncInfoResponse.message) {
                let syncInfo = syncInfoResponse;
                //disable the button if job already submitted
                if (
                  syncInfo?.currentStatus === 1 ||
                  syncInfo?.currentStatus === 2
                ) {
                  setIsSyncButtonDisabled(true);
                } else {
                  setIsSyncButtonDisabled(false);
                }
                let notes = syncInfo?.notes ? syncInfo.notes : "";
                if (syncInfo?.currentStatus === 4) {
                  setSyncModalText(
                    "Submitted by " +
                      syncInfo?.submittedBy +
                      " on " +
                      syncInfo?.submissionTime +
                      ".\n Status: " +
                      (syncInfo?.currentStatus
                        ? syncStatuses[syncInfo.currentStatus]
                        : syncInfo.currentStatus) +
                      "<br>----------------------------------------------------------------------" +
                      (notes
                        ? " <br>[" + notes.split(";").join("<br>") + "]"
                        : "")
                  );
                } else {
                  setSyncModalText(
                    "Submitted by " +
                      syncInfo?.submittedBy +
                      " on " +
                      syncInfo?.submissionTime +
                      ".\n Status: " +
                      (syncInfo?.currentStatus
                        ? syncStatuses[syncInfo.currentStatus]
                        : syncInfo.currentStatus) +
                      (notes ? " [" + notes + "]" : "")
                  );
                }
              }
            }

            if (response && response.projectTestSource === DataSource.ADO) {
              setSourceTestDataType(DataSource.ADO);
              setSourceDefectDataType(DataSource.ADO);
              setProgram(response.projectTypeId);
            }

            setProjectDetails(response);
            setEditProjectDetails(response);
            props.setCurrentProjectName(response?.projectName || "");
            setTestToolProjectId(response?.lpQtestRelations[0]?.qtestProjectId);

            if (response && response?.lpQtestRelations) {
              setTestToolProjectId(
                response?.lpQtestRelations[0]?.qtestProjectId
              );
            }
            setIsActiveEditValue(response?.isActive);
          }
        } else {
          setIsActiveEditValue(projectDetails?.isActive);
          dispatch({
            type: "EDIT_CURRENT_SELECTED_PROJECT_TYPE_ID",
            value: projectDetails?.projectTypeId,
          });
          props.setCurrentProjectName(projectDetails?.projectName);

          getSourceDataType(projectDetails?.projectTypeId);
          setProgram(projectDetails?.projectTypeId);

          if (sourceTestDataType === DataSource?.QTEST || !isADOProject()) {
            let syncInfoResponse = await getSyncInfo(props.token, currentId);
            if (syncInfoResponse && !syncInfoResponse?.message) {
              let syncInfo = syncInfoResponse;
              //disable the button if job already submitted
              if (
                syncInfo?.currentStatus === 1 ||
                syncInfo?.currentStatus === 2
              ) {
                setIsSyncButtonDisabled(true);
              } else {
                setIsSyncButtonDisabled(false);
              }
              let notes = syncInfo?.notes ? syncInfo.notes : "";
              if (syncInfo?.currentStatus === 4) {
                setSyncModalText(
                  "Submitted by " +
                    syncInfo?.submittedBy +
                    " on " +
                    syncInfo?.submissionTime +
                    ".\n Status: " +
                    (syncInfo?.currentStatus
                      ? syncStatuses[syncInfo.currentStatus]
                      : syncInfo.currentStatus) +
                    "<br>----------------------------------------------------------------------" +
                    (notes
                      ? " <br>[" + notes.split(";").join("<br>") + "]"
                      : "")
                );
              } else {
                setSyncModalText(
                  "Submitted by " +
                    syncInfo?.submittedBy +
                    " on " +
                    syncInfo?.submissionTime +
                    ".\n Status: " +
                    (syncInfo?.currentStatus
                      ? syncStatuses[syncInfo.currentStatus]
                      : syncInfo.currentStatus) +
                    (notes ? " [" + notes + "]" : "")
                );
              }
            }
          }
        }
      }
      props.changeStyle("settings");
      setLoading(false);
    };

    if (props.loggedUser) {
      setLoggedUser(props.loggedUser);
    }
    handleProjectDetails();
  }, []);

  const handleSubmit = async (e) => {
    setErrorMessage();

    if (isADOProject()) {
      getTestPlansReleases();

      projectEditDetails.projectTypeId = program
        ? program
        : getDefaultProgramId();
      projectEditDetails.projectTestSource = "ADO";
      projectEditDetails.projectDefectSource = "ADO";
      projectEditDetails.lpAdoRelations = ADORequestData;
      projectEditDetails.lpIterations = handleTransformADOSprintsRequestData(
        ADOSprintsRequestData
      );
      projectEditDetails.lpJiraRelations = [];
    }

    for (let index in projectEditDetails.projectMilestones) {
      const item = projectEditDetails.projectMilestones[index];
      if (!item || Object.keys(item).length === 0) {
        projectEditDetails.projectMilestones.splice(index, 1);
      }
    }

    if (projectEditDetails.projectMilestones?.length < 1) {
      delete projectEditDetails["projectMilestones"];
    }

    projectEditDetails.userId = loggedUser?.id;

    let projectValueId = currentSelectedProjectTypeId;

    if (!isADOProject()) {
      projectEditDetails.projectTypeId =
        projectValueId === "All" ? 1 : projectValueId;
    }

    setSaveLoading(true);
    const response = await submitDetails(props.token, projectEditDetails);
    if (response.message) {
      setErrorMessage(response.message);
      setSaveStatus(false);
    } else {
      setToRefreshProjects(true);
      setProjectDetails(response);
      setEditProjectDetails(response);
      setAddNewProjectFlag(false);
      setIsNewProject(false);
      setSaveStatus(true);
      setEditMode(false);
      setProjectIdNumber(response.projectId);
      props.setCurrentProjectId(response.projectId);
      props.setCurrentProjectName(response.projectName);

      if (isADOProject()) {
        const adoSyncResponse = await submitADOSyncJob(props.token, response);
      }
    }
    window.scrollTo(0, 0);
    if (isNewProject && isADOProject()) {
      window.location.reload(true);
    }
    setSaveLoading(false);
  };

  const saveCurrentTestToolProjectId = (id) => {
    setTestToolProjectId(id);
  };

  const handleSubmitSyncJob = async () => {
    let name =
      projectDetails.projectId === props.currentProjectId
        ? projectDetails.projectName
        : "";
    let detailsObj = {
      lpProjectId: props.currentProjectId,
      name: name,
      submittedBy: loggedUser?.name,
    };
    const response = await submitSyncJob(props.token, detailsObj);

    if (response.message) {
      setSyncModalText(response.message);
    } else {
      let syncInfo = response;
      if (syncInfo?.currentStatus === 1 || syncInfo?.currentStatus === 2) {
        setIsSyncButtonDisabled(true);
      } else {
        setIsSyncButtonDisabled(false);
      }
      let notes = syncInfo?.notes ? syncInfo.notes : "";
      setSyncModalText(
        "Submitted by " +
          syncInfo?.submittedBy +
          " on " +
          syncInfo?.submissionTime +
          ".\n Status: " +
          (syncInfo?.currentStatus
            ? syncStatuses[syncInfo.currentStatus]
            : syncInfo.currentStatus) +
          (notes ? " [" + notes + "]" : "")
      );
    }
  };

  const prepareEditObj = () => {
    if (projectEditDetails?.projectId === -1) {
      if (!projectDetails) {
        delete projectEditDetails["projectId"];
        let str = JSON.stringify(projectEditDetails);
        let currPrj = JSON.parse(str);
        setEditProjectDetails(currPrj);
      } else {
        let str = JSON.stringify(projectDetails);
        let currPrj = JSON.parse(str);
        setEditProjectDetails(currPrj);
      }
    }
    return;
  };

  const isADOProject = () => {
    let isADO = false;
    let selectedProgram = selectedProjectTypeId;
    let userProjectsAllowed = JSON.parse(localStorage.getItem("loggedUser"));

    if (isNewProject && selectedProgram == "All") {
      let program;

      projectTypes.find((type) => {
        if (type.projectTypeId == userProjectsAllowed.projectTypes[0]) {
          return (program = type);
        }
      });
      dispatch({
        type: "EDIT_SELECTED_PROJECT_TYPE_ID",
        value: program.projectTypeId,
      });

      dispatch({
        type: "EDIT_CURRENT_SELECTED_PROJECT_TYPE_ID",
        value: program.projectTypeId,
      });
      dispatch({
        type: "EDIT_SELECTED_PROJECT_TYPE_ID",
        value: program.projectTypeId,
      });

      if (program.sourceDefectType === DataSource.ADO) {
        return (isADO = true);
      } else {
        return (isADO = false);
      }
    }

    if (!sourceTestDataType || !sourceDefectDataType) {
      projectTypes.find((type) => {
        if (
          type.projectTypeId == selectedProgram &&
          type.sourceDefectType === DataSource.ADO
        ) {
          isADO = true;
        }
      });
    }

    if (
      sourceTestDataType === DataSource.ADO ||
      sourceDefectDataType === DataSource.ADO
    ) {
      isADO = true;
    }

    return isADO;
  };

  const getProgramSourceByID = (id) => {
    let isADO = false;

    if (id == "All") {
      return (isADO = false);
    }

    projectTypes.forEach((project) => {
      if (
        id &&
        id !== "undefined" &&
        project.projectTypeId == JSON.parse(id) &&
        project.sourceDefectType !== DataSource.JIRA
      ) {
        isADO = true;
      }
    });

    return isADO;
  };

  const getDefaultProgramId = () => {
    let defaultProgramId;
    let selectedProgram = currentSelectedProjectTypeId;

    if (selectedProgram === "All") {
      return (defaultProgramId = 1);
    }

    projectTypes.find((type) => {
      if (type.projectTypeId == selectedProgram) {
        return (defaultProgramId = type.projectTypeId);
      }
    });

    return defaultProgramId;
  };

  const getSourceDataType = (projectTypeId) => {
    if (projectTypes && isNaN(projectTypeId)) {
      let projectType = projectTypes.find(
        (type) => type.projectTypeName === projectDetails?.projectTypeName
      );

      setSourceTestDataType(projectType?.sourceTestType);
      setSourceDefectDataType(projectType?.sourceDefectType);
    } else {
      let projectType = projectTypes.filter(
        (f) => f.projectTypeId === parseInt(projectTypeId)
      );

      setSourceTestDataType(projectType[0]?.sourceTestType);
      setSourceDefectDataType(projectType[0]?.sourceDefectType);
    }
  };

  const getTestPlansReleases = () => {
    return ADORequestData.forEach((project, index) => {
      let projectName = project.adoProjName;
      delete project.adoTestPlanReleaseData;

      if (!ADOTestPlansReleases?.length) {
        return (ADORequestData[index].adoTestPlanRelease = []);
      }

      ADOTestPlansReleases.forEach((testPlan) => {
        if (
          testPlan.program === projectName &&
          !ADORequestData[index].adoTestPlanRelease.includes(testPlan.id)
        ) {
          ADORequestData[index].adoTestPlanRelease.push(testPlan.id);
        }
      });
    });
  };

  const handleProjectNameChange = async (event) => {
    projectEditDetails.projectName = event.target.value;
    setEditProjectDetails(projectEditDetails);
    verifyRequiredFields(projectEditDetails);
  };

  const handleIsActiveChange = async (event) => {
    projectEditDetails.isActive = !!event.target.checked;
    setIsActiveEditValue(projectEditDetails.isActive);
    setEditProjectDetails(projectEditDetails);
    verifyRequiredFields(projectEditDetails);
  };

  const handleProjectTypeChange = async (event) => {
    getSourceDataType(event.target.value);
    setProgram(event.target.value);

    projectEditDetails.projectTypeId = event.target.value;

    if (projectEditDetails["projectTypeName"]) {
      delete projectEditDetails["projectTypeName"];
    }
    dispatch({
      type: "EDIT_CURRENT_SELECTED_PROJECT_TYPE_ID",
      value: event.target.value,
    });

    setEditProjectDetails(projectEditDetails);
    verifyRequiredFields(projectEditDetails);
  };

  const handleTestPlanLinkChange = async (event) => {
    projectEditDetails.testPlanLink = event.target.value;
    setEditProjectDetails(projectEditDetails);
    verifyRequiredFields(projectEditDetails);
  };

  const handleStartDateChange = async (date) => {
    projectEditDetails.startDate = date
      ? date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      : null;
    setEditProjectDetails(projectEditDetails);
    verifyRequiredFields(projectEditDetails);
  };

  const handleEndDateChange = async (date) => {
    projectEditDetails.endDate = date
      ? date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      : null;
    setEditProjectDetails(projectEditDetails);
    verifyRequiredFields(projectEditDetails);
  };

  const saveJiraProjectChange = async (event) => {
    projectEditDetails.lpJiraRelations[0].projectKey = event.target.value;
    setEditProjectDetails(projectEditDetails);
    verifyRequiredFields(projectEditDetails);
  };

  const handleDefaultProjectKey = async (key) => {
    projectEditDetails.lpJiraRelations[0].projectKey = key;
  };

  const handleTargetReleaseChange = async (event) => {
    projectEditDetails.lpJiraRelations[0].targetReleaseFieldId =
      event.target.value;
    setEditProjectDetails(projectEditDetails);
    verifyRequiredFields(projectEditDetails);
  };

  const handleReleasesChange = async (values) => {
    let tmpObj = JSON.parse(JSON.stringify(projectEditDetails));
    tmpObj.lpJiraRelations[0].targetReleaseValues = values;
    setEditProjectDetails(tmpObj);
    verifyRequiredFields(projectEditDetails);
  };

  const handleReleaseFoundChange = async (event) => {
    projectEditDetails.lpJiraRelations[0].releaseFoundFieldId =
      event.target.value;

    setEditProjectDetails(projectEditDetails);
  };

  const handleEnvironmentChange = async (event) => {
    projectEditDetails.lpJiraRelations[0].environmentFieldId =
      event.target.value;
    setEditProjectDetails(projectEditDetails);
  };

  const handleProductChange = async (event) => {
    projectEditDetails.lpJiraRelations[0].productFieldId = event.target.value;
    setEditProjectDetails(projectEditDetails);
  };

  const saveTestToolProjectChange = async (event) => {
    const { selectedIndex } = event.target;
    setTestToolProjectId(event.target.value);
    setTestToolProjectName(event.target.options[selectedIndex].text);
  };

  const handleTestToolReleasesChange = async (event) => {
    let values = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    projectEditDetails.lpQtestRelations = [];
    for (let index = 0; index < values.length; index++) {
      projectEditDetails.lpQtestRelations.push({
        id: index + 1,
        lpProjectId: projectEditDetails.projectId,
        qtestProjectId: testToolProjectId,
        qtestProjectName: testToolProjectName,
        qtestReleaseId: values[index],
        qtestReleaseName: event.target.selectedOptions[index].releaseName,
      });
    }
    setEditProjectDetails(projectEditDetails);
    verifyRequiredFields(projectEditDetails);
  };
  const handleTestToolIterationsChange = async (iterations) => {
    if (sourceTestDataType === DataSource.ADO) {
      projectEditDetails.lpIterations = iterations;
      setEditProjectDetails(projectEditDetails);
      verifyRequiredFields(projectEditDetails);
    } else {
      setEditProjectDetails(projectEditDetails);
      verifyRequiredFields(projectEditDetails);
    }
  };

  const handleMilestonesChange = async (milestones) => {
    projectEditDetails.projectMilestones = milestones.slice();
    setEditProjectDetails(projectEditDetails);
    verifyRequiredFields(projectEditDetails);
  };

  const handleADORequestData = (data) => {
    setADORequestData(data);
  };

  const handleADOSprintsRequestData = (iterations) => {
    setADOSprintsRequestData(iterations);
  };

  const handleTransformADOSprintsRequestData = (iterations) => {
    let newIterations = structuredClone(iterations);

    newIterations.forEach((iteration) => {
      let testCyclesIds = [];
      iteration.iterationCycles.forEach((cycle) => {
        return testCyclesIds.push(cycle.id);
      });

      iteration.iterationCycles = testCyclesIds;
    });

    return newIterations;
  };

  const handleADOTestPlansReleases = (data) => {
    setADOTestPlansReleases(data);
  };

  const handleUsersChange = async (users) => {};

  const clearResultMessage = () => {
    setSaveStatus(false);
  };

  const setPopulatedJiraInfoFields = async (populatedFields) => {
    projectEditDetails.lpJiraRelations[0].targetReleaseFieldId =
      populatedFields.target === ""
        ? populatedFields.default
        : populatedFields.target;
    projectEditDetails.lpJiraRelations[0].releaseFoundFieldId =
      populatedFields.release === ""
        ? populatedFields.default
        : populatedFields.release;
    projectEditDetails.lpJiraRelations[0].environmentFieldId =
      populatedFields.environment === ""
        ? populatedFields.default
        : populatedFields.environment;
    projectEditDetails.lpJiraRelations[0].productFieldId =
      populatedFields.product === ""
        ? populatedFields.default
        : populatedFields.product;

    setEditProjectDetails(projectEditDetails);
  };

  const setDateError = (error) => {
    setProjectDateError(error);
  };

  updatedProps.saveCurrentTestToolProjectId = saveCurrentTestToolProjectId;
  updatedProps.clearErrorMessage = setErrorMessage;
  updatedProps.selectedProjectType = props.selectedProjectType;
  updatedProps.handleProjectNameChange = handleProjectNameChange;
  updatedProps.handleIsActiveChange = handleIsActiveChange;
  updatedProps.handleProjectTypeChange = handleProjectTypeChange;
  updatedProps.handleTestPlanLinkChange = handleTestPlanLinkChange;
  updatedProps.handleStartDateChange = handleStartDateChange;
  updatedProps.handleEndDateChange = handleEndDateChange;
  updatedProps.saveJiraProjectChange = saveJiraProjectChange;
  updatedProps.handleDefaultProjectKey = handleDefaultProjectKey;
  updatedProps.handleTargetReleaseChange = handleTargetReleaseChange;
  updatedProps.handleReleasesChange = handleReleasesChange;
  updatedProps.handleReleaseFoundChange = handleReleaseFoundChange;
  updatedProps.handleEnvironmentChange = handleEnvironmentChange;
  updatedProps.handleProductChange = handleProductChange;
  updatedProps.saveTestToolProjectChange = saveTestToolProjectChange;
  updatedProps.handleTestToolReleasesChange = handleTestToolReleasesChange;
  updatedProps.handleTestToolIterationsChange = handleTestToolIterationsChange;
  updatedProps.handleMilestonesChange = handleMilestonesChange;
  updatedProps.handleUsersChange = handleUsersChange;
  updatedProps.clearResultMessage = clearResultMessage;
  updatedProps.setPopulatedJiraInfoFields = setPopulatedJiraInfoFields;
  updatedProps.setDateError = setDateError;

  return (
    <div id="main_container">
      {loading ? (
        <Loading />
      ) : (
        <div id="main_container_1">
          <ErrorMessage errorMessage={errorMessage} />
          {saveStatus && (
            <div className="form-outline mt-4" id="save-message">
              <div className="alert alert-success" role="alert">
                Project saved successfully [id=
                {props.currentProjectId || projectIdNumber}]
              </div>
            </div>
          )}
          {saveLoading && (
            <div className="overlay" id="loading">
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          )}
          <div className="bg-light">
            <section className="d-flex min-vh">
              <div className="container py-5 h-100">
                {isADOProject() || isNewProject ? null : (
                  <div
                    className="row d-flex justify-content-start align-items-center h-100 mb-4 mt-0"
                    style={
                      isADOProject()
                        ? { visibility: "hidden" }
                        : { visibility: "visible" }
                    }
                  >
                    <div
                      className="d-grid gap-2 col-2"
                      data-tip={syncModalText}
                    >
                      <button
                        disabled={
                          addNewProjectFlag || isSyncButtonDisabled
                            ? true
                            : false
                        }
                        className="btn btn-warning ms-0 w-100"
                        type="button"
                        onClick={() => handleSubmitSyncJob()}
                      >
                        Submit qTest Sync Job
                      </button>
                      <ReactTooltip type="info" multiline={true} />
                    </div>
                  </div>
                )}
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <form onSubmit={handleSubmit}>
                    {saveIsNotAllowed && (
                      <div className="instruction" style={{ color: "red" }}>
                        <div className="alert alert-danger" role="alert">
                          <div style={{ marginBottom: "5px" }}>
                            Save project is not allowed with the following empty
                            required fields:
                          </div>
                          {emptyFieldsMsg &&
                            emptyFieldsMsg.map((message, index) => {
                              return (
                                <ul key={index} style={{ margin: "0px 0px" }}>
                                  <li>{message}</li>
                                </ul>
                              );
                            })}
                        </div>
                      </div>
                    )}
                    <ButtonsBar
                      props={updatedProps}
                      setDisabled={setDisabled}
                      prepareEditObj={prepareEditObj}
                      clearEditProjectDetails={clearEditProjectDetails}
                      handleSubmit={handleSubmit}
                      isActiveList={isActiveButtonsList}
                      isNewProject={isNewProject}
                      setIsActiveList={setIsActiveButtonsList}
                      selectedProjectType={props.selectedProjectType}
                      projectDateError={projectDateError}
                      saveIsNotAllowed={saveIsNotAllowed}
                      editMode={editMode}
                      setEditMode={setEditMode}
                      currentProjectType={projectEditDetails}
                    />
                    <ProjectInfo
                      sourceTestDataType={sourceTestDataType}
                      sourceDefectDataType={sourceDefectDataType}
                      isADO={isADO}
                      token={props.token}
                      props={updatedProps}
                      disabled={disabled}
                      projectDetails={projectDetails}
                    />
                    <hr
                      style={
                        isADOProject()
                          ? { display: "block" }
                          : { display: "none" }
                      }
                      className="bg-secondary border-2 border-top border-secondary"
                    />
                    {isADOProject() && (
                      <ADODefectManagementTool
                        token={props.token}
                        props={updatedProps}
                        programId={program}
                        editMode={editMode}
                        addNewProjectFlag={addNewProjectFlag}
                        sourceDefectDataType={sourceDefectDataType}
                        handleADORequestData={(e) => handleADORequestData(e)}
                        set_ADO_Project_Selected={set_ADO_Project_Selected}
                        setTestPlansLoading={setIsADOTestPlansReleasesLoading}
                        setTestPlansOptions={setTestPlansOptions}
                      />
                    )}
                    <hr
                      className="bg-secondary border-2 border-top border-secondary"
                      style={
                        !isADOProject()
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    />
                    {isADOProject() && (
                      <ADOTestManagementTool
                        props={updatedProps}
                        editMode={editMode}
                        ADORequestData={ADORequestData}
                        ADO_Project_Selected={ADO_Project_Selected}
                        isDataLoading={isADOTestPlansReleasesLoading}
                        testPlansOptions={testPlansOptions}
                        handleADOTestPlansReleases={(iterations) => {
                          handleADOTestPlansReleases(iterations);
                        }}
                      />
                    )}
                    {!isADOProject() && (
                      <JiraInfo
                        token={props.token}
                        props={updatedProps}
                        disabled={disabled}
                        isNewProject={isNewProject}
                      />
                    )}
                    <hr className="bg-secondary border-2 border-top border-secondary"></hr>
                    {isADOProject() && (
                      <ADOSprintsManagementTool
                        props={updatedProps}
                        editMode={editMode}
                        testPlansReleases={ADOTestPlansReleases}
                        ADORequestData={ADORequestData}
                        handleADOSprintsRequestData={(iterations) =>
                          handleADOSprintsRequestData(iterations)
                        }
                      />
                    )}
                    {!isADOProject() && (
                      <TestManagementTool
                        token={props.token}
                        props={updatedProps}
                        disabled={disabled}
                        editMode={editMode}
                        isActive={isActiveEditValue}
                        addNewProjectFlag={addNewProjectFlag}
                      />
                    )}
                    <hr className="bg-secondary border-2 border-top border-secondary"></hr>
                    <Milestones
                      token={props.token}
                      props={updatedProps}
                      disabled={disabled}
                      isActive={isActiveEditValue}
                    />
                    <ButtonsBar
                      props={updatedProps}
                      setDisabled={setDisabled}
                      prepareEditObj={prepareEditObj}
                      clearEditProjectDetails={clearEditProjectDetails}
                      handleSubmit={handleSubmit}
                      isActiveList={isActiveButtonsList}
                      isNewProject={isNewProject}
                      setIsActiveList={setIsActiveButtonsList}
                      selectedProjectType={props.selectedProjectType}
                      projectDateError={projectDateError}
                      saveIsNotAllowed={false}
                      editMode={editMode}
                      setEditMode={setEditMode}
                      currentProjectType={projectEditDetails}
                    />
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  );
}
