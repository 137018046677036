const scoreCardUsers = [
  "Chris.Allanson",
  "Juan.Calleri.Tovo",
  "Chris.deRuiter",
  "Marlon.Guerrero",
  "Sunil.Kale",
  "brendan.mullady",
  "Leslie.Rosales",
  "jason.santti",
  "dhawal.sharma",
  "mayur.thole",
  "Juan.Vargas",
  "German.Suarez",
  "paul.digrazia",
  "Donna.Mallet",
  "Hope.Isley",
];

export const scoreCardUsersMock = scoreCardUsers.map((user) =>
  user.toLowerCase()
);
