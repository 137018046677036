import React, { useState, useEffect } from "react";
import CommonService from "../../../services/ScoreCardService";
import UsersTab from "./UsersTab";
import DepartmentsTab from "./DepartmentsTab";
import ProgramsTab from "./ProgramsTab";
import ProjectsTab from "./ProjectsTab";
import TeamsTab from "./TeamsTab";
import { TabView, TabPanel } from "primereact/tabview";

const AdminSettingsComponent = () => {
  const [token, setToken] = useState([]);
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [programs, setPrograms] = useState([{ name: "All", id: "0" }]);
  const [projects, setProjects] = useState([]);
  const [bussinessUnits, setBussinessUnits] = useState([]);
  const [roles, setRoles] = useState([
    { label: "Normal", value: "Normal" },
    { label: "SME", value: "SME" },
    { label: "Admin", value: "Admin" },
  ]);
  const [addEditUser, setAddEditUser] = useState(false);
  const [addEditDept, setAddEditDept] = useState(false);
  const [addEditProgram, setAddEditProgram] = useState(false);
  const [addEditProduct, setAddEditProduct] = useState(false);
  const [addEditBussinessUnit, setAddEditBussinessUnit] = useState(false);
  const [isSmeUser, setIsSmeUser] = useState(false);
  const [user, setUser] = useState({
    full_name: "",
    email: "",
    role: "",
    program_name: "",
    team: "",
  });
  const [dept, setDept] = useState({ id: "", name: "" });
  const [program, setProgram] = useState({
    id: "",
    name: "",
    departmentName: "",
  });
  const [project, setProject] = useState({ name: "", id: "", programName: "" });
  const [bussinessUnit, setBussinessUnit] = useState({ name: "", id: "" });

  useEffect(() => {
    // getAllBussinessUnits();
    // getAllDepartments();
    // getAllProd();
    // getAllUsers();
    // getAllPrograms();
  }, []);

  const getAllUsers = () => {
    CommonService.getAllUsers().then((data) => {
      setUsers(data);
    });
  };

  const getAllPrograms = () => {
    CommonService.getAllPrograms().then((data) => {
      setPrograms(data);
    });
  };

  const getAllDepartments = () => {
    CommonService.getAllDepartments().then((data) => {
      setDepartments(data);
    });
  };

  const getAllProd = () => {
    CommonService.getAllProjects().then((data) => {
      setProjects(data);
    });
  };

  const getAllBussinessUnits = () => {
    CommonService.getAllBussinessUnits().then((data) => {
      setBussinessUnits(data);
    });
  };

  const createUpdateUser = () => {
    CommonService.addNewUser(token, user).then(() => {
      setAddEditUser(false);
      getAllUsers();
      setUser({
        full_name: "",
        email: "",
        role: "",
        program_name: "",
        team: "",
      });
    });
  };

  const createUpdateDept = () => {
    CommonService.addNewDept(token, dept).then(() => {
      setAddEditDept(false);
      getAllDepartments();
      setDept({ name: "", id: "" });
    });
  };

  const createUpdateProgram = () => {
    CommonService.addNewProgram(token, program).then(() => {
      setAddEditProgram(false);
      getAllPrograms();
      setProgram({ name: "", id: "", departmentName: "" });
    });
  };

  const createUpdateProduct = () => {
    CommonService.addNewProduct(token, project).then(() => {
      setAddEditProduct(false);
      getAllProd();
      setProject({ name: "", id: "", programName: "" });
    });
  };

  const createUpdateBussinessUnit = () => {
    CommonService.addBussinessUnits(token, bussinessUnit).then(() => {
      setAddEditBussinessUnit(false);
      getAllBussinessUnits();
      setBussinessUnit({ name: "", id: "" });
    });
  };

  const editBussinessUnit = (unit) => {
    setBussinessUnit(unit);
    setAddEditBussinessUnit(true);
  };

  return (
    <TabView>
      <TabPanel header="Users Info">
        <UsersTab
          users={users}
          roles={roles}
          programs={programs}
          addEditUser={addEditUser}
          setAddEditUser={setAddEditUser}
          user={user}
          setUser={setUser}
          isSmeUser={isSmeUser}
          setIsSmeUser={setIsSmeUser}
          createUpdateUser={createUpdateUser}
        />
      </TabPanel>
      <TabPanel header="Department/Division">
        <DepartmentsTab
          departments={departments}
          addEditDept={addEditDept}
          setAddEditDept={setAddEditDept}
          dept={dept}
          setDept={setDept}
          createUpdateDept={createUpdateDept}
          userRole={user.role}
        />
      </TabPanel>
      <TabPanel header="Programs">
        <ProgramsTab
          programs={programs}
          departments={departments}
          addEditProgram={addEditProgram}
          setAddEditProgram={setAddEditProgram}
          program={program}
          setProgram={setProgram}
          createUpdateProgram={createUpdateProgram}
        />
      </TabPanel>
      <TabPanel header="Project/Product">
        <ProjectsTab
          projects={projects}
          programs={programs}
          addEditProduct={addEditProduct}
          setAddEditProduct={setAddEditProduct}
          project={project}
          setProject={setProject}
          createUpdateProduct={createUpdateProduct}
        />
      </TabPanel>
      <TabPanel header="Teams">
        <TeamsTab
          bussinessUnits={bussinessUnits}
          addEditBussinessUnit={addEditBussinessUnit}
          setAddEditBussinessUnit={setAddEditBussinessUnit}
          bussinessUnit={bussinessUnit}
          setBussinessUnit={setBussinessUnit}
          createUpdateBussinessUnit={createUpdateBussinessUnit}
          editBussinessUnit={editBussinessUnit}
          userRole={user.role}
        />
      </TabPanel>
    </TabView>
  );
};

export default AdminSettingsComponent;
