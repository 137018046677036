import { Chart } from "react-google-charts";
import { useState } from "react";
import TestCasesDevelopmentModal from "../../common/modals/TestCasesDevelopmentModal";

const OpenIssueBacklogChart = ({
  token,
  issueBackLogInfo,
  issues,
  isADO,
  currentIteration,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    projectId: null,
    summary: "",
  });
  const data = [["Date", "New Features", "Regression"]];
  const datesAndIssues = {};

  if (isADO === "ADO" && issueBackLogInfo) {
    issueBackLogInfo = Object.entries(issueBackLogInfo);
  }

  if (issueBackLogInfo) {
    let iterationId = true ? "0" : currentIteration;
    let iterationData = issueBackLogInfo.find(
      (iteration) => iteration[0] === iterationId
    );

    if (iterationData) {
      const infoWithDates = Object.entries(
        iterationData[1].trendingOpenBacklogMap
      );

      infoWithDates.forEach((element) => {
        let tempArray = [];

        if (
          element[1]["New Features"]?.length > 0 ||
          element[1]["Regression"]?.length > 0
        ) {
          let alreadyExist = false;

          if (datesAndIssues[element[0]]) {
            alreadyExist = true;
          }
          datesAndIssues[element[0]] = {
            NewFeatures: element[1]["New Features"],
            Regression: element[1]["Regression"],
          };

          if (!alreadyExist) {
            tempArray.push(
              element[0],
              datesAndIssues[element[0]].NewFeatures.length,
              datesAndIssues[element[0]].Regression.length
            );
            data.push(tempArray);
          }
        }
      });
    }
  }

  if (data.length === 1) {
    data.push(["No data", 0, 0]);
  }

  var chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function (e) {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row;
          var col = selection[0].column;
          let selectedDate = data[row + 1] && data[row + 1][0];
          if (!selectedDate) {
            return;
          }

          let selectedType = col === 1 ? "New Features" : "Regression";
          const issuePerDate =
            datesAndIssues[selectedDate] &&
            datesAndIssues[selectedDate][selectedType.replace(" ", "")];
          const defectsInfoArray = [];

          if (issuePerDate) {
            issuePerDate.forEach((issue) => {
              const element = issues.find((item) => {
                return issue === item.issueID;
              });
              defectsInfoArray.push({
                testCaseId: element.issueID,
                testCaseDescription: element.summary,
                link: element.selfLink,
              });
            });

            setModalData({
              title: `${selectedType}: ${selectedDate}`,
              testCasesData: defectsInfoArray,
              headers: ["Issue ID", "	Issue Summary"],
            });
            setShowModal(true);
          }
        };
        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          () => handler()
        );
      },
    },
  ];

  var options = {
    title: "Open Issues Backlog",
    titleTextStyle: { fontSize: "16" },
    bars: "vertical",
    isStacked: true,
    hAxis: {
      title: "Date",
      slantedText: true,
      slantedTextAngle: 45,
      textStyle: { fontSize: 11 },
    },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    tooltip: { isHtml: true },
    vAxis: {
      format: "0",
    },
  };

  return (
    <>
      <TestCasesDevelopmentModal
        isOpen={showModal}
        setShowModal={setShowModal}
        setModalData={setModalData}
        data={modalData}
        token={token}
      />
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
        chartEvents={chartEvents}
      />
    </>
  );
};

export default OpenIssueBacklogChart;
