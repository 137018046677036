import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo-header.png";
import MSVersion from "../../services/MSVersion";
import Loading from "../common/Loading";
import LaunchPadPDF from "../../assets/launchpad_user_guide_2.0.pdf";
import useToken from "../../components/hooks/useToken";
import useUserInfo from "../hooks/useUserInfo";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { loginRequest } from "../../services/authConfig";
import { useMsal } from "@azure/msal-react";
import "./../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

const LAUNCHPAD_RELEASE_NOTES_LINK =
  "https://confluence.wolterskluwer.io/display/GPOQA/Certification+Statement+for+LaunchPad+2.0+-+Sprint+Certification";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: "14px",
    border: "1px solid #dadde9",
  },
}));

async function getMSVersions(token) {
  try {
    return await MSVersion.getMicroServicesVersions(token);
  } catch (err) {
    return { message: "MS version error (" + err.message + ")" };
  }
}

async function getLPVersion(token) {
  try {
    return await MSVersion.getLPReleaseVersion(token);
  } catch (err) {
    return { message: "LP Release version error (" + err.message + ")" };
  }
}

function PageLogo() {
  const history = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();
  const { loggedUser, setLoggedUser } = useUserInfo({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [MSVersionData, setMSVersionData] = useState();
  const [MSVersionLoading, setMSVersionLoading] = useState(null);
  const [LPReleaseVersionData, setLPReleaseVersionData] = useState("");
  const [storedVersions, setStoredVersions] = useState();
  const { token, setToken } = useToken();

  useEffect(() => {
    defineIsAdminPage();

    if (loggedUser) {
      fetchReleaseData();
    }
  }, []);

  const fetchReleaseData = async () => {
    let storedData = sessionStorage.getItem("version");

    if (token !== null && MSVersionData === undefined && storedData === null) {
      setMSVersionLoading(true);
      let launchPadVersion = await getLPVersion(token);
      let versions = await getMSVersions(token);
      let fullMSData = { ...launchPadVersion, versions };

      sessionStorage.setItem("version", JSON.stringify(fullMSData));

      setMSVersionData(versions);
      setLPReleaseVersionData(launchPadVersion.data);
      setMSVersionLoading(false);
    } else {
      setMSVersionLoading(false);
      let versionToShow = JSON.parse(sessionStorage.getItem("version"));
      setStoredVersions(versionToShow);
    }
  };

  const defineIsAdminPage = () => {
    const pathSegments = location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];

    if (lastSegment === "admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {});
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    instance.logout({
      postLogoutRedirectUri: "/",
    });
  };

  return (
    <div id="logo_container" className="container-fluid pt-3 pb-3 ">
      <div className="row" style={{ minHeight: "91px" }}>
        <div className="col-sm-3 " key="01">
          <img
            src={logo}
            alt="logo"
            className="img-fluid"
            onClick={() => history(location?.pathname, { replace: true })}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
        <div className="d-flex mb-3 col-sm-6 flex-grow-1 bg-primary">
          <div className="d-flex align-self-end w-75" key="02">
            <div style={{ display: "flex", fontSize: "16px" }}>
              <h2 className="lplogofont">LaunchPad</h2>
              {loggedUser && !MSVersionLoading && (
                <div
                  style={{
                    fontSize: "14px",
                    padding: "13px 0 0 5px",
                    color: "white",
                  }}
                >
                  <HtmlTooltip
                    title={
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontStyle: "italic",
                            fontWeight: "bold",
                            color: "#0d6efd",
                          }}
                        >
                          <a
                            href={LAUNCHPAD_RELEASE_NOTES_LINK}
                            target="_blank"
                          >
                            Release Notes
                          </a>
                        </div>
                        {MSVersionLoading ? (
                          <Loading />
                        ) : !storedVersions &&
                          MSVersionData !== undefined &&
                          Array.isArray(MSVersionData) ? (
                          MSVersionData.map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  padding: "5px 10px",
                                }}
                              >
                                <span style={{ fontStyle: "italic" }}>
                                  {item.title}:
                                </span>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  {item.version}
                                </span>
                              </div>
                            );
                          })
                        ) : (
                          storedVersions &&
                          !storedVersions?.versions?.message &&
                          storedVersions.versions?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  padding: "5px 10px",
                                }}
                              >
                                <span style={{ fontStyle: "italic" }}>
                                  {item.title}:
                                </span>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  {item.version}
                                </span>
                              </div>
                            );
                          })
                        )}
                      </>
                    }
                  >
                    <div>
                      {storedVersions
                        ? `v${storedVersions?.data?.name.split("-")[1]}`
                        : LPReleaseVersionData !== ""
                        ? `v${LPReleaseVersionData?.name?.split("-")[1]}`
                        : null}
                    </div>
                  </HtmlTooltip>
                </div>
              )}
            </div>
          </div>
          <div className="w-25 position-relative">
            {loggedUser?.admin && (
              <div
                className="position-absolute bottom-0 mb-1"
                style={{ display: "flex", alignItems: "center" }}
              >
                {!isAdmin && (
                  <div
                    style={{ display: "flex" }}
                    onClick={() => {
                      if (loggedUser?.admin) {
                        history(location?.pathname, { replace: true });
                        history("/admin");
                        setIsAdmin(true);
                      }
                    }}
                  >
                    <i
                      className="fa fa-cog fa-light text-end small text-white cursor-pointer"
                      role={loggedUser?.admin ? "button" : ""}
                      style={{ alignSelf: "center" }}
                    />
                    <div
                      className="text-end small text-white cursor-pointer"
                      style={{ paddingLeft: "5px", cursor: "pointer" }}
                    >
                      {" User Settings"}
                    </div>
                  </div>
                )}
                {isAdmin && (
                  <div
                    style={{ display: "flex" }}
                    onClick={() => {
                      if (loggedUser?.admin) {
                        history(location?.pathname, { replace: true });
                        history("/dashboard");
                        setIsAdmin(false);
                      }
                    }}
                  >
                    <i
                      className="fa fa-dashboard fa-light text-end small text-white cursor-pointer"
                      role={loggedUser?.name ? "button" : ""}
                      style={{ alignSelf: "center" }}
                    />
                    <div
                      className="text-end small text-white cursor-pointer"
                      style={{ paddingLeft: "5px", cursor: "pointer" }}
                    >
                      {" Dashboard"}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="w-25 ">
            <ul className="mt-2 mb-1">
              <li
                className="list-unstyled"
                style={{ display: "flex", alignItems: "center" }}
              >
                {loggedUser?.name && (
                  <>
                    <i className="fa fa-light fa-user text-end small text-white"></i>
                    <div
                      className="text-end small text-white cursor-pointer"
                      style={{ paddingLeft: "9px" }}
                    >
                      {" " + loggedUser?.name}
                    </div>
                  </>
                )}
              </li>
              <li className="list-unstyled">
                <div style={{ maxWidth: "50px" }}>
                  <a
                    href={LaunchPadPDF}
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                    }}
                  >
                    <i
                      className="fa fa-question-circle text-end small text-white"
                      role={loggedUser?.admin ? "button" : ""}
                      style={{ alignSelf: "center" }}
                    />
                    <div
                      className="text-end small text-white cursor-pointer"
                      style={{ paddingLeft: "5px", cursor: "pointer" }}
                    >
                      {"Help"}
                    </div>
                  </a>
                </div>
              </li>
              <li
                className="list-unstyled"
                style={{ display: "flex", alignItems: "center" }}
                onClick={(e) => {
                  handleLogout();
                }}
              >
                {loggedUser?.name && (
                  <i
                    className="fa fa-sign-out text-end small text-white cursor-pointer"
                    role="button"
                  />
                )}
                <div
                  className="text-end small text-white cursor-pointer"
                  style={{ paddingLeft: "5px", cursor: "pointer" }}
                >
                  {loggedUser?.name ? "Logout" : null}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageLogo;
